import {
    FlexColumnStart,
    FlexRowResponsive,
} from "../../../style/projectComponents";
import React, {FC} from "react";
import styled from "styled-components/macro";
import {StyledSection} from "../../global/StyledSection";
import {useTranslation} from "react-i18next";

const MainSectionWrapper = styled(StyledSection)`
  padding: 0;
  align-items: center;
  margin: 0;
  color: #fff;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  text-align: start;
  color: #fff;
  margin-top: 60px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-top: 70px;
    font-size: 21px;
    text-align: center;
    width: calc(100% - 120px);
  }
`;

const ColumnWrapper = styled(FlexRowResponsive)`
  align-items: start;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
  }
`
const WhyColumn = styled(FlexColumnStart)`
  justify-content: start;
  text-align: left;
  width: 360px;
  background: #121212;
  border-radius: 20px;
  padding: 15px 34px;
  height: 400px;
  margin: 9px;

  img {
    width: 150px;
    height: 150px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    width: calc(358px - 68px);
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const WhyChooseSection: FC = () => {
    const {t} = useTranslation();

    return (
        <MainSectionWrapper id={"main"}>
            <Title>{t("landing.whyChooseUs.title")}</Title>
            <ColumnWrapper>
                <WhyColumn>
                    <img src="/images/landing/1.svg" alt=""/>
                    <h4>{t("landing.whyChooseUs.versatility")}</h4>
                    <p>{t("landing.whyChooseUs.versatilityDesc")}</p>
                </WhyColumn>
                <WhyColumn>
                    <img src="/images/landing/2.svg" alt=""/>
                    <h4>{t("landing.whyChooseUs.security")}</h4>
                    <p>{t("landing.whyChooseUs.securityDesc")}</p>
                </WhyColumn>
            </ColumnWrapper>
            <ColumnWrapper>
                <WhyColumn>
                    <img src="/images/landing/3.svg" alt=""/>
                    <h4>{t("landing.whyChooseUs.scalability")}</h4>
                    <p>{t("landing.whyChooseUs.scalabilityDesc")}</p>
                </WhyColumn>
                <WhyColumn>
                    <img src="/images/landing/4.svg" alt=""/>
                    <h4>{t("landing.whyChooseUs.innovation")}</h4>
                    <p>{t("landing.whyChooseUs.innovationDesc")}</p>
                </WhyColumn>
            </ColumnWrapper>
        </MainSectionWrapper>
    );
};
