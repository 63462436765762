import React from "react";
import styled from "styled-components/macro";
import {
    AppTitleNormal,
    FlexColumn,
    FlexRow, MarketTextMini, TradeLabel,
} from "../../style/projectComponents";

const Block = styled(FlexColumn)`
  width: 240px;
  border-radius: 12px;
  align-items: start;
  color: ${({theme}) => theme.colors.black};

  h1 {
    font-size: 22px;
    margin: 0;
    padding-bottom: 11px;
    width: 100%;
    text-align: center;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    width: 100%;
  }

  @media (max-width: 1100px) {
    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 780px) {
    width: 12.5rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    h1 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`;

interface StatisticsComponentProps {
    title: string;
    description: string;
    symbol?: string;
}

export const StatisticsComponent: React.FC<StatisticsComponentProps> = ({
                                                                            title,
                                                                            description,
                                                                            symbol,
                                                                        }) => {
    return (
        <Block>
            <TradeLabel style={{marginBottom: 8, textAlign: "left"}}>
                {description}
            </TradeLabel>
            <FlexRow style={{alignItems: "end"}}>
                <AppTitleNormal style={{marginRight: 6, fontSize: 24}}>
                    {title}
                </AppTitleNormal>
                <MarketTextMini style={{marginBottom: 2}}>
                    {symbol}
                </MarketTextMini>
            </FlexRow>
        </Block>
    );
};
