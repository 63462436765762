import {
    FlexColumnStart,
    FlexRowBetween,
    HalfContent,
    StyledButton,
} from "../../../style/projectComponents";
import React, {FC} from "react";
import styled from "styled-components/macro";
import {StyledSection} from "../../global/StyledSection";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AnimatedPriceTicker} from "../../global/AnimatedPriceTicker";

const TextWrapper = styled(FlexColumnStart)`
  z-index: 100 !important;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 780px) {
    max-width: 100%;
  }
  
  p {
    font-size: 16px;
    color: #fff;
    text-align: left;
    margin-bottom: 15px;
  }
  
`;
const MainSectionWrapper = styled(StyledSection)`
  padding: 0;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  background-image: url("/images/landing/black-abstract-bg.png");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  @media(max-width: 768px) {
    background-image: url("/images/landing/black-abstract-mobile.png");
    background-position: center right;
    background-size: auto;
    height: 890px;
    margin-bottom: 130px;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 42px;
  text-align: start;
  color: #fff;
  margin-bottom: 20px;
  @media (max-width: 780px) {
    width: 100%;
    max-width: 100%;
    font-size: 35px;
    margin-bottom: 470px;
  }
`;
const MobilePhoneImg = styled.img`
  width: 300px;
  @media(max-width: 780px) {
    display: none;
  }
`;

const Content = styled(FlexRowBetween)`
  align-items: center;
  width: calc(100% - 128px);
  margin: auto;
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 36px;
  padding-top: 32px;
  padding-bottom: 32px;
  @media (max-width: 768px) {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 50px;
  }
`;

export const StyledTryButton = styled(StyledButton)`
  padding: 0;
  width: 131px;
  border-radius: 9px;
  height: 44px;
  font-weight: 600;
  background: linear-gradient(90.28deg, #FF8A00 0%, #D449F7 100%);
  margin-top: 14px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HalfRightContent = styled(HalfContent)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const MainSection: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <MainSectionWrapper id={"main"}>
            <Content>
                <HalfContent style={{background: "none"}}>
                    <TextWrapper>
                        <Title>{t("landing.title")}</Title>
                        <p>{t("landing.description1")}</p>
                        <p>{t("landing.description2")}</p>
                        <StyledTryButton onClick={() => navigate("/login")}>
                            {t("landing.join")}
                        </StyledTryButton>
                    </TextWrapper>
                </HalfContent>
                <HalfRightContent style={{background: "none"}}>
                    <MobilePhoneImg src="/images/landing/mobile.png" alt=""/>
                </HalfRightContent>
            </Content>
            <AnimatedPriceTicker/>
        </MainSectionWrapper>
    );
};
