import {
    CloseModalButton,
    ErrorText,
    FlexColumnStart,
    FlexRow,
    StyledButton,
} from "../../style/projectComponents";
import styled from "styled-components/macro";
import {ValidationInputComponent} from "../../components/global/ValidationInputComponent";
import React, {FC, useContext, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useMutation, useQuery} from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import {IAccrualOfInterestReq} from "../../service/admin-api/models";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {DropdownComponent} from "../../components/global/DropdownComponent";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;

  @media (max-width: 480px) {
    width: 320px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: ${({theme}) => theme.colors.modalBg};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

const Content = styled(FlexColumnStart)`
  background: ${({theme}) => theme.colors.gray050};
  border-radius: 16px;
  padding: 20px 25px;
  position: relative;
  width: 416px;
  align-items: center;
  z-index: 100;

  @media (max-width: 480px) {
    padding: 15px;
    width: calc(100% - 50px);
    align-items: center;
  }
`;

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 1rem;

  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  h2 {
    font-size: 20px;
    width: 100%;
  }
`;

export const AdminPercent: FC<{ setIsOpen?: any }> = (props) => {
    const {setIsOpen} = props;

    const {
        control,
        clearErrors,
        formState: {errors},
        handleSubmit,
    } = useForm();

    const GlobalModalStorage = useContext(GlobalModalStore);

    const addPercent = useMutation(
        (data: IAccrualOfInterestReq) => AdminApi.accrualOfInterest(data),
        {
            onSuccess: () => {
                setIsOpen(false);
                GlobalModalStorage.makeVisible(true, t("common.success"));
            },
            onError: (error) => {
                GlobalModalStorage.makeVisible(false, t("common.error"));
                console.log(error);
            },
        }
    );

    const {data: currencyList} = useQuery(
        "currency_list",
        () =>
            AdminApi.getCurrencies({
                skip: 0,
                take: 50,
            })
    );
    const currencyItems = currencyList?.currencies || [];
    const currencyNames = currencyItems.map((c) => c.name) || [];
    const [currencyId, setCurrencyId] = useState<any>(0);
    const handleChangeCurrencyId = (id: number) => {
        setCurrencyId(id);
    };
    const [openCurrencyMenu, setOpenCurrencyMenu] = useState(false);

    const onSubmit = (data: any) => {
        const newData: IAccrualOfInterestReq = {
            comment: "Interest accrual",
            currencyId: currencyItems[currencyId]?.id ?? "",
            percents: parseFloat(parseFloat(data.percents).toFixed(6)),
        };
        addPercent.mutate(newData);
    };

    const {t} = useTranslation();

    return (
        <StyledForm
            onSubmit={handleSubmit(onSubmit)}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    setIsOpen(false);
                }
            }}
        >
            <Content>
                <CloseModalButton onClick={() => setIsOpen(false)}>
                    <img src={"/images/ic_cancel.svg"}/>
                </CloseModalButton>

                <InnerWrapper>
                    <h3>{t("adminPercent.accrualInterest")}</h3>
                    <InputWrapper>
                        <Controller
                            name={"percents"}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Required",
                                },
                                pattern: {
                                    value: /^-?\d{1,7}(?:\.\d{1,2})?$/,
                                    message: "adminPercent.errors.max",
                                },
                                max: {
                                    value: 1000,
                                    message: "adminPercent.errors.max",
                                },
                            }}
                            render={({field: {ref, ...field}}) => (
                                <ValidationInputComponent
                                    {...field}
                                    type={"text"}
                                    maxLength={6}
                                    isError={!!errors["percents"] || addPercent.isError}
                                    label={t("adminPercent.percent")}
                                />
                            )}
                        />
                    </InputWrapper>
                    {errors?.percents?.message && (
                        <ErrorText>{t(errors?.percents?.message.toString())}</ErrorText>
                    )}
                    <DropdownComponent
                        openMenu={openCurrencyMenu}
                        setOpenMenu={setOpenCurrencyMenu}
                        itemId={currencyId}
                        setId={handleChangeCurrencyId}
                        itemList={currencyNames}
                    />
                    <StyledButton
                        onClick={() => clearErrors()}
                        variant="black"
                        type={"submit"}
                        noRadius
                        style={{width: "100%", padding: "1rem"}}
                    >
                        {addPercent.isLoading
                            ? t("adminPercent.processus")
                            : t("adminPercent.add")}
                    </StyledButton>
                </InnerWrapper>
            </Content>
        </StyledForm>
    );
};
