import DashboardPage from "../pages/DashboardPage";
import AssetsPage from "../pages/AssetsPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import { HomePage } from "../pages/HomePage";
import AuthPage from "../pages/AuthPage";
import { RegistrationPage } from "../pages/RegisterPage";
import { AdminAnalytics } from "../pages/AdminPages/AdminAnalytics";
import { AdminWithdraw } from "../pages/AdminPages/AdminWithdraw";
import { AdminOperations } from "../pages/AdminPages/AdminOperations";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword";
import AdminUserDetail from "../pages/AdminPages/AdminUserDetail";
import { AdminWithdrawalHistory } from "../pages/AdminPages/AdminWithdrawalHistory";
import { AmlPage } from "../pages/DocumentsPages/Aml/AmlPage";
import { PrivacyPage } from "../pages/DocumentsPages/Privacy/PrivacyPage";
import { SubscriptionPage } from "../pages/DocumentsPages/Subscription/SubscriptionPage";
import { TermsPage } from "../pages/DocumentsPages/Terms/TermsPage";
import MarketsPage from "../pages/MarketsPage/MarketsPage";
import InvestPage from "../pages/InvestPage/InvestPage";
import { AdminInvestPools } from "../pages/AdminPages/AdminInvestPools";
import { AdminCurrencies } from "../pages/AdminPages/AdminCurrencies";
import ConvertPage from "../pages/ConvertPage/ConvertPage";
import { AdminWallets } from "../pages/AdminPages/AdminWallets";
import ReferralsPage from "../pages/ReferralsPage";

export const publicRoutes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/aml",
    component: AmlPage,
  },
  {
    path: "/privacy",
    component: PrivacyPage,
  },
  {
    path: "/subscription",
    component: SubscriptionPage,
  },
  {
    path: "/login",
    component: AuthPage,
  },
  {
    path: "/register",
    component: RegistrationPage,
  },
  {
    path: "/forgot",
    component: ForgotPassword,
  },
  {
    path: "/reset",
    component: ResetPassword,
  },
  {
    path: "/terms",
    component: TermsPage,
  },
];

export const privateRoutes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    path: "/assets",
    component: AssetsPage,
  },
  {
    path: "/trade-convert",
    component: ConvertPage,
  },
  {
    path: "/settings",
    component: SettingsPage,
  },
  {
    path: "/markets/:pair",
    component: MarketsPage,
  },
  {
    path: "/invest/:pool",
    component: InvestPage,
  },
  {
    path: "/referrals",
    component: ReferralsPage,
  },
];

export const adminRoutes = [
  {
    path: "/admin/analytics",
    component: AdminAnalytics,
  },
  {
    path: "/admin/withdraw",
    component: AdminWithdraw,
  },
  {
    path: "/admin/withdraw-history",
    component: AdminWithdrawalHistory,
  },
  {
    path: "/admin/operations",
    component: AdminOperations,
  },
  {
    path: "/admin/invest-pools",
    component: AdminInvestPools,
  },
  {
    path: "/admin/currencies",
    component: AdminCurrencies,
  },
  {
    path: "/admin/wallets",
    component: AdminWallets,
  },
  {
    path: "/admin/:id?",
    component: AdminUserDetail,
  },
];
