import React from "react";
import styled from "styled-components/macro";
import {
  FlexColumn,
  MarketTextNormal,
  FlexRow,
  SelectButton,
} from "../../style/projectComponents";

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
  color: #000;
  gap: 8px;
`;

interface AddressComponentProps {
  title: string;
  elements: any[];
  selectedId: number;
  selectElement: (id: number) => void;
}

const SelectionPanel: React.FC<AddressComponentProps> = ({
  title,
  elements,
  selectedId,
  selectElement,
}) => {
  return (
    <Block>
      <MarketTextNormal>{title}</MarketTextNormal>
      <FlexRow style={{ gap: 8 }}>
        {elements.map((el, id) => (
          <SelectButton
            key={id}
            onClick={(e) => {
              selectElement(id);
            }}
            type="button"
            selected={selectedId === id}
          >
            {el}
          </SelectButton>
        ))}
      </FlexRow>
    </Block>
  );
};

export default SelectionPanel;
