import React, {useContext, useState} from "react";
import styled from "styled-components/macro";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    FlexRow,
    FlexRowBetween,
    StyledButton,
    StyledLink,
} from "../../style/projectComponents";
import {observer} from "mobx-react-lite";
import MenuModal from "./modal/MenuModal";
import UserStorage from "../../storage/UserStorage/UserStorage";
import {useTranslation} from "react-i18next";
import {Link as ScrollLink} from "react-scroll";
import LanguageModal from "./modal/LanguageModal";
import {LogoutModal} from "./modal/LogoutModal";
import {headerAuthLinks} from "./headerLinks";

interface HeaderComponentProps {
    toggleTheme: any;
    isDarkTheme: boolean;
}

const Header = styled.div<{ isAuth?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  height: 54px;
  background: #111111;
`;

interface ContentProps {
    isLanding: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding-left: 64px;
  padding-right: 64px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Logo = styled.img`
  height: 24px;
  margin: 0 10px;
`;

const RightWrapper = styled(FlexRow)`
  align-items: center;
  margin-right: 20px;
  column-gap: 20px;
  @media (max-width: 600px) {
    width: auto;
  }
`;
const BtnWrapper = styled(FlexRow)`
  column-gap: 0.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const AppMenu = styled(FlexRowBetween)<{
    menuOpen: boolean;
    isAdminPaths: boolean;
}>`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  box-shadow: ${({menuOpen}) =>
          menuOpen ? "1px 4px 8px 1px rgba(0,0,0,0.25)" : "none"};
  display: ${(props) => (props.isAdminPaths ? "flex" : "none")};
  @media (max-width: 1200px) {
    display: flex;
  }
`;

const SettingsButton = styled(FlexRow)`
  width: 20px;
  height: 20px;
  cursor: pointer;

  img {
    width: 20px;
  }
`;
const ExitButton = styled(SettingsButton)`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const SettingsLink = styled(Link)`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;

  img {
    width: 20px;
  }
`;

interface HeaderLinkProps {
    isSelected: boolean;
}

const HeaderLink = styled(StyledLink)<HeaderLinkProps>`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background: ${({isSelected}) => isSelected ? "linear-gradient(84.75deg, #FF8A00 -55.87%, #D449F7 40.4%)" : "none"};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({isSelected}) => isSelected ? "transparent" : "#fff"};

  &:hover {
    background: linear-gradient(84.75deg, #FF8A00 -55.87%, #D449F7 40.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
const LinksWrapper = styled(FlexRow)`
  gap: 32px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const ThemeButtonsWrapper = styled(FlexRow)`
  margin-right: 32px;
  @media (max-width: 480px) {
    display: none;
  }
`;
const ThemeButton = styled(FlexRow)`
  width: 20px;
  height: 20px;
  padding: 8px;
  background: none;
  border-radius: 9px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`;

const useOnWindowScroll = (callback) => {
    const listener = React.useRef<null | any>(null);

    React.useEffect(() => {
        if (listener.current)
            window.removeEventListener("scroll", listener.current);
        listener.current = window.addEventListener("scroll", callback);
        return () => {
            window.removeEventListener("scroll", listener.current);
        };
    }, [callback]);
};

const HeaderComponent: React.FC<HeaderComponentProps> = ({toggleTheme, isDarkTheme}) => {
    const UserStore = useContext(UserStorage);
    const [isScrolled, setIsScrolled] = useState(false);

    useOnWindowScroll(() => {
        if (isScrolled && window.scrollY < 100) {
            setIsScrolled(false);
        } else if (!isScrolled && window.scrollY > 100) {
            setIsScrolled(true);
        }
    });

    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const isAuth = !!UserStore.accessToken;
    const isLanding = location.pathname === "/";
    const isAdminPaths = location.pathname.includes("admin");

    const {t, i18n} = useTranslation();
    const [openLangModal, setOpenLangModal] = useState(false);
    const handleLangMenu = () => {
        setOpenLangModal((bool) => !bool);
    };

    const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
    const isAdmin = UserStore.isAdmin();

    return (
        <Header className={"header"} isAuth={isAuth}>
            <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu}/>
            <Content isLanding={isLanding}>
                {isLanding ? (
                    <ScrollLink
                        to={"main"}
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        style={{display: "flex", cursor: "pointer"}}
                    >
                        <Logo src="/images/defi-logo.svg" alt=""/>
                    </ScrollLink>
                ) : (
                    <Link
                        to={isAuth ? "/dashboard" : "/"}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Logo src="/images/defi-logo.svg" alt=""/>
                    </Link>
                )}

                <LinksWrapper>
                    {isAuth &&
                    !isAdminPaths &&
                    headerAuthLinks.map((link, idx) => (
                        <HeaderLink to={link.to} key={idx} isSelected={location.pathname === link.to}>
                            {t(link.text)}
                        </HeaderLink>
                    ))}
                    {isAuth && isAdmin && (
                        <HeaderLink to={!isAdminPaths ? "/admin/analytics" : "/dashboard"}
                                    isSelected={location.pathname === (!isAdminPaths ? "/admin/analytics" : "/dashboard")}>
                            {t("menu.adminPanel")}
                        </HeaderLink>
                    )}
                </LinksWrapper>

                <RightWrapper>
                    <ThemeButtonsWrapper>
                        <ThemeButton onClick={toggleTheme}
                                     style={{background: isDarkTheme && "linear-gradient(100.72deg, #ff8a00, #d449f7)"}}>
                            <img src="/images/interface/header/moon.svg" alt=""/>
                        </ThemeButton>
                        <ThemeButton onClick={toggleTheme}
                                     style={{background: !isDarkTheme && "linear-gradient(100.72deg, #ff8a00, #d449f7)"}}>
                            <img src="/images/interface/header/sun.svg" alt=""/>
                        </ThemeButton>
                    </ThemeButtonsWrapper>
                    {!isAuth && (
                        <BtnWrapper>
                            <StyledButton onClick={() => navigate("/login")} variant={"text"}
                                          style={{fontSize: 16, fontWeight: 600, color: "#fff"}}>
                                {t("auth.login")}
                            </StyledButton>
                            <StyledButton onClick={() => navigate("/register")}
                                          style={{
                                              background: "#fff",
                                              color: "#000",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              padding: 8,
                                              border: "none"
                                          }}>
                                {t("auth.reg_title")}
                            </StyledButton>
                        </BtnWrapper>
                    )}
                    {!isLanding && isAuth && (
                        <AppMenu
                            onClick={() => setMenuOpen((prev) => !menuOpen)}
                            menuOpen={menuOpen}
                            isAdminPaths
                        >
                            <img
                                width={18}
                                height={18}
                                src={
                                    menuOpen
                                        ? "/images/close16.svg"
                                        : "/images/interface/header/menu.svg"
                                }
                            />
                        </AppMenu>
                    )}
                    <SettingsButton onClick={handleLangMenu}>
                        <img src="/images/interface/header/language.svg" alt=""/>
                    </SettingsButton>
                    {isAuth && (
                        <SettingsLink to="/settings">
                            <img src="/images/interface/header/settings.svg" alt=""/>
                        </SettingsLink>
                    )}
                    {isAuth && (
                        <ExitButton onClick={() => setVisibleLogout(true)}>
                            <img
                                src={"/images/interface/header/exit.svg"}
                                alt=""
                            />
                        </ExitButton>
                    )}
                </RightWrapper>
            </Content>
            <MenuModal
                isScrolled={isScrolled}
                isAuth={isAuth}
                open={menuOpen}
                handleMenu={() => setMenuOpen((prev) => !prev)}
                toggleTheme={toggleTheme}
                isDarkTheme={isDarkTheme}
            />
            {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout}/>}
        </Header>
    );
};

export default observer(HeaderComponent);
