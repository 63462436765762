export const deleteElementById = (id: number, arr: any, setArr: any) => {
    const updatedArray = [...arr];
    if (id >= 0 && id < updatedArray.length) {
        updatedArray.splice(id, 1);
        setArr(updatedArray);
    } else {
        console.error('Invalid ID');
    }
};

export const addElementIfNotExists = (arr: any, setArr: any, newElement:string) => {
    if (!arr.includes(newElement)) {
        const updatedArray = [...arr, newElement];
        setArr(updatedArray);
    } else {
        console.log("element already exist");
    }
}

export const addNumberIfNotExists = (arr: any, setArr: any, newNumber: number) => {
    if (!arr.includes(newNumber)) {
        const updatedArray = [...arr, newNumber];
        setArr(updatedArray);
    } else {
        console.log("element already exist");
    }
}

export const toggleNumberInArray = (arr: any, setArr: any, newNumber: number) => {
    const index = arr.indexOf(newNumber);
    if (index === -1) {
        const updatedArray = [...arr, newNumber];
        setArr(updatedArray);
    } else {
        const updatedArray = arr.filter((_, i) => i !== index);
        setArr(updatedArray);
    }
}

export const deleteWalletById = (id: number, arr: any, setArr: any) => {
    const updatedArray = [...arr];
    if (arr.findIndex(wallet => wallet.id === id)) {
        updatedArray.splice(id, 1);
        setArr(updatedArray);
    }
};