import {bybitApiClient} from "../../config/serverConstants";
import {
    IBybitInterface, IGetOrderBookReq, IGetOrderBookResponse, IGetRecentTradesResponse,
    IGetTickersReq, IGetTickersRes
} from "./models";

class BybitService implements IBybitInterface {

    async getTickers(data: IGetTickersReq) {
        const response = await bybitApiClient.get('/v5/market/tickers', {
            params: data
        });
        return response.data as IGetTickersRes;
    }

    async getMarketRecentTrade(data: IGetTickersReq) {
        const response = await bybitApiClient.get('/v5/market/recent-trade', {
            params: data
        });
        return response.data as IGetRecentTradesResponse;
    }

    async getOrderBook(data: IGetOrderBookReq) {
        const response = await bybitApiClient.get('/v5/market/orderbook', {
            params: data
        });
        return response.data as IGetOrderBookResponse;
    }
}

export default new BybitService();