export const socialLinks = [
    {
        link: "https://t.me/",
        image: "/images/social/tg.svg"
    },
    {
        link: "https://www.facebook.com/",
        image: "/images/social/fb.svg"
    },
    {
        link: "https://twitter.com/",
        image: "/images/social/x.svg"
    },
    {
        link: "https://www.reddit.com",
        image: "/images/social/reddit.svg"
    },
]