import React from "react";
import styled from "styled-components/macro";
import {
    getActiveMarketPair,
    getMarketDataByPair,
} from "../../utils/getActiveMarketPair";
import {
    MarketTextBold,
    FlexRow,
    FlexColumnStart,
    AppTextMini,
    MarketTextBlack, AppTextMiniBold,
} from "../../style/projectComponents";
import getColorByValue from "../../utils/getColorByValue";
import {formatNumber} from "../../utils/formatNumber";
import {useTranslation} from "react-i18next";
import toLocaleNumber from "../../utils/toLocaleNumber";
import {ITicker} from "../../service/bybit-api/models";

const Block = styled(FlexRow)`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0;
  border: none;
  background: none;
  width: 100vw;
  text-decoration: none;
  gap: 32px;
  transform: translateY(2px);
`;

const CurrencyImage = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 100%;
`


interface MarketHeaderProps {
    tickerList: ITicker[] | null;
    activePair: string;
}

export const MarketHeader: React.FC<MarketHeaderProps> = ({
                                                              tickerList,
                                                              activePair,
                                                          }) => {
    const {t} = useTranslation();

    const marketPair = getActiveMarketPair(activePair);
    const pairSymbol = marketPair?.symbol || "";
    const pairName = marketPair.pair;
    const tokenPriceData = getMarketDataByPair(tickerList, activePair);

    // icon
    const icon = `/images/coins/${pairSymbol.toLowerCase()}.${
        pairSymbol === "UNI" ? "jpg" : "png"
    }`;
    // price
    const tokenPrice =
        (tokenPriceData?.lastPrice && toLocaleNumber(tokenPriceData?.lastPrice)) ||
        0;
    const priceLow24h = tokenPriceData?.lowPrice24h || 0;
    const priceHigh24h = tokenPriceData?.highPrice24h || 0;
    // price percent
    const priceChangePercent = tokenPriceData?.price24hPcnt * 100 || 0;
    const priceChangeString = `${
        Number(priceChangePercent) >= 0 ? "+" : ""
    }${Number(priceChangePercent).toFixed(2)}`;
    // volume
    const totalVolume = tokenPriceData?.volume24h || 0;
    const turnover24h = tokenPriceData?.turnover24h || 0;

    return (
        <Block>
            <FlexRow style={{marginLeft: 16, cursor: "pointer"}}>
                <CurrencyImage src={icon} alt=""/>
                <MarketTextBlack>{pairName}</MarketTextBlack>
                <img
                    src="/images/interface/arrow-bottom.png"
                    alt=""
                    style={{marginLeft: 8, width: 12}}
                />
            </FlexRow>
            <FlexColumnStart>
                <MarketTextBold>
                    {toLocaleNumber(Number(tokenPrice))}
                </MarketTextBold>
                <MarketTextBold style={{color: getColorByValue(priceChangePercent)}}>
                    {priceChangeString} %
                </MarketTextBold>
            </FlexColumnStart>
            <FlexColumnStart>
                <AppTextMini>{t("market.low24")}</AppTextMini>
                <AppTextMiniBold>{priceLow24h}</AppTextMiniBold>
            </FlexColumnStart>
            <FlexColumnStart>
                <AppTextMini>{t("market.high24")}</AppTextMini>
                <AppTextMiniBold>{priceHigh24h}</AppTextMiniBold>
            </FlexColumnStart>
            <FlexColumnStart>
                <AppTextMini>{t("market.volume")}</AppTextMini>
                <AppTextMiniBold>{formatNumber(totalVolume)}</AppTextMiniBold>
            </FlexColumnStart>
            <FlexColumnStart>
                <AppTextMini>{t("market.turnover24h")}</AppTextMini>
                <AppTextMiniBold>{formatNumber(turnover24h)}</AppTextMiniBold>
            </FlexColumnStart>
        </Block>
    );
};
