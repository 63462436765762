import React from "react";
import {TabsButton} from "../../style/projectComponents";

interface FilterMethodButtonProps {
    title?: string;
    imageLink?: string;
    isActive: boolean;
    setActiveTypeId: any;
    id: number;
}


export const FilterMethodButton: React.FC<FilterMethodButtonProps> = ({
                                                                          title,
                                                                          isActive,
                                                                          setActiveTypeId,
                                                                          id,
                                                                          imageLink
                                                                      }) => {
    return (
        <TabsButton
            clicked={isActive}
            style={{fontSize: 14, background: !isActive && "none", color: `${({theme}) => theme.colors.black}`}}
            onClick={() => setActiveTypeId(id)}>
            {title && <p>{title}</p>}
            {imageLink && <img src={imageLink} alt="" width={16} height={16}/>}
        </TabsButton>
    );
};
