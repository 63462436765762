import {
    FlexColumn,
    FlexColumnStart,
    FlexRow,
    StyledButton,
} from "../../../style/projectComponents";
import React, {FC} from "react";
import styled from "styled-components/macro";
import {StyledSection} from "../../global/StyledSection";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const MainSectionWrapper = styled(StyledSection)`
  padding: 0;
  align-items: center;
  margin: 0;
  color: #fff;
  margin-bottom: 100px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  text-align: start;
  color: #fff;
  margin-top: 100px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 70px;
    font-size: 21px;
    text-align: center;
    width: 100%;
  }
`;

const Block = styled(FlexColumn)`
  background: #121212;
  border-radius: 20px;
  color: #fff;
  text-align: left;
  align-items: start;
  width: calc(100% - 70px);
  padding: 35px;

  p {
    text-align: left;
  }

  h4 {
    margin: 0;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
    @media (max-width: 1280px) {
      margin-bottom: 10px;
    }
  }
`;
const SmallBlock = styled(Block)`
  height: 267px;
  width: 50%;
  justify-content: start;

  @media (max-width: 1280px) {
    width: 510px;
  }

  @media (max-width: 600px) {
    height: auto;
    padding: 35px;
    width: calc(358px - 70px);
  }
`;
const LeftMainBlock = styled(Block)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 197px;

  img {
    height: 172px;
  }

  @media (max-width: 1280px) {
    width: 510px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    height: 330px;
    align-items: start;
    padding: 35px;
    width: calc(358px - 70px);
  }
`;
const RightMainBlock = styled(LeftMainBlock)`
  padding: 35px;
  height: 555px;
  width: 640px;
  background-image: url("/images/landing/wallet.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  @media (max-width: 1280px) {
    width: 510px;
  }

  @media (max-width: 600px) {
    background-image: url("/images/landing/wallet-mobile.png");
    width: calc(358px - 70px);
    height: 485px;
  }

  p {
    max-width: 60%;
    @media (max-width: 1280px) {
      max-width: 200px;
    }
  }
`;

const TryButton = styled(StyledButton)`
  margin-top: 24px;
  width: 131px;
  height: 44px;
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 14px;
    margin-top: 18px;
  }
`;

const LeftContent = styled(FlexColumn)`
  width: 45vw;
  gap: 24px;
  @media (max-width: 1280px) {
    width: 510px;
  }
  @media (max-width: 600px) {
    width: calc(100vw - 32px);
  }
`;
const SmallBlocksWrapper = styled(FlexRow)`
  gap: 24px;
  width: 100%;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
const SectionWrapper = styled(FlexRow)`
  gap: 20px;
  align-items: start;
  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  max-width: 100%;
  @media (max-width: 1280px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    max-width: 220px;
  }
`;
const FirstBlockText = styled(Text)`
  max-width: 263px;
  @media (max-width: 1280px) {
    max-width: 100%;
  }
  @media (max-width: 600px) {
    max-width: 220px;
  }
`;

export const ExploreSection: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <MainSectionWrapper id={"main"}>
            <Title>{t("landing.explore.title")}</Title>
            <SectionWrapper>
                <LeftContent>
                    <LeftMainBlock>
                        <FlexColumnStart style={{width: "100%"}}>
                            <h4>{t("landing.explore.one1")}</h4>
                            <FirstBlockText>{t("landing.explore.one2")}</FirstBlockText>
                        </FlexColumnStart>
                        <img src="/images/landing/exchange.svg" alt=""/>
                    </LeftMainBlock>
                    <SmallBlocksWrapper>
                        <SmallBlock>
                            <h4>{t("landing.explore.three1")}</h4>
                            <Text>{t("landing.explore.three2")}</Text>
                        </SmallBlock>
                        <SmallBlock>
                            <h4>{t("landing.explore.four1")}</h4>
                            <Text>{t("landing.explore.four2")}</Text>
                        </SmallBlock>
                    </SmallBlocksWrapper>
                </LeftContent>
                <RightMainBlock>
                    <h4>{t("landing.explore.two1")}</h4>
                    <Text>{t("landing.explore.two2")}</Text>
                    <TryButton onClick={() => navigate("/login")}>
                        {t("landing.join")}
                    </TryButton>
                </RightMainBlock>
            </SectionWrapper>
        </MainSectionWrapper>
    );
};
