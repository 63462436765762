import axios, { AxiosInstance } from "axios";

export const SERVER_URL =
  window.location.host === "localhost:3000"
    ? "https://invest-wallet.di-dev.ru"
    : "https://invest-wallet.di-dev.ru";
export const BYBIT_URL = "https://api.bybit.com";

export const apiClient: { isAwaitRefresh?: boolean } & AxiosInstance =
  axios.create({
    baseURL: SERVER_URL,
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    },
  });

export const bybitApiClient = axios.create({
  baseURL: BYBIT_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
  },
});
apiClient.isAwaitRefresh = false;

apiClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${JSON.parse(
    localStorage.accessToken
  )}`;
  return config;
});

apiClient.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      if (apiClient.isAwaitRefresh) {
        return;
      }
      apiClient.isAwaitRefresh = true;

      try {
        const response = await axios.post(
          `${SERVER_URL}/api/Auth/refreshToken`,
          {
            accessToken: JSON.parse(localStorage.accessToken),
            refreshToken: JSON.parse(localStorage.refreshToken),
          }
        );
        localStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.accessToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(response.data.refreshToken)
        );
        return apiClient.request(originalRequest);
      } catch (e) {
        console.log(e);
        localStorage.clear();
        window.location.href = "/login";
      } finally {
        apiClient.isAwaitRefresh = false;
      }
    }
    throw error;
  }
);
