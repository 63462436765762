import React from "react";
import {Oval} from "react-loader-spinner";


export const PageLoader: React.FC = () => {

    return (
        <Oval
            height={30}
            width={30}
            color={`${({theme}) => theme.colors.blue}`}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor={`${({theme}) => theme.colors.primaryActive}`}
            strokeWidth={2}
            strokeWidthSecondary={2}
        />    );
};
