export type IGetAllInvestPoolsResponse = {
    items: IInvestPool[]
    totalCount: number
}

export type IInvestPool = {
    id: string,
    name: string,
    description: string,
    logoDownloadLink: string,
    imageDownloadLink: string,
    tags: string[],
    availableCurrencies: string[],
    term: InvestPoolTerm,
    fixedApr: number,
    flexibleAprFrom: number,
    flexibleAprTo: number,
    period: IPeriod,
    userLimit: number,
    totalLimit: number,
    nearestAccountingDay: string,
    fairAccrual: boolean,
    userWallets: IGetUserWalletInPool[],
    totalBalances: ICurrencyBalance[],
    totalInvestedInUsd: number,
    investedByUserInUsd: number,
    isClosed: boolean
}

export type ICurrencyBalance = {
    currencyName: string,
    balance: number
}

export type IGetUserWalletInPool = {
    id: string,
    currencyId: string,
    currencyName: string,
    balance: number,
    totalIncome: number,
    balanceInUsd: number,
    createDate: string
}

export type ICreateInvestPoolReq = {
    name: string,
    description: string,
    logo: File,
    image: File,
    tags: string[],
    availableCurrenciesIds: string[],
    period: IPeriod,
    term: InvestPoolTerm,
    enableAccrualInterestFromReferal: boolean,
    holdInDays: number,
    firstApr: number,
    secondApr: number,
    userLimit: number,
    totalLimit: number,
    fairAccrual: boolean
}
export type IInvestPoolReq = {
    investPoolId: string,
    value: number,
    tokenId: string
}
export type IAccrualInvestPoolReq = {
    id: string,
    percents: number
}

export type IWithdrawPoolReq = {
    investPoolId: string,
    value: number,
    currencyId: string
}

export const AvailableTerms = [
    {type: "fixed", title: "Fixed"},
    {type: "flexible", title: "Flexible"},
];

export enum InvestPoolTerm {
    fixed = 'fixed',
    flexible = 'flexible'
}

export const AvailablePeriods = [
    {type: "once", title: "Once"},
    {type: "daily", title: "Daily"},
    {type: "monthly", title: "Monthly"},
    {type: "yearly", title: "Yearly"},
];

export enum IPeriod {
    once = 'once',
    daily = 'daily',
    monthly = 'monthly',
    yearly = 'yearly'
}

export type IInvestPoolInterface = {
    getAllInvestPools()
    createInvestPool(data: ICreateInvestPoolReq)
}