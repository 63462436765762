import {useTranslation} from "react-i18next";
import {CircleTitle, DocContent, RectTitle} from "../components";
import {FlexColumnStart} from "../../../style/projectComponents";

export const Second = () => {
    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.69")}


                    <CircleTitle text={t(`documents.terms.1.70`)}/>


                    {t("documents.terms.1.71")}


                    <CircleTitle text={t(`documents.terms.1.72`)}/>


                    {t("documents.terms.1.73")}


                    <CircleTitle text={t(`documents.terms.1.74`)}/>


                    {t("documents.terms.1.75")}


                    <CircleTitle text={t(`documents.terms.1.76`)}/>

                    {t("documents.terms.2.1")}


                    <CircleTitle text={t(`documents.terms.2.2.1`)}/>


                    {t("documents.terms.2.2.2")}


                    <CircleTitle text={t(`documents.terms.2.3`)}/>


                    <CircleTitle text={t(`documents.terms.2.4`)}/>


                    {t("documents.terms.2.5")}


                    <CircleTitle text={t(`documents.terms.2.6`)}/>

                    <FlexColumnStart className={'block'}>
                        <RectTitle text={t(`documents.terms.2.7`)}/>


                        <RectTitle text={t(`documents.terms.2.8`)}/>


                        <RectTitle text={t(`documents.terms.2.9`)}/>


                        <RectTitle text={t(`documents.terms.2.10`)}/>


                        <RectTitle text={t(`documents.terms.2.11`)}/>
                    </FlexColumnStart>


                    {t("documents.terms.2.12")}

                    <CircleTitle text={t(`documents.terms.2.13`)}/>

                    {t("documents.terms.2.14")}

                    <CircleTitle text={t(`documents.terms.2.15`)}/>

                    {t("documents.terms.2.16")}

                    <CircleTitle text={t(`documents.terms.2.17`)}/>

                    {t("documents.terms.2.18")}

                    <CircleTitle text={t(`documents.terms.2.19`)}/>

                    {t("documents.terms.2.20")}

                    <CircleTitle text={t(`documents.terms.2.21`)}/>

                    {t("documents.terms.2.22")}

                    <CircleTitle text={t(`documents.terms.2.23`)}/>

                    <CircleTitle text={t(`documents.terms.2.24`)}/>

                    {t("documents.terms.2.25")}

                    <CircleTitle text={t(`documents.terms.2.26`)}/>
                    <CircleTitle text={t(`documents.terms.2.27`)}/>

                    {t("documents.terms.2.28")}

                    <CircleTitle text={t(`documents.terms.2.29`)}/>
                </p>
            </FlexColumnStart>

        </DocContent>
    );
};
