import { FlexColumn, FlexRow } from "../../../style/projectComponents";
import React, { FC } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { isPasswordGood } from "../Register/SecUtils";
import { useNavigate } from "react-router-dom";
import { DataEmptyComponent } from "../../global/DataEmptyComponent";

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 300px;
  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`;

type IFormValues = {
  resetToken: string;
  newPassword: string;
  badReset: any;
};

type IInputs = {
  name: "resetToken" | "newPassword";
  label: string;
  type: string;
  rules: any;
};

const baseInputs: IInputs[] = [
  {
    name: "resetToken",
    label: "Код сброса",
    type: "text",
    rules: { required: true },
  },
  {
    name: "newPassword",
    label: "Новый пароль",
    type: "password",
    rules: isPasswordGood,
  },
];

const SecondStep: FC = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <FlexColumn style={{ minWidth: 300 }}>
      <DataEmptyComponent text={t("forgotPassword.letterSent")} isEmail />
    </FlexColumn>
  );
};

export default observer(SecondStep);
