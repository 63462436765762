// TradingViewWidget.jsx
import React, {useEffect, useRef} from 'react';
import {marketList} from "../../config/marketList";
import {useTheme} from "styled-components/macro";

interface LivePriceChartProps {
    pair: string;
}

const TradingViewChart: React.FC<LivePriceChartProps> = ({pair}) => {
    const container = useRef();
    const tradingViewLink = pair && marketList.find(p => p.link.toUpperCase().split('/')[2] === pair.toUpperCase())?.tradingView || "";
    const theme = useTheme();
    // @ts-ignore
    const bgColor = theme.colors.white
    const isDark = bgColor === "#000"
    const activeChartTheme = isDark ? "dark" : "light"

    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
         "autosize": true,
          "symbol": "${tradingViewLink}",
          "interval": "H",
          "timezone": "Etc/UTC",
          "theme": "${activeChartTheme}",
          "style": "1",
          "locale": "en",
          "backgroundColor": "${bgColor}",
          "enable_publishing": false,
          "hide_legend": true,
          "hide_side_toolbar": false,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
          }`;
            //@ts-ignore
            container.current.innerHTML = "";
            //@ts-ignore
            container.current.appendChild(script);
        },
        [tradingViewLink, activeChartTheme]
    );

    return (
        <div className="tradingview-widget-container" ref={container} style={{height: "100%", width: "100%"}}>
            <div className="tradingview-widget-container__widget"
                 style={{height: "calc(100% - 32px)", width: "100%"}}></div>
        </div>
    );
};

export default TradingViewChart;
