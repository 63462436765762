import React, {useState} from "react";
import styled from "styled-components/macro";
import {
    AppTitleNormal,
    AppTextNormal,
    FlexColumn,
    FlexRowBetween,
    FlexRow, MarketText, FlexColumnStart, FlexRowBetweenResponsive,
} from "../../style/projectComponents";
import {useTranslation} from "react-i18next";
import {InvestMethodRow} from "./InvestMethodRow";
import {InvestMethodCard} from "./InvestMethodCard";
import {IInvestPool} from "../../service/investpool-api/models";
import {AdminInvestPoolRow} from "../AdminComponents/AdminInvestPoolRow";
import {FilterMethodButton} from "./FilterMethodButton";

const Block = styled(FlexRowBetween)`
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  padding-top: 10px;
  color: ${({theme}) => theme.colors.black};
`;
const LastColumnTextMini = styled(MarketText)`
  width: 140px;
  text-align: right;
  @media (max-width: 768px) {
    display: none;
  }
`;
const CardsWrapper = styled(FlexRow)`
  margin-bottom: 32px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  gap: ${`calc(${7 / 3}%)`};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const InvestMethodsTable = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.gray050};
  width: calc(100% - 40px);
  border-radius: 20px;
  padding: 20px;
`;
const DisplaySelector = styled(FlexRow)`
  gap: 4px;
  border-radius: 8px;
  background: ${({theme}) => theme.colors.gray050};
`

const BlockHeader = styled(FlexRowBetweenResponsive)`
  align-items: end;
  @media (max-width: 768px) {
    align-items: start;
    gap: 16px;
  }
`

interface InvestMethodsComponentProps {
    investPools: IInvestPool[];
    isAdmin?: boolean;
}

export const InvestMethodsComponent: React.FC<InvestMethodsComponentProps> = ({
                                                                                  investPools,
                                                                                  isAdmin,
                                                                              }) => {
    const {t} = useTranslation();
    const isInvestPoolsExist = investPools?.length > 0;
    const openedPools = investPools.filter((i) => !i.isClosed);

    const [displayId, setDisplayId] = useState(isAdmin ? 1 : 0)
    const displayVariants = ["/images/buttons/sort/cards.png", "/images/buttons/sort/table.png"]

    return (
        <FlexColumn style={{width: "100%"}}>
            {isInvestPoolsExist && (
                <Block style={{flexDirection: "column", marginBottom: 32}}>
                    <BlockHeader>
                        <FlexColumnStart>
                            <AppTitleNormal style={{marginBottom: 10}}>
                                {t("invest.title")}
                            </AppTitleNormal>
                            <AppTextNormal style={{fontSize: 14}}>
                                {t("invest.description")}
                            </AppTextNormal>
                        </FlexColumnStart>
                        {!isAdmin && <DisplaySelector>
                            {displayVariants.map((item, idx) => (
                                <FilterMethodButton
                                    imageLink={item}
                                    isActive={idx === displayId}
                                    setActiveTypeId={setDisplayId}
                                    id={idx}
                                    key={idx}
                                />
                            ))}
                        </DisplaySelector>}
                    </BlockHeader>
                </Block>
            )}
            {!isAdmin && (
                <CardsWrapper>
                    {displayId === 0 && isInvestPoolsExist && openedPools.map((iPool, id) => <InvestMethodCard iPool={iPool}
                                                                                            key={`key${iPool.name}`}/>)}
                </CardsWrapper>
            )}
            {displayId === 1 && <InvestMethodsTable>
                {isInvestPoolsExist && (
                    <Block>
                        <MarketText style={{width: 150}}>
                            {t("invest.table.title")}
                        </MarketText>
                        <MarketText style={{width: 140, textAlign: "right"}}>
                            {t("invest.table.tokens")}
                        </MarketText>
                        <MarketText style={{width: 140, textAlign: "right"}}>
                            {t("invest.table.estApr")}
                        </MarketText>
                        <MarketText style={{width: 140, textAlign: "right"}}>
                            {t("invest.table.term")}
                        </MarketText>
                        <LastColumnTextMini>{t("invest.table.period")}</LastColumnTextMini>
                    </Block>
                )}
                {!isAdmin &&
                isInvestPoolsExist &&
                openedPools.map(
                    (iPool, id) => <InvestMethodRow iPool={iPool} id={id} key={iPool.id}/>
                )}
                {isAdmin &&
                investPools.map((iPool, id) => (
                    <AdminInvestPoolRow iPool={iPool} id={id} key={iPool.id}/>
                ))}
            </InvestMethodsTable>}
        </FlexColumn>
    );
};
