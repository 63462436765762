export const mockPricesData = [
    {
        "id": "bitcoin",
        "symbol": "btc",
        "name": "Bitcoin",
        "image": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
        "current_price": 42813,
        "market_cap": 838898372682,
        "market_cap_rank": 1,
        "fully_diluted_valuation": 898940675093,
        "total_volume": 43636428674,
        "high_24h": 46371,
        "low_24h": 41730,
        "price_change_24h": -3207.055387574903,
        "price_change_percentage_24h": -6.96883,
        "market_cap_change_24h": -63004061614.93713,
        "market_cap_change_percentage_24h": -6.98568,
        "circulating_supply": 19597362,
        "total_supply": 21000000,
        "max_supply": 21000000,
        "ath": 69045,
        "ath_change_percentage": -37.96869,
        "ath_date": "2021-11-10T14:24:11.849Z",
        "atl": 67.81,
        "atl_change_percentage": 63061.78208,
        "atl_date": "2013-07-06T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:45.617Z"
    },
    {
        "id": "ethereum",
        "symbol": "eth",
        "name": "Ethereum",
        "image": "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628",
        "current_price": 2545.62,
        "market_cap": 305591828729,
        "market_cap_rank": 2,
        "fully_diluted_valuation": 305592301538,
        "total_volume": 48409474567,
        "high_24h": 2698.43,
        "low_24h": 2458.7,
        "price_change_24h": -50.40896382222763,
        "price_change_percentage_24h": -1.94177,
        "market_cap_change_24h": -6544503870.098633,
        "market_cap_change_percentage_24h": -2.09668,
        "circulating_supply": 120181204.341188,
        "total_supply": 120181390.284252,
        "max_supply": null,
        "ath": 4878.26,
        "ath_change_percentage": -47.8224,
        "ath_date": "2021-11-10T14:24:19.604Z",
        "atl": 0.432979,
        "atl_change_percentage": 587771.53411,
        "atl_date": "2015-10-20T00:00:00.000Z",
        "roi": {
            "times": 78.45477551050169,
            "currency": "btc",
            "percentage": 7845.477551050169
        },
        "last_updated": "2024-01-13T05:14:04.716Z"
    },
    {
        "id": "tether",
        "symbol": "usdt",
        "name": "Tether",
        "image": "https://assets.coingecko.com/coins/images/325/large/Tether.png?1696501661",
        "current_price": 0.99987,
        "market_cap": 94998525415,
        "market_cap_rank": 3,
        "fully_diluted_valuation": 94998525415,
        "total_volume": 106692244336,
        "high_24h": 1.003,
        "low_24h": 0.98976,
        "price_change_24h": -0.000923390672543989,
        "price_change_percentage_24h": -0.09227,
        "market_cap_change_24h": -78845699.59283447,
        "market_cap_change_percentage_24h": -0.08293,
        "circulating_supply": 95010835101.6419,
        "total_supply": 95010835101.6419,
        "max_supply": null,
        "ath": 1.32,
        "ath_change_percentage": -24.37534,
        "ath_date": "2018-07-24T00:00:00.000Z",
        "atl": 0.572521,
        "atl_change_percentage": 74.76848,
        "atl_date": "2015-03-02T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:10:19.117Z"
    },
    {
        "id": "binancecoin",
        "symbol": "bnb",
        "name": "BNB",
        "image": "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1696501970",
        "current_price": 297.17,
        "market_cap": 45682741756,
        "market_cap_rank": 4,
        "fully_diluted_valuation": 45682741756,
        "total_volume": 1328863841,
        "high_24h": 312.82,
        "low_24h": 288.64,
        "price_change_24h": -14.574186594344155,
        "price_change_percentage_24h": -4.67497,
        "market_cap_change_24h": -2311681527.657425,
        "market_cap_change_percentage_24h": -4.81656,
        "circulating_supply": 153856150,
        "total_supply": 153856150,
        "max_supply": 200000000,
        "ath": 686.31,
        "ath_change_percentage": -56.73892,
        "ath_date": "2021-05-10T07:24:17.097Z",
        "atl": 0.0398177,
        "atl_change_percentage": 745557.55122,
        "atl_date": "2017-10-19T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:11.388Z"
    },
    {
        "id": "solana",
        "symbol": "sol",
        "name": "Solana",
        "image": "https://assets.coingecko.com/coins/images/4128/large/solana.png?1696504756",
        "current_price": 91.42,
        "market_cap": 39539584085,
        "market_cap_rank": 5,
        "fully_diluted_valuation": 51835451285,
        "total_volume": 2793481120,
        "high_24h": 100.25,
        "low_24h": 88.48,
        "price_change_24h": -6.977990440781312,
        "price_change_percentage_24h": -7.0918,
        "market_cap_change_24h": -2964219486.145653,
        "market_cap_change_percentage_24h": -6.97401,
        "circulating_supply": 432549281.861621,
        "total_supply": 567061787.503762,
        "max_supply": null,
        "ath": 259.96,
        "ath_change_percentage": -64.75831,
        "ath_date": "2021-11-06T21:54:35.825Z",
        "atl": 0.500801,
        "atl_change_percentage": 18193.49407,
        "atl_date": "2020-05-11T19:35:23.449Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:49.167Z"
    },
    {
        "id": "ripple",
        "symbol": "xrp",
        "name": "XRP",
        "image": "https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1696501442",
        "current_price": 0.573422,
        "market_cap": 31116963991,
        "market_cap_rank": 6,
        "fully_diluted_valuation": 57333805713,
        "total_volume": 1496080787,
        "high_24h": 0.601882,
        "low_24h": 0.561144,
        "price_change_24h": -0.026016270519809037,
        "price_change_percentage_24h": -4.34011,
        "market_cap_change_24h": -1404064265.434208,
        "market_cap_change_percentage_24h": -4.3174,
        "circulating_supply": 54266821529,
        "total_supply": 99988013070,
        "max_supply": 100000000000,
        "ath": 3.4,
        "ath_change_percentage": -83.13194,
        "ath_date": "2018-01-07T00:00:00.000Z",
        "atl": 0.00268621,
        "atl_change_percentage": 21240.60384,
        "atl_date": "2014-05-22T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:56.657Z"
    },
    {
        "id": "usd-coin",
        "symbol": "usdc",
        "name": "USDC",
        "image": "https://assets.coingecko.com/coins/images/6319/large/usdc.png?1696506694",
        "current_price": 1.001,
        "market_cap": 25462994506,
        "market_cap_rank": 7,
        "fully_diluted_valuation": 25437977954,
        "total_volume": 21280703100,
        "high_24h": 1.004,
        "low_24h": 0.988261,
        "price_change_24h": 0.00019491,
        "price_change_percentage_24h": 0.01948,
        "market_cap_change_24h": 190094108,
        "market_cap_change_percentage_24h": 0.75217,
        "circulating_supply": 25454606923.2374,
        "total_supply": 25429598612.4246,
        "max_supply": null,
        "ath": 1.17,
        "ath_change_percentage": -14.69682,
        "ath_date": "2019-05-08T00:40:28.300Z",
        "atl": 0.877647,
        "atl_change_percentage": 13.98159,
        "atl_date": "2023-03-11T08:02:13.981Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:04.149Z"
    },
    {
        "id": "staked-ether",
        "symbol": "steth",
        "name": "Lido Staked Ether",
        "image": "https://assets.coingecko.com/coins/images/13442/large/steth_logo.png?1696513206",
        "current_price": 2543.82,
        "market_cap": 23719942440,
        "market_cap_rank": 8,
        "fully_diluted_valuation": 23719942440,
        "total_volume": 16878176,
        "high_24h": 2703.98,
        "low_24h": 2437.7,
        "price_change_24h": -51.67715521328046,
        "price_change_percentage_24h": -1.99103,
        "market_cap_change_24h": -466178580.03227615,
        "market_cap_change_percentage_24h": -1.92746,
        "circulating_supply": 9324528.01410916,
        "total_supply": 9324528.01410916,
        "max_supply": 9324528.01410916,
        "ath": 4829.57,
        "ath_change_percentage": -47.3553,
        "ath_date": "2021-11-10T14:40:47.256Z",
        "atl": 482.9,
        "atl_change_percentage": 426.51334,
        "atl_date": "2020-12-22T04:08:21.854Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:56.586Z"
    },
    {
        "id": "cardano",
        "symbol": "ada",
        "name": "Cardano",
        "image": "https://assets.coingecko.com/coins/images/975/large/cardano.png?1696502090",
        "current_price": 0.54636,
        "market_cap": 19152987597,
        "market_cap_rank": 9,
        "fully_diluted_valuation": 24581405474,
        "total_volume": 680699101,
        "high_24h": 0.588014,
        "low_24h": 0.534024,
        "price_change_24h": -0.03638146379649088,
        "price_change_percentage_24h": -6.24316,
        "market_cap_change_24h": -1278156836.2209625,
        "market_cap_change_percentage_24h": -6.25592,
        "circulating_supply": 35062455754.9956,
        "total_supply": 45000000000,
        "max_supply": 45000000000,
        "ath": 3.09,
        "ath_change_percentage": -82.28425,
        "ath_date": "2021-09-02T06:00:10.474Z",
        "atl": 0.01925275,
        "atl_change_percentage": 2740.47518,
        "atl_date": "2020-03-13T02:22:55.044Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:02.778Z"
    },
    {
        "id": "avalanche-2",
        "symbol": "avax",
        "name": "Avalanche",
        "image": "https://assets.coingecko.com/coins/images/12559/large/Avalanche_Circle_RedWhite_Trans.png?1696512369",
        "current_price": 35.54,
        "market_cap": 13028416680,
        "market_cap_rank": 10,
        "fully_diluted_valuation": 15445971609,
        "total_volume": 867192993,
        "high_24h": 39.31,
        "low_24h": 34.88,
        "price_change_24h": -3.4136130858182696,
        "price_change_percentage_24h": -8.76438,
        "market_cap_change_24h": -1234542589.2899494,
        "market_cap_change_percentage_24h": -8.65559,
        "circulating_supply": 366635097.111901,
        "total_supply": 434667960.037232,
        "max_supply": 720000000,
        "ath": 144.96,
        "ath_change_percentage": -75.46968,
        "ath_date": "2021-11-21T14:18:56.538Z",
        "atl": 2.8,
        "atl_change_percentage": 1169.49782,
        "atl_date": "2020-12-31T13:15:21.540Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:04.875Z"
    },
    {
        "id": "dogecoin",
        "symbol": "doge",
        "name": "Dogecoin",
        "image": "https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1696501409",
        "current_price": 0.080524,
        "market_cap": 11482744648,
        "market_cap_rank": 11,
        "fully_diluted_valuation": 11482662532,
        "total_volume": 640840343,
        "high_24h": 0.085479,
        "low_24h": 0.078262,
        "price_change_24h": -0.003461401223345054,
        "price_change_percentage_24h": -4.12144,
        "market_cap_change_24h": -492977069.3530884,
        "market_cap_change_percentage_24h": -4.11647,
        "circulating_supply": 142631616383.705,
        "total_supply": 142630596383.705,
        "max_supply": null,
        "ath": 0.731578,
        "ath_change_percentage": -88.9894,
        "ath_date": "2021-05-08T05:08:23.458Z",
        "atl": 0.0000869,
        "atl_change_percentage": 92590.01353,
        "atl_date": "2015-05-06T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:51.983Z"
    },
    {
        "id": "polkadot",
        "symbol": "dot",
        "name": "Polkadot",
        "image": "https://assets.coingecko.com/coins/images/12171/large/polkadot.png?1696512008",
        "current_price": 7.61,
        "market_cap": 10025903318,
        "market_cap_rank": 12,
        "fully_diluted_valuation": 10641641016,
        "total_volume": 368291749,
        "high_24h": 8.2,
        "low_24h": 7.42,
        "price_change_24h": -0.511680226036634,
        "price_change_percentage_24h": -6.29913,
        "market_cap_change_24h": -674531493.8833447,
        "market_cap_change_percentage_24h": -6.30378,
        "circulating_supply": 1318055264.64321,
        "total_supply": 1399003214.02092,
        "max_supply": null,
        "ath": 54.98,
        "ath_change_percentage": -86.1541,
        "ath_date": "2021-11-04T14:10:09.301Z",
        "atl": 2.7,
        "atl_change_percentage": 182.21417,
        "atl_date": "2020-08-20T05:48:11.359Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:12.691Z"
    },
    {
        "id": "tron",
        "symbol": "trx",
        "name": "TRON",
        "image": "https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1696502193",
        "current_price": 0.106897,
        "market_cap": 9428170998,
        "market_cap_rank": 13,
        "fully_diluted_valuation": 9428180921,
        "total_volume": 573308442,
        "high_24h": 0.108161,
        "low_24h": 0.105056,
        "price_change_24h": -0.000125484101343332,
        "price_change_percentage_24h": -0.11725,
        "market_cap_change_24h": -3476147.292581558,
        "market_cap_change_percentage_24h": -0.03686,
        "circulating_supply": 88245148034.508,
        "total_supply": 88245240907.1324,
        "max_supply": null,
        "ath": 0.231673,
        "ath_change_percentage": -53.9019,
        "ath_date": "2018-01-05T00:00:00.000Z",
        "atl": 0.00180434,
        "atl_change_percentage": 5818.87522,
        "atl_date": "2017-11-12T00:00:00.000Z",
        "roi": {
            "times": 55.26147717468884,
            "currency": "usd",
            "percentage": 5526.147717468884
        },
        "last_updated": "2024-01-13T05:14:12.448Z"
    },
    {
        "id": "chainlink",
        "symbol": "link",
        "name": "Chainlink",
        "image": "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1696502009",
        "current_price": 14.1,
        "market_cap": 8010538669,
        "market_cap_rank": 14,
        "fully_diluted_valuation": 14100579253,
        "total_volume": 664093629,
        "high_24h": 15.25,
        "low_24h": 13.77,
        "price_change_24h": -0.7320045084209426,
        "price_change_percentage_24h": -4.93639,
        "market_cap_change_24h": -411065730.3461933,
        "market_cap_change_percentage_24h": -4.88109,
        "circulating_supply": 568099971.3083414,
        "total_supply": 1000000000,
        "max_supply": 1000000000,
        "ath": 52.7,
        "ath_change_percentage": -73.22683,
        "ath_date": "2021-05-10T00:13:57.214Z",
        "atl": 0.148183,
        "atl_change_percentage": 9421.02021,
        "atl_date": "2017-11-29T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:58.563Z"
    },
    {
        "id": "matic-network",
        "symbol": "matic",
        "name": "Polygon",
        "image": "https://assets.coingecko.com/coins/images/4713/large/polygon.png?1698233745",
        "current_price": 0.861961,
        "market_cap": 7993812915,
        "market_cap_rank": 15,
        "fully_diluted_valuation": 8611291083,
        "total_volume": 700022252,
        "high_24h": 0.942526,
        "low_24h": 0.845799,
        "price_change_24h": -0.05396634062514838,
        "price_change_percentage_24h": -5.89199,
        "market_cap_change_24h": -502751847.08565426,
        "market_cap_change_percentage_24h": -5.91712,
        "circulating_supply": 9282943566.203985,
        "total_supply": 10000000000,
        "max_supply": 10000000000,
        "ath": 2.92,
        "ath_change_percentage": -70.44157,
        "ath_date": "2021-12-27T02:08:34.307Z",
        "atl": 0.00314376,
        "atl_change_percentage": 27319.4472,
        "atl_date": "2019-05-10T00:00:00.000Z",
        "roi": {
            "times": 326.7419578677881,
            "currency": "usd",
            "percentage": 32674.195786778808
        },
        "last_updated": "2024-01-13T05:14:05.466Z"
    },
    {
        "id": "the-open-network",
        "symbol": "ton",
        "name": "Toncoin",
        "image": "https://assets.coingecko.com/coins/images/17980/large/ton_symbol.png?1696517498",
        "current_price": 2.12,
        "market_cap": 7312337191,
        "market_cap_rank": 16,
        "fully_diluted_valuation": 10791150355,
        "total_volume": 52653664,
        "high_24h": 2.22,
        "low_24h": 2.1,
        "price_change_24h": -0.09516946064344456,
        "price_change_percentage_24h": -4.30505,
        "market_cap_change_24h": -331672060.7135992,
        "market_cap_change_percentage_24h": -4.33898,
        "circulating_supply": 3456595898.68246,
        "total_supply": 5101056623.51598,
        "max_supply": null,
        "ath": 5.29,
        "ath_change_percentage": -60.06023,
        "ath_date": "2021-11-12T06:50:02.476Z",
        "atl": 0.519364,
        "atl_change_percentage": 306.86928,
        "atl_date": "2021-09-21T00:33:11.092Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:49.810Z"
    },
    {
        "id": "wrapped-bitcoin",
        "symbol": "wbtc",
        "name": "Wrapped Bitcoin",
        "image": "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1696507857",
        "current_price": 42835,
        "market_cap": 6775162899,
        "market_cap_rank": 17,
        "fully_diluted_valuation": 6775162899,
        "total_volume": 755018567,
        "high_24h": 46328,
        "low_24h": 41776,
        "price_change_24h": -3099.29446821248,
        "price_change_percentage_24h": -6.74724,
        "market_cap_change_24h": -489196119.8631878,
        "market_cap_change_percentage_24h": -6.7342,
        "circulating_supply": 158168.98879455,
        "total_supply": 158168.98879455,
        "max_supply": 158168.98879455,
        "ath": 70643,
        "ath_change_percentage": -39.40166,
        "ath_date": "2021-11-10T14:40:19.650Z",
        "atl": 3139.17,
        "atl_change_percentage": 1263.69431,
        "atl_date": "2019-04-02T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:02.684Z"
    },
    {
        "id": "shiba-inu",
        "symbol": "shib",
        "name": "Shiba Inu",
        "image": "https://assets.coingecko.com/coins/images/11939/large/shiba.png?1696511800",
        "current_price": 0.00000975,
        "market_cap": 5742284678,
        "market_cap_rank": 18,
        "fully_diluted_valuation": 9744377280,
        "total_volume": 204792615,
        "high_24h": 0.00001029,
        "low_24h": 0.00000941,
        "price_change_24h": -4.11705418415e-7,
        "price_change_percentage_24h": -4.05195,
        "market_cap_change_24h": -242618266.68457794,
        "market_cap_change_percentage_24h": -4.05384,
        "circulating_supply": 589281734938786.8,
        "total_supply": 999982388663919,
        "max_supply": null,
        "ath": 0.00008616,
        "ath_change_percentage": -88.70277,
        "ath_date": "2021-10-28T03:54:55.568Z",
        "atl": 5.6366e-11,
        "atl_change_percentage": 17268265.95609,
        "atl_date": "2020-11-28T11:26:25.838Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:56.829Z"
    },
    {
        "id": "internet-computer",
        "symbol": "icp",
        "name": "Internet Computer",
        "image": "https://assets.coingecko.com/coins/images/14495/large/Internet_Computer_logo.png?1696514180",
        "current_price": 12.49,
        "market_cap": 5677125601,
        "market_cap_rank": 19,
        "fully_diluted_valuation": 6398284043,
        "total_volume": 214997190,
        "high_24h": 13,
        "low_24h": 11.94,
        "price_change_24h": -0.3147867568203626,
        "price_change_percentage_24h": -2.45885,
        "market_cap_change_24h": -141234765.60134792,
        "market_cap_change_percentage_24h": -2.4274,
        "circulating_supply": 455142944.899901,
        "total_supply": 512959206.156472,
        "max_supply": null,
        "ath": 700.65,
        "ath_change_percentage": -98.2259,
        "ath_date": "2021-05-10T16:05:53.653Z",
        "atl": 2.87,
        "atl_change_percentage": 333.86088,
        "atl_date": "2023-09-22T00:29:57.369Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:11.513Z"
    },
    {
        "id": "litecoin",
        "symbol": "ltc",
        "name": "Litecoin",
        "image": "https://assets.coingecko.com/coins/images/2/large/litecoin.png?1696501400",
        "current_price": 72.1,
        "market_cap": 5339224896,
        "market_cap_rank": 20,
        "fully_diluted_valuation": 6053572934,
        "total_volume": 2072895060,
        "high_24h": 76.64,
        "low_24h": 70.23,
        "price_change_24h": -1.141651559017859,
        "price_change_percentage_24h": -1.55878,
        "market_cap_change_24h": -85090110.00545311,
        "market_cap_change_percentage_24h": -1.56868,
        "circulating_supply": 74087633.2334713,
        "total_supply": 84000000,
        "max_supply": 84000000,
        "ath": 410.26,
        "ath_change_percentage": -82.40225,
        "ath_date": "2021-05-10T03:13:07.904Z",
        "atl": 1.15,
        "atl_change_percentage": 6184.28093,
        "atl_date": "2015-01-14T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:58.455Z"
    },
    {
        "id": "dai",
        "symbol": "dai",
        "name": "Dai",
        "image": "https://assets.coingecko.com/coins/images/9956/large/Badge_Dai.png?1696509996",
        "current_price": 1.001,
        "market_cap": 5259987011,
        "market_cap_rank": 21,
        "fully_diluted_valuation": 5259987011,
        "total_volume": 289011229,
        "high_24h": 1.005,
        "low_24h": 0.990264,
        "price_change_24h": 0.00176921,
        "price_change_percentage_24h": 0.1771,
        "market_cap_change_24h": -33350369.73675251,
        "market_cap_change_percentage_24h": -0.63004,
        "circulating_supply": 5255841121.01834,
        "total_supply": 5255841121.01834,
        "max_supply": null,
        "ath": 1.22,
        "ath_change_percentage": -17.85167,
        "ath_date": "2020-03-13T03:02:50.373Z",
        "atl": 0.88196,
        "atl_change_percentage": 13.53477,
        "atl_date": "2023-03-11T07:50:50.514Z",
        "roi": null,
        "last_updated": "2024-01-13T05:10:13.477Z"
    },
    {
        "id": "bitcoin-cash",
        "symbol": "bch",
        "name": "Bitcoin Cash",
        "image": "https://assets.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png?1696501932",
        "current_price": 260.31,
        "market_cap": 5102160533,
        "market_cap_rank": 22,
        "fully_diluted_valuation": 5463071206,
        "total_volume": 487777303,
        "high_24h": 295.85,
        "low_24h": 252.88,
        "price_change_24h": -34.88127454053739,
        "price_change_percentage_24h": -11.81653,
        "market_cap_change_24h": -668613776.738411,
        "market_cap_change_percentage_24h": -11.58621,
        "circulating_supply": 19612662.3966508,
        "total_supply": 21000000,
        "max_supply": 21000000,
        "ath": 3785.82,
        "ath_change_percentage": -93.12308,
        "ath_date": "2017-12-20T00:00:00.000Z",
        "atl": 76.93,
        "atl_change_percentage": 238.40036,
        "atl_date": "2018-12-16T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:12.883Z"
    },
    {
        "id": "uniswap",
        "symbol": "uni",
        "name": "Uniswap",
        "image": "https://assets.coingecko.com/coins/images/12504/large/uni.jpg?1696512319",
        "current_price": 6.62,
        "market_cap": 4991068763,
        "market_cap_rank": 23,
        "fully_diluted_valuation": 6621503685,
        "total_volume": 398254990,
        "high_24h": 6.95,
        "low_24h": 6.32,
        "price_change_24h": -0.12386787181107373,
        "price_change_percentage_24h": -1.83633,
        "market_cap_change_24h": -80203642.4803648,
        "market_cap_change_percentage_24h": -1.58153,
        "circulating_supply": 753766667,
        "total_supply": 1000000000,
        "max_supply": 1000000000,
        "ath": 44.92,
        "ath_change_percentage": -85.21477,
        "ath_date": "2021-05-03T05:25:04.822Z",
        "atl": 1.03,
        "atl_change_percentage": 544.67331,
        "atl_date": "2020-09-17T01:20:38.214Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:56.618Z"
    },
    {
        "id": "ethereum-classic",
        "symbol": "etc",
        "name": "Ethereum Classic",
        "image": "https://assets.coingecko.com/coins/images/453/large/ethereum-classic-logo.png?1696501717",
        "current_price": 29.55,
        "market_cap": 4230817609,
        "market_cap_rank": 24,
        "fully_diluted_valuation": 6225000523,
        "total_volume": 848539413,
        "high_24h": 32.72,
        "low_24h": 27.83,
        "price_change_24h": 0.061283,
        "price_change_percentage_24h": 0.2078,
        "market_cap_change_24h": 2551028,
        "market_cap_change_percentage_24h": 0.06033,
        "circulating_supply": 143202119.751449,
        "total_supply": 210700000,
        "max_supply": 210700000,
        "ath": 167.09,
        "ath_change_percentage": -82.27178,
        "ath_date": "2021-05-06T18:34:22.133Z",
        "atl": 0.615038,
        "atl_change_percentage": 4716.16826,
        "atl_date": "2016-07-25T00:00:00.000Z",
        "roi": {
            "times": 64.67341971961866,
            "currency": "usd",
            "percentage": 6467.341971961867
        },
        "last_updated": "2024-01-13T05:14:14.613Z"
    },
    {
        "id": "cosmos",
        "symbol": "atom",
        "name": "Cosmos Hub",
        "image": "https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1696502525",
        "current_price": 9.99,
        "market_cap": 3820291052,
        "market_cap_rank": 25,
        "fully_diluted_valuation": 3820291052,
        "total_volume": 270942422,
        "high_24h": 10.92,
        "low_24h": 9.73,
        "price_change_24h": -0.8081985064298074,
        "price_change_percentage_24h": -7.48308,
        "market_cap_change_24h": -304019528.26859903,
        "market_cap_change_percentage_24h": -7.3714,
        "circulating_supply": 382584827.895706,
        "total_supply": 382584827.895706,
        "max_supply": null,
        "ath": 44.45,
        "ath_change_percentage": -77.50978,
        "ath_date": "2022-01-17T00:34:41.497Z",
        "atl": 1.16,
        "atl_change_percentage": 761.72007,
        "atl_date": "2020-03-13T02:27:44.591Z",
        "roi": {
            "times": 98.92144906642093,
            "currency": "usd",
            "percentage": 9892.144906642094
        },
        "last_updated": "2024-01-13T05:14:09.618Z"
    },
    {
        "id": "leo-token",
        "symbol": "leo",
        "name": "LEO Token",
        "image": "https://assets.coingecko.com/coins/images/8418/large/leo-token.png?1696508607",
        "current_price": 4.05,
        "market_cap": 3756052361,
        "market_cap_rank": 26,
        "fully_diluted_valuation": 3988025013,
        "total_volume": 1608900,
        "high_24h": 4.08,
        "low_24h": 4.01,
        "price_change_24h": 0.0110913,
        "price_change_percentage_24h": 0.27432,
        "market_cap_change_24h": 2911510,
        "market_cap_change_percentage_24h": 0.07758,
        "circulating_supply": 927930780.9,
        "total_supply": 985239504,
        "max_supply": null,
        "ath": 8.14,
        "ath_change_percentage": -50.39394,
        "ath_date": "2022-02-08T17:40:10.285Z",
        "atl": 0.799859,
        "atl_change_percentage": 404.54178,
        "atl_date": "2019-12-24T15:14:35.376Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:08.895Z"
    },
    {
        "id": "optimism",
        "symbol": "op",
        "name": "Optimism",
        "image": "https://assets.coingecko.com/coins/images/25244/large/Optimism.png?1696524385",
        "current_price": 3.88,
        "market_cap": 3710247410,
        "market_cap_rank": 27,
        "fully_diluted_valuation": 16644817232,
        "total_volume": 787666184,
        "high_24h": 4.23,
        "low_24h": 3.72,
        "price_change_24h": 0.079598,
        "price_change_percentage_24h": 2.09509,
        "market_cap_change_24h": 73904046,
        "market_cap_change_percentage_24h": 2.03237,
        "circulating_supply": 957378568,
        "total_supply": 4294967296,
        "max_supply": 4294967296,
        "ath": 4.23,
        "ath_change_percentage": -8.30848,
        "ath_date": "2024-01-12T13:44:50.085Z",
        "atl": 0.402159,
        "atl_change_percentage": 864.04184,
        "atl_date": "2022-06-18T20:54:52.178Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:05.191Z"
    },
    {
        "id": "near",
        "symbol": "near",
        "name": "NEAR Protocol",
        "image": "https://assets.coingecko.com/coins/images/10365/large/near.jpg?1696510367",
        "current_price": 3.42,
        "market_cap": 3463048834,
        "market_cap_rank": 28,
        "fully_diluted_valuation": 4001715870,
        "total_volume": 288657975,
        "high_24h": 3.67,
        "low_24h": 3.32,
        "price_change_24h": -0.1598255143468985,
        "price_change_percentage_24h": -4.46663,
        "market_cap_change_24h": -162737526.60196733,
        "market_cap_change_percentage_24h": -4.48834,
        "circulating_supply": 1014215429.43874,
        "total_supply": 1171973649.18893,
        "max_supply": null,
        "ath": 20.44,
        "ath_change_percentage": -83.3243,
        "ath_date": "2022-01-16T22:09:45.873Z",
        "atl": 0.526762,
        "atl_change_percentage": 547.00898,
        "atl_date": "2020-11-04T16:09:15.137Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:14.316Z"
    },
    {
        "id": "stellar",
        "symbol": "xlm",
        "name": "Stellar",
        "image": "https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1696501482",
        "current_price": 0.119266,
        "market_cap": 3376052525,
        "market_cap_rank": 29,
        "fully_diluted_valuation": 5957806883,
        "total_volume": 92674119,
        "high_24h": 0.125312,
        "low_24h": 0.116997,
        "price_change_24h": -0.004169953358632184,
        "price_change_percentage_24h": -3.37824,
        "market_cap_change_24h": -116663520.78946733,
        "market_cap_change_percentage_24h": -3.34019,
        "circulating_supply": 28334026723.1077,
        "total_supply": 50001787052.8864,
        "max_supply": 50001787052.8864,
        "ath": 0.875563,
        "ath_change_percentage": -86.38689,
        "ath_date": "2018-01-03T00:00:00.000Z",
        "atl": 0.00047612,
        "atl_change_percentage": 24933.71601,
        "atl_date": "2015-03-05T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:59.881Z"
    },
    {
        "id": "okb",
        "symbol": "okb",
        "name": "OKB",
        "image": "https://assets.coingecko.com/coins/images/4463/large/WeChat_Image_20220118095654.png?1696505053",
        "current_price": 52.99,
        "market_cap": 3179020393,
        "market_cap_rank": 30,
        "fully_diluted_valuation": 12501904891,
        "total_volume": 10598861,
        "high_24h": 54.57,
        "low_24h": 52.7,
        "price_change_24h": -1.17751289437345,
        "price_change_percentage_24h": -2.17372,
        "market_cap_change_24h": -72108923.52400732,
        "market_cap_change_percentage_24h": -2.21797,
        "circulating_supply": 60000000,
        "total_supply": 235957685.3,
        "max_supply": 300000000,
        "ath": 64.92,
        "ath_change_percentage": -18.29453,
        "ath_date": "2023-11-14T16:15:10.918Z",
        "atl": 0.580608,
        "atl_change_percentage": 9036.06086,
        "atl_date": "2019-01-14T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:00.136Z"
    },
    {
        "id": "injective-protocol",
        "symbol": "inj",
        "name": "Injective",
        "image": "https://assets.coingecko.com/coins/images/12882/large/Secondary_Symbol.png?1696512670",
        "current_price": 35.99,
        "market_cap": 3046178224,
        "market_cap_rank": 31,
        "fully_diluted_valuation": 3595347590,
        "total_volume": 232132905,
        "high_24h": 39.6,
        "low_24h": 35.11,
        "price_change_24h": -2.660618761717032,
        "price_change_percentage_24h": -6.88346,
        "market_cap_change_24h": -219331417.67012835,
        "market_cap_change_percentage_24h": -6.71661,
        "circulating_supply": 84725555.66,
        "total_supply": 100000000,
        "max_supply": null,
        "ath": 45.01,
        "ath_change_percentage": -20.14686,
        "ath_date": "2024-01-09T06:31:10.010Z",
        "atl": 0.657401,
        "atl_change_percentage": 5367.60987,
        "atl_date": "2020-11-03T16:19:30.576Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:05.459Z"
    },
    {
        "id": "lido-dao",
        "symbol": "ldo",
        "name": "Lido DAO",
        "image": "https://assets.coingecko.com/coins/images/13573/large/Lido_DAO.png?1696513326",
        "current_price": 3.37,
        "market_cap": 3001396765,
        "market_cap_rank": 32,
        "fully_diluted_valuation": 3371666504,
        "total_volume": 192699352,
        "high_24h": 3.82,
        "low_24h": 3.36,
        "price_change_24h": -0.20125625878111286,
        "price_change_percentage_24h": -5.62904,
        "market_cap_change_24h": -176826170.5279355,
        "market_cap_change_percentage_24h": -5.56368,
        "circulating_supply": 890181980.134323,
        "total_supply": 1000000000,
        "max_supply": 1000000000,
        "ath": 7.3,
        "ath_change_percentage": -53.77998,
        "ath_date": "2021-08-20T08:35:20.158Z",
        "atl": 0.40615,
        "atl_change_percentage": 731.06991,
        "atl_date": "2022-06-18T20:55:12.035Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:00.948Z"
    },
    {
        "id": "aptos",
        "symbol": "apt",
        "name": "Aptos",
        "image": "https://assets.coingecko.com/coins/images/26455/large/aptos_round.png?1696525528",
        "current_price": 8.85,
        "market_cap": 2956313433,
        "market_cap_rank": 33,
        "fully_diluted_valuation": 9505518166,
        "total_volume": 260958040,
        "high_24h": 9.85,
        "low_24h": 8.76,
        "price_change_24h": -0.7620783276598662,
        "price_change_percentage_24h": -7.93201,
        "market_cap_change_24h": -13442468.028318882,
        "market_cap_change_percentage_24h": -0.45265,
        "circulating_supply": 334459067.031603,
        "total_supply": 1075395694.6175,
        "max_supply": null,
        "ath": 19.92,
        "ath_change_percentage": -55.67048,
        "ath_date": "2023-01-26T14:25:17.390Z",
        "atl": 3.08,
        "atl_change_percentage": 186.72736,
        "atl_date": "2022-12-29T21:35:14.796Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:55.520Z"
    },
    {
        "id": "filecoin",
        "symbol": "fil",
        "name": "Filecoin",
        "image": "https://assets.coingecko.com/coins/images/12817/large/filecoin.png?1696512609",
        "current_price": 5.96,
        "market_cap": 2954730501,
        "market_cap_rank": 34,
        "fully_diluted_valuation": 11669516112,
        "total_volume": 367419345,
        "high_24h": 6.34,
        "low_24h": 5.75,
        "price_change_24h": -0.2538241602178113,
        "price_change_percentage_24h": -4.084,
        "market_cap_change_24h": -131071876.80671406,
        "market_cap_change_percentage_24h": -4.24758,
        "circulating_supply": 496521339,
        "total_supply": 1960978764,
        "max_supply": 1960978764,
        "ath": 236.84,
        "ath_change_percentage": -97.4924,
        "ath_date": "2021-04-01T13:29:41.564Z",
        "atl": 2.64,
        "atl_change_percentage": 124.88022,
        "atl_date": "2022-12-16T22:45:28.552Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:13.571Z"
    },
    {
        "id": "immutable-x",
        "symbol": "imx",
        "name": "Immutable",
        "image": "https://assets.coingecko.com/coins/images/17233/large/immutableX-symbol-BLK-RGB.png?1696516787",
        "current_price": 2.11,
        "market_cap": 2790868334,
        "market_cap_rank": 35,
        "fully_diluted_valuation": 4222224541,
        "total_volume": 160480995,
        "high_24h": 2.3,
        "low_24h": 2.06,
        "price_change_24h": -0.1100146806647877,
        "price_change_percentage_24h": -4.94488,
        "market_cap_change_24h": -152857716.3392539,
        "market_cap_change_percentage_24h": -5.19266,
        "circulating_supply": 1321989537.490024,
        "total_supply": 2000000000,
        "max_supply": 2000000000,
        "ath": 9.52,
        "ath_change_percentage": -77.81212,
        "ath_date": "2021-11-26T01:03:01.536Z",
        "atl": 0.378055,
        "atl_change_percentage": 458.72114,
        "atl_date": "2022-12-31T07:36:37.649Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:10.193Z"
    },
    {
        "id": "arbitrum",
        "symbol": "arb",
        "name": "Arbitrum",
        "image": "https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg?1696516109",
        "current_price": 2.15,
        "market_cap": 2742266802,
        "market_cap_rank": 36,
        "fully_diluted_valuation": 21507974918,
        "total_volume": 1636726045,
        "high_24h": 2.39,
        "low_24h": 2.09,
        "price_change_24h": -0.04356391559529893,
        "price_change_percentage_24h": -1.98452,
        "market_cap_change_24h": -55867447.08528471,
        "market_cap_change_percentage_24h": -1.9966,
        "circulating_supply": 1275000000,
        "total_supply": 10000000000,
        "max_supply": 10000000000,
        "ath": 2.39,
        "ath_change_percentage": -10.04737,
        "ath_date": "2024-01-12T12:29:59.231Z",
        "atl": 0.744925,
        "atl_change_percentage": 188.63608,
        "atl_date": "2023-09-11T19:40:49.196Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:55.425Z"
    },
    {
        "id": "monero",
        "symbol": "xmr",
        "name": "Monero",
        "image": "https://assets.coingecko.com/coins/images/69/large/monero_logo.png?1696501460",
        "current_price": 151.11,
        "market_cap": 2741692906,
        "market_cap_rank": 37,
        "fully_diluted_valuation": null,
        "total_volume": 117182282,
        "high_24h": 161.58,
        "low_24h": 148.55,
        "price_change_24h": -5.307041023174264,
        "price_change_percentage_24h": -3.39285,
        "market_cap_change_24h": -96122846.77452707,
        "market_cap_change_percentage_24h": -3.38721,
        "circulating_supply": 18147820.3764146,
        "total_supply": null,
        "max_supply": null,
        "ath": 542.33,
        "ath_change_percentage": -72.15763,
        "ath_date": "2018-01-09T00:00:00.000Z",
        "atl": 0.216177,
        "atl_change_percentage": 69748.56622,
        "atl_date": "2015-01-14T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:43.731Z"
    },
    {
        "id": "hedera-hashgraph",
        "symbol": "hbar",
        "name": "Hedera",
        "image": "https://assets.coingecko.com/coins/images/3688/large/hbar.png?1696504364",
        "current_price": 0.079025,
        "market_cap": 2657930004,
        "market_cap_rank": 38,
        "fully_diluted_valuation": 3949492947,
        "total_volume": 99135861,
        "high_24h": 0.085228,
        "low_24h": 0.077668,
        "price_change_24h": -0.004469946031298703,
        "price_change_percentage_24h": -5.35357,
        "market_cap_change_24h": -150241238.7928543,
        "market_cap_change_percentage_24h": -5.35015,
        "circulating_supply": 33649003052.4632,
        "total_supply": 50000000000,
        "max_supply": 50000000000,
        "ath": 0.569229,
        "ath_change_percentage": -86.09503,
        "ath_date": "2021-09-15T10:40:28.318Z",
        "atl": 0.00986111,
        "atl_change_percentage": 702.65908,
        "atl_date": "2020-01-02T17:30:24.852Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:38.576Z"
    },
    {
        "id": "celestia",
        "symbol": "tia",
        "name": "Celestia",
        "image": "https://assets.coingecko.com/coins/images/31967/large/tia.jpg?1696530772",
        "current_price": 16.11,
        "market_cap": 2530697848,
        "market_cap_rank": 39,
        "fully_diluted_valuation": 16372460876,
        "total_volume": 377560048,
        "high_24h": 17.07,
        "low_24h": 15.45,
        "price_change_24h": 0.417645,
        "price_change_percentage_24h": 2.66218,
        "market_cap_change_24h": 69720750,
        "market_cap_change_percentage_24h": 2.83305,
        "circulating_supply": 157043527.749943,
        "total_supply": 1015999999.99994,
        "max_supply": null,
        "ath": 17.19,
        "ath_change_percentage": -6.22065,
        "ath_date": "2024-01-11T13:00:51.009Z",
        "atl": 2.08,
        "atl_change_percentage": 673.83643,
        "atl_date": "2023-10-31T15:14:31.951Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:51.822Z"
    },
    {
        "id": "kaspa",
        "symbol": "kas",
        "name": "Kaspa",
        "image": "https://assets.coingecko.com/coins/images/25751/large/kaspa-icon-exchanges.png?1696524837",
        "current_price": 0.112786,
        "market_cap": 2513298227,
        "market_cap_rank": 40,
        "fully_diluted_valuation": 2513209851,
        "total_volume": 31929034,
        "high_24h": 0.124096,
        "low_24h": 0.110909,
        "price_change_24h": -0.008271853147291982,
        "price_change_percentage_24h": -6.83297,
        "market_cap_change_24h": -179337243.49535275,
        "market_cap_change_percentage_24h": -6.66029,
        "circulating_supply": 22292230847.815,
        "total_supply": 22291446975.3346,
        "max_supply": 28704026601,
        "ath": 0.152699,
        "ath_change_percentage": -26.17448,
        "ath_date": "2023-11-19T21:34:58.238Z",
        "atl": 0.00017105,
        "atl_change_percentage": 65805.53015,
        "atl_date": "2022-05-26T14:42:59.316Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:10.297Z"
    },
    {
        "id": "blockstack",
        "symbol": "stx",
        "name": "Stacks",
        "image": "https://assets.coingecko.com/coins/images/2069/large/Stacks_logo_full.png?1696503035",
        "current_price": 1.7,
        "market_cap": 2439337419,
        "market_cap_rank": 41,
        "fully_diluted_valuation": 3097496381,
        "total_volume": 130623654,
        "high_24h": 1.76,
        "low_24h": 1.59,
        "price_change_24h": -0.03869637801628989,
        "price_change_percentage_24h": -2.22366,
        "market_cap_change_24h": -50829375.142878056,
        "market_cap_change_percentage_24h": -2.0412,
        "circulating_supply": 1431709639.65464,
        "total_supply": 1818000000,
        "max_supply": 1818000000,
        "ath": 3.39,
        "ath_change_percentage": -49.29899,
        "ath_date": "2021-12-01T01:32:34.725Z",
        "atl": 0.04559639,
        "atl_change_percentage": 3669.11378,
        "atl_date": "2020-03-13T02:29:26.415Z",
        "roi": {
            "times": 13.179283606988875,
            "currency": "usd",
            "percentage": 1317.9283606988874
        },
        "last_updated": "2024-01-13T05:13:37.470Z"
    },
    {
        "id": "crypto-com-chain",
        "symbol": "cro",
        "name": "Cronos",
        "image": "https://assets.coingecko.com/coins/images/7310/large/cro_token_logo.png?1696507599",
        "current_price": 0.088421,
        "market_cap": 2340746162,
        "market_cap_rank": 42,
        "fully_diluted_valuation": 2652395859,
        "total_volume": 13432314,
        "high_24h": 0.093873,
        "low_24h": 0.086714,
        "price_change_24h": -0.004270696144344846,
        "price_change_percentage_24h": -4.60743,
        "market_cap_change_24h": -113101465.78660107,
        "market_cap_change_percentage_24h": -4.60915,
        "circulating_supply": 26475077097.9124,
        "total_supply": 30000000000,
        "max_supply": null,
        "ath": 0.965407,
        "ath_change_percentage": -90.83211,
        "ath_date": "2021-11-24T15:53:54.855Z",
        "atl": 0.0121196,
        "atl_change_percentage": 630.28373,
        "atl_date": "2019-02-08T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:40.238Z"
    },
    {
        "id": "mantle",
        "symbol": "mnt",
        "name": "Mantle",
        "image": "https://assets.coingecko.com/coins/images/30980/large/token-logo.png?1696529819",
        "current_price": 0.744061,
        "market_cap": 2330305883,
        "market_cap_rank": 43,
        "fully_diluted_valuation": 4626713329,
        "total_volume": 392290910,
        "high_24h": 0.825583,
        "low_24h": 0.731399,
        "price_change_24h": -0.04115032786818007,
        "price_change_percentage_24h": -5.24067,
        "market_cap_change_24h": -129293729.92348385,
        "market_cap_change_percentage_24h": -5.2567,
        "circulating_supply": 3132441863.03314,
        "total_supply": 6219316794.99,
        "max_supply": 6219316794.99,
        "ath": 0.849137,
        "ath_change_percentage": -12.37781,
        "ath_date": "2024-01-11T13:25:10.126Z",
        "atl": 0.307978,
        "atl_change_percentage": 141.58671,
        "atl_date": "2023-10-18T02:50:46.942Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:59.073Z"
    },
    {
        "id": "vechain",
        "symbol": "vet",
        "name": "VeChain",
        "image": "https://assets.coingecko.com/coins/images/1167/large/VET_Token_Icon.png?1696502256",
        "current_price": 0.03097953,
        "market_cap": 2251343806,
        "market_cap_rank": 44,
        "fully_diluted_valuation": 2662217922,
        "total_volume": 66682748,
        "high_24h": 0.03344927,
        "low_24h": 0.03008051,
        "price_change_24h": -0.002165938184984364,
        "price_change_percentage_24h": -6.53464,
        "market_cap_change_24h": -155777848.3873167,
        "market_cap_change_percentage_24h": -6.47154,
        "circulating_supply": 72714516834,
        "total_supply": 85985041177,
        "max_supply": 86712634466,
        "ath": 0.280991,
        "ath_change_percentage": -88.97417,
        "ath_date": "2021-04-19T01:08:21.675Z",
        "atl": 0.00191713,
        "atl_change_percentage": 1516.03783,
        "atl_date": "2020-03-13T02:29:59.652Z",
        "roi": {
            "times": 3.2594058387275364,
            "currency": "eth",
            "percentage": 325.9405838727537
        },
        "last_updated": "2024-01-13T05:14:10.819Z"
    },
    {
        "id": "true-usd",
        "symbol": "tusd",
        "name": "TrueUSD",
        "image": "https://assets.coingecko.com/coins/images/3449/large/tusd.png?1696504140",
        "current_price": 0.994832,
        "market_cap": 2039795351,
        "market_cap_rank": 45,
        "fully_diluted_valuation": 2039795351,
        "total_volume": 275079496,
        "high_24h": 1.005,
        "low_24h": 0.974797,
        "price_change_24h": -0.002109083069787077,
        "price_change_percentage_24h": -0.21156,
        "market_cap_change_24h": -8277903.481444359,
        "market_cap_change_percentage_24h": -0.40418,
        "circulating_supply": 2050388395.54,
        "total_supply": 2050388395.54,
        "max_supply": null,
        "ath": 1.62,
        "ath_change_percentage": -38.45649,
        "ath_date": "2018-08-26T20:41:09.375Z",
        "atl": 0.88355,
        "atl_change_percentage": 12.73722,
        "atl_date": "2020-03-12T10:47:51.380Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:59.919Z"
    },
    {
        "id": "first-digital-usd",
        "symbol": "fdusd",
        "name": "First Digital USD",
        "image": "https://assets.coingecko.com/coins/images/31079/large/firstfigital.jpeg?1696529912",
        "current_price": 0.998132,
        "market_cap": 2015050401,
        "market_cap_rank": 46,
        "fully_diluted_valuation": 2015050401,
        "total_volume": 5441128894,
        "high_24h": 1.011,
        "low_24h": 0.964919,
        "price_change_24h": -0.000606555787855512,
        "price_change_percentage_24h": -0.06073,
        "market_cap_change_24h": -6533389.003646851,
        "market_cap_change_percentage_24h": -0.32318,
        "circulating_supply": 2018986628.41,
        "total_supply": 2018986628.41,
        "max_supply": null,
        "ath": 1.041,
        "ath_change_percentage": -4.127,
        "ath_date": "2023-10-23T22:55:15.103Z",
        "atl": 0.942129,
        "atl_change_percentage": 5.9694,
        "atl_date": "2023-08-17T21:55:41.478Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:57.514Z"
    },
    {
        "id": "maker",
        "symbol": "mkr",
        "name": "Maker",
        "image": "https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1696502423",
        "current_price": 2079.09,
        "market_cap": 1912587689,
        "market_cap_rank": 47,
        "fully_diluted_valuation": 2032148697,
        "total_volume": 150599382,
        "high_24h": 2184.39,
        "low_24h": 1978.87,
        "price_change_24h": -29.09666874953473,
        "price_change_percentage_24h": -1.38018,
        "market_cap_change_24h": -26138450.947508097,
        "market_cap_change_percentage_24h": -1.34823,
        "circulating_supply": 920112.336426501,
        "total_supply": 977631.036950888,
        "max_supply": 1005577,
        "ath": 6292.31,
        "ath_change_percentage": -66.97434,
        "ath_date": "2021-05-03T21:54:29.333Z",
        "atl": 168.36,
        "atl_change_percentage": 1134.32432,
        "atl_date": "2020-03-16T20:52:36.527Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:59.256Z"
    },
    {
        "id": "quant-network",
        "symbol": "qnt",
        "name": "Quant",
        "image": "https://assets.coingecko.com/coins/images/3370/large/5ZOu7brX_400x400.jpg?1696504070",
        "current_price": 117.09,
        "market_cap": 1701795819,
        "market_cap_rank": 48,
        "fully_diluted_valuation": 1709789487,
        "total_volume": 45989052,
        "high_24h": 130.56,
        "low_24h": 112.94,
        "price_change_24h": -8.796202847634348,
        "price_change_percentage_24h": -6.98733,
        "market_cap_change_24h": -126925624.58021045,
        "market_cap_change_percentage_24h": -6.94068,
        "circulating_supply": 14544176.164091088,
        "total_supply": 14612493,
        "max_supply": 14612493,
        "ath": 427.42,
        "ath_change_percentage": -72.5579,
        "ath_date": "2021-09-11T09:15:00.668Z",
        "atl": 0.215773,
        "atl_change_percentage": 54259.67808,
        "atl_date": "2018-08-23T00:00:00.000Z",
        "roi": {
            "times": 18.778829152801787,
            "currency": "eth",
            "percentage": 1877.8829152801788
        },
        "last_updated": "2024-01-13T05:14:05.837Z"
    },
    {
        "id": "bitcoin-cash-sv",
        "symbol": "bsv",
        "name": "Bitcoin SV",
        "image": "https://assets.coingecko.com/coins/images/6799/large/BSV.png?1696507128",
        "current_price": 85.04,
        "market_cap": 1664452651,
        "market_cap_rank": 49,
        "fully_diluted_valuation": 1782848659,
        "total_volume": 103245159,
        "high_24h": 99.63,
        "low_24h": 83.05,
        "price_change_24h": -13.573316643707543,
        "price_change_percentage_24h": -13.76474,
        "market_cap_change_24h": -264777245.51615787,
        "market_cap_change_percentage_24h": -13.7245,
        "circulating_supply": 19605425,
        "total_supply": 21000000,
        "max_supply": 21000000,
        "ath": 489.75,
        "ath_change_percentage": -82.66659,
        "ath_date": "2021-04-16T17:09:04.630Z",
        "atl": 21.43,
        "atl_change_percentage": 296.04003,
        "atl_date": "2023-06-10T04:32:12.266Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:49.524Z"
    },
    {
        "id": "the-graph",
        "symbol": "grt",
        "name": "The Graph",
        "image": "https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png?1696513159",
        "current_price": 0.171921,
        "market_cap": 1609910619,
        "market_cap_rank": 50,
        "fully_diluted_valuation": 1854683642,
        "total_volume": 111585581,
        "high_24h": 0.195239,
        "low_24h": 0.169534,
        "price_change_24h": -0.016774890546626037,
        "price_change_percentage_24h": -8.88992,
        "market_cap_change_24h": -155750488.46381783,
        "market_cap_change_percentage_24h": -8.82109,
        "circulating_supply": 9364250762.36863,
        "total_supply": 10788004319,
        "max_supply": 10788004319,
        "ath": 2.84,
        "ath_change_percentage": -93.94263,
        "ath_date": "2021-02-12T07:28:45.775Z",
        "atl": 0.052051,
        "atl_change_percentage": 230.67642,
        "atl_date": "2022-11-22T10:05:03.503Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:55.734Z"
    },
    {
        "id": "rocket-pool-eth",
        "symbol": "reth",
        "name": "Rocket Pool ETH",
        "image": "https://assets.coingecko.com/coins/images/20764/large/reth.png?1696520159",
        "current_price": 2789.15,
        "market_cap": 1575497807,
        "market_cap_rank": 51,
        "fully_diluted_valuation": 1575497807,
        "total_volume": 13161995,
        "high_24h": 2968,
        "low_24h": 2704.85,
        "price_change_24h": -58.73356188533626,
        "price_change_percentage_24h": -2.06236,
        "market_cap_change_24h": -25426573.56341386,
        "market_cap_change_percentage_24h": -1.58824,
        "circulating_supply": 564859.149591355,
        "total_supply": 564859.149591355,
        "max_supply": null,
        "ath": 4814.31,
        "ath_change_percentage": -42.06868,
        "ath_date": "2021-12-01T08:03:50.749Z",
        "atl": 887.26,
        "atl_change_percentage": 214.33655,
        "atl_date": "2022-06-18T20:55:45.957Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:54.957Z"
    },
    {
        "id": "sei-network",
        "symbol": "sei",
        "name": "Sei",
        "image": "https://assets.coingecko.com/coins/images/28205/large/Sei_Logo_-_Transparent.png?1696527207",
        "current_price": 0.676054,
        "market_cap": 1550923517,
        "market_cap_rank": 52,
        "fully_diluted_valuation": 6743145728,
        "total_volume": 277821981,
        "high_24h": 0.736749,
        "low_24h": 0.654082,
        "price_change_24h": -0.046854684871456165,
        "price_change_percentage_24h": -6.48141,
        "market_cap_change_24h": -110485770.61425471,
        "market_cap_change_percentage_24h": -6.65012,
        "circulating_supply": 2300000000,
        "total_supply": 10000000000,
        "max_supply": null,
        "ath": 0.877895,
        "ath_change_percentage": -23.19685,
        "ath_date": "2024-01-03T21:20:26.826Z",
        "atl": 0.095364,
        "atl_change_percentage": 607.03021,
        "atl_date": "2023-10-19T08:05:30.655Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:58.616Z"
    },
    {
        "id": "aave",
        "symbol": "aave",
        "name": "Aave",
        "image": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1696512452",
        "current_price": 103.8,
        "market_cap": 1526567456,
        "market_cap_rank": 53,
        "fully_diluted_valuation": 1660931012,
        "total_volume": 212310306,
        "high_24h": 113.33,
        "low_24h": 100.64,
        "price_change_24h": -4.679290928887156,
        "price_change_percentage_24h": -4.31363,
        "market_cap_change_24h": -68580228.2233727,
        "market_cap_change_percentage_24h": -4.2993,
        "circulating_supply": 14705655.516425714,
        "total_supply": 16000000,
        "max_supply": 16000000,
        "ath": 661.69,
        "ath_change_percentage": -84.31884,
        "ath_date": "2021-05-18T21:19:59.514Z",
        "atl": 26.02,
        "atl_change_percentage": 298.72455,
        "atl_date": "2020-11-05T09:20:11.928Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:09.956Z"
    },
    {
        "id": "algorand",
        "symbol": "algo",
        "name": "Algorand",
        "image": "https://assets.coingecko.com/coins/images/4380/large/download.png?1696504978",
        "current_price": 0.185389,
        "market_cap": 1490942009,
        "market_cap_rank": 54,
        "fully_diluted_valuation": 1490942194,
        "total_volume": 69731323,
        "high_24h": 0.204235,
        "low_24h": 0.1817,
        "price_change_24h": -0.015946656268922388,
        "price_change_percentage_24h": -7.92043,
        "market_cap_change_24h": -128103336.18973613,
        "market_cap_change_percentage_24h": -7.91228,
        "circulating_supply": 8043033046.30115,
        "total_supply": 8043034043.37163,
        "max_supply": 10000000000,
        "ath": 3.56,
        "ath_change_percentage": -94.79667,
        "ath_date": "2019-06-20T14:51:19.480Z",
        "atl": 0.087513,
        "atl_change_percentage": 111.7373,
        "atl_date": "2023-09-11T19:42:08.247Z",
        "roi": {
            "times": -0.9227545582752237,
            "currency": "usd",
            "percentage": -92.27545582752236
        },
        "last_updated": "2024-01-13T05:14:13.322Z"
    },
    {
        "id": "ordinals",
        "symbol": "ordi",
        "name": "ORDI",
        "image": "https://assets.coingecko.com/coins/images/30162/large/ordi.png?1696529082",
        "current_price": 69.12,
        "market_cap": 1451315012,
        "market_cap_rank": 55,
        "fully_diluted_valuation": 1451315012,
        "total_volume": 351371052,
        "high_24h": 74.41,
        "low_24h": 65.78,
        "price_change_24h": -4.418532689787398,
        "price_change_percentage_24h": -6.00816,
        "market_cap_change_24h": -91537696.47446275,
        "market_cap_change_percentage_24h": -5.93302,
        "circulating_supply": 21000000,
        "total_supply": 21000000,
        "max_supply": null,
        "ath": 89.94,
        "ath_change_percentage": -23.13965,
        "ath_date": "2024-01-02T11:30:17.565Z",
        "atl": 2.86,
        "atl_change_percentage": 2316.24365,
        "atl_date": "2023-09-11T15:22:32.140Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:53.831Z"
    },
    {
        "id": "render-token",
        "symbol": "rndr",
        "name": "Render",
        "image": "https://assets.coingecko.com/coins/images/11636/large/rndr.png?1696511529",
        "current_price": 3.8,
        "market_cap": 1430039428,
        "market_cap_rank": 56,
        "fully_diluted_valuation": 2018916497,
        "total_volume": 108063805,
        "high_24h": 4.06,
        "low_24h": 3.7,
        "price_change_24h": -0.1441342972120645,
        "price_change_percentage_24h": -3.6523,
        "market_cap_change_24h": -52603780.61793256,
        "market_cap_change_percentage_24h": -3.54797,
        "circulating_supply": 376146335.687948,
        "total_supply": 531039932.967948,
        "max_supply": 531039932.967948,
        "ath": 8.78,
        "ath_change_percentage": -56.7333,
        "ath_date": "2021-11-21T10:03:19.097Z",
        "atl": 0.03665669,
        "atl_change_percentage": 10266.77846,
        "atl_date": "2020-06-16T13:22:25.900Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:44.779Z"
    },
    {
        "id": "elrond-erd-2",
        "symbol": "egld",
        "name": "MultiversX",
        "image": "https://assets.coingecko.com/coins/images/12335/large/egld-token-logo.png?1696512162",
        "current_price": 53.49,
        "market_cap": 1413133349,
        "market_cap_rank": 57,
        "fully_diluted_valuation": 1413408669,
        "total_volume": 47374909,
        "high_24h": 58.24,
        "low_24h": 52.4,
        "price_change_24h": -3.7161560251634214,
        "price_change_percentage_24h": -6.49655,
        "market_cap_change_24h": -99061665.83189702,
        "market_cap_change_percentage_24h": -6.55085,
        "circulating_supply": 26443605,
        "total_supply": 26448757,
        "max_supply": 31415926,
        "ath": 545.64,
        "ath_change_percentage": -90.20789,
        "ath_date": "2021-11-23T10:33:26.737Z",
        "atl": 6.51,
        "atl_change_percentage": 721.15234,
        "atl_date": "2020-10-07T01:44:53.554Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:01.076Z"
    },
    {
        "id": "thorchain",
        "symbol": "rune",
        "name": "THORChain",
        "image": "https://assets.coingecko.com/coins/images/6595/large/Rune200x200.png?1696506946",
        "current_price": 4.51,
        "market_cap": 1356646589,
        "market_cap_rank": 58,
        "fully_diluted_valuation": 2255809873,
        "total_volume": 448829775,
        "high_24h": 5.11,
        "low_24h": 4.46,
        "price_change_24h": -0.5534882363198399,
        "price_change_percentage_24h": -10.92747,
        "market_cap_change_24h": -165473176.74768734,
        "market_cap_change_percentage_24h": -10.87123,
        "circulating_supply": 300700561,
        "total_supply": 500000000,
        "max_supply": 500000000,
        "ath": 20.87,
        "ath_change_percentage": -78.38045,
        "ath_date": "2021-05-19T00:30:09.436Z",
        "atl": 0.00851264,
        "atl_change_percentage": 52897.35517,
        "atl_date": "2019-09-28T00:00:00.000Z",
        "roi": {
            "times": 117.72683543515639,
            "currency": "usd",
            "percentage": 11772.683543515639
        },
        "last_updated": "2024-01-13T05:13:42.271Z"
    },
    {
        "id": "bittensor",
        "symbol": "tao",
        "name": "Bittensor",
        "image": "https://assets.coingecko.com/coins/images/28452/large/ARUsPeNQ_400x400.jpeg?1696527447",
        "current_price": 224.61,
        "market_cap": 1345511239,
        "market_cap_rank": 59,
        "fully_diluted_valuation": 1345511239,
        "total_volume": 6431844,
        "high_24h": 233.35,
        "low_24h": 208.45,
        "price_change_24h": -6.57518068558943,
        "price_change_percentage_24h": -2.84417,
        "market_cap_change_24h": -49203552.40759063,
        "market_cap_change_percentage_24h": -3.52786,
        "circulating_supply": 6032467,
        "total_supply": 6032467,
        "max_supply": 21000000,
        "ath": 394,
        "ath_change_percentage": -43.39539,
        "ath_date": "2023-12-14T20:03:59.063Z",
        "atl": 30.83,
        "atl_change_percentage": 623.40026,
        "atl_date": "2023-05-14T08:57:53.732Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:09.315Z"
    },
    {
        "id": "sui",
        "symbol": "sui",
        "name": "Sui",
        "image": "https://assets.coingecko.com/coins/images/26375/large/sui_asset.jpeg?1696525453",
        "current_price": 1.22,
        "market_cap": 1339628932,
        "market_cap_rank": 60,
        "fully_diluted_valuation": 12167966946,
        "total_volume": 758006481,
        "high_24h": 1.22,
        "low_24h": 1.077,
        "price_change_24h": 0.128765,
        "price_change_percentage_24h": 11.83378,
        "market_cap_change_24h": 144346258,
        "market_cap_change_percentage_24h": 12.07633,
        "circulating_supply": 1100947214.55346,
        "total_supply": 10000000000,
        "max_supply": 10000000000,
        "ath": 2.16,
        "ath_change_percentage": -43.72681,
        "ath_date": "2023-05-03T12:00:26.430Z",
        "atl": 0.364846,
        "atl_change_percentage": 233.54233,
        "atl_date": "2023-10-19T10:40:30.078Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:00.457Z"
    },
    {
        "id": "sats-ordinals",
        "symbol": "sats",
        "name": "SATS (Ordinals)",
        "image": "https://assets.coingecko.com/coins/images/30666/large/_dD8qr3M_400x400.png?1702913020",
        "current_price": 5.91536e-7,
        "market_cap": 1242284810,
        "market_cap_rank": 61,
        "fully_diluted_valuation": 1242284810,
        "total_volume": 48184555,
        "high_24h": 6.56711e-7,
        "low_24h": 5.73441e-7,
        "price_change_24h": -4.6833077233e-8,
        "price_change_percentage_24h": -7.33637,
        "market_cap_change_24h": -95412869.85998368,
        "market_cap_change_percentage_24h": -7.13262,
        "circulating_supply": 2100000000000000,
        "total_supply": 2100000000000000,
        "max_supply": 2100000000000000,
        "ath": 9.22983e-7,
        "ath_change_percentage": -35.84089,
        "ath_date": "2023-12-15T11:45:07.985Z",
        "atl": 3.189e-9,
        "atl_change_percentage": 18468.77306,
        "atl_date": "2023-07-08T13:03:18.882Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:33.075Z"
    },
    {
        "id": "mina-protocol",
        "symbol": "mina",
        "name": "Mina Protocol",
        "image": "https://assets.coingecko.com/coins/images/15628/large/JM4_vQ34_400x400.png?1696515261",
        "current_price": 1.19,
        "market_cap": 1228497400,
        "market_cap_rank": 62,
        "fully_diluted_valuation": 1324271402,
        "total_volume": 65800308,
        "high_24h": 1.32,
        "low_24h": 1.16,
        "price_change_24h": -0.08088667143008132,
        "price_change_percentage_24h": -6.36902,
        "market_cap_change_24h": -82211516.01881027,
        "market_cap_change_percentage_24h": -6.27229,
        "circulating_supply": 1033282313.84004,
        "total_supply": 1113837292.84004,
        "max_supply": null,
        "ath": 9.09,
        "ath_change_percentage": -86.94252,
        "ath_date": "2021-06-01T01:42:37.064Z",
        "atl": 0.351341,
        "atl_change_percentage": 237.83956,
        "atl_date": "2023-10-11T04:00:22.740Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:42.564Z"
    },
    {
        "id": "flow",
        "symbol": "flow",
        "name": "Flow",
        "image": "https://assets.coingecko.com/coins/images/13446/large/5f6294c0c7a8cda55cb1c936_Flow_Wordmark.png?1696513210",
        "current_price": 0.823991,
        "market_cap": 1221334758,
        "market_cap_rank": 64,
        "fully_diluted_valuation": 1221334758,
        "total_volume": 101660968,
        "high_24h": 0.929837,
        "low_24h": 0.800214,
        "price_change_24h": -0.08035037322163352,
        "price_change_percentage_24h": -8.88496,
        "market_cap_change_24h": -120967814.24767852,
        "market_cap_change_percentage_24h": -9.01196,
        "circulating_supply": 1482285260,
        "total_supply": 1482285260.2224,
        "max_supply": 1482285260.2224,
        "ath": 42.4,
        "ath_change_percentage": -98.0589,
        "ath_date": "2021-04-05T13:49:10.098Z",
        "atl": 0.391969,
        "atl_change_percentage": 109.95878,
        "atl_date": "2023-09-11T19:41:06.528Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:42.535Z"
    },
    {
        "id": "havven",
        "symbol": "snx",
        "name": "Synthetix Network",
        "image": "https://assets.coingecko.com/coins/images/3406/large/SNX.png?1696504103",
        "current_price": 3.72,
        "market_cap": 1218461062,
        "market_cap_rank": 63,
        "fully_diluted_valuation": 1220036913,
        "total_volume": 70212543,
        "high_24h": 4.03,
        "low_24h": 3.63,
        "price_change_24h": -0.12885198510247564,
        "price_change_percentage_24h": -3.34854,
        "market_cap_change_24h": -43392601.505908966,
        "market_cap_change_percentage_24h": -3.4388,
        "circulating_supply": 327769196.13952357,
        "total_supply": 328193104.088774,
        "max_supply": 328193104.088774,
        "ath": 28.53,
        "ath_change_percentage": -86.92378,
        "ath_date": "2021-02-14T01:12:38.505Z",
        "atl": 0.0347864,
        "atl_change_percentage": 10625.29461,
        "atl_date": "2019-01-06T00:00:00.000Z",
        "roi": {
            "times": 6.438301137367722,
            "currency": "usd",
            "percentage": 643.8301137367722
        },
        "last_updated": "2024-01-13T05:13:51.664Z"
    },
    {
        "id": "osmosis",
        "symbol": "osmo",
        "name": "Osmosis",
        "image": "https://assets.coingecko.com/coins/images/16724/large/osmo.png?1696516298",
        "current_price": 1.74,
        "market_cap": 1100707202,
        "market_cap_rank": 65,
        "fully_diluted_valuation": 1737776832,
        "total_volume": 47691598,
        "high_24h": 1.9,
        "low_24h": 1.7,
        "price_change_24h": -0.151230239151809,
        "price_change_percentage_24h": -8.00005,
        "market_cap_change_24h": -97365700.223284,
        "market_cap_change_percentage_24h": -8.12686,
        "circulating_supply": 633386517,
        "total_supply": 999979298,
        "max_supply": 1000000000,
        "ath": 11.25,
        "ath_change_percentage": -84.55234,
        "ath_date": "2022-03-04T05:12:18.707Z",
        "atl": 0.224523,
        "atl_change_percentage": 673.96032,
        "atl_date": "2023-10-19T17:45:36.695Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:04.137Z"
    },
    {
        "id": "fantom",
        "symbol": "ftm",
        "name": "Fantom",
        "image": "https://assets.coingecko.com/coins/images/4001/large/Fantom_round.png?1696504642",
        "current_price": 0.388561,
        "market_cap": 1087102688,
        "market_cap_rank": 66,
        "fully_diluted_valuation": 1231098640,
        "total_volume": 107219015,
        "high_24h": 0.428721,
        "low_24h": 0.378787,
        "price_change_24h": -0.03326704454825735,
        "price_change_percentage_24h": -7.88639,
        "market_cap_change_24h": -94102613.28488755,
        "market_cap_change_percentage_24h": -7.96666,
        "circulating_supply": 2803634835.52659,
        "total_supply": 3175000000,
        "max_supply": 3175000000,
        "ath": 3.46,
        "ath_change_percentage": -88.77947,
        "ath_date": "2021-10-28T05:19:39.845Z",
        "atl": 0.00190227,
        "atl_change_percentage": 20300.38202,
        "atl_date": "2020-03-13T02:25:38.280Z",
        "roi": {
            "times": 11.95204599123593,
            "currency": "usd",
            "percentage": 1195.204599123593
        },
        "last_updated": "2024-01-13T05:14:04.695Z"
    },
    {
        "id": "tokenize-xchange",
        "symbol": "tkx",
        "name": "Tokenize Xchange",
        "image": "https://assets.coingecko.com/coins/images/4984/large/TKX_-_Logo_-_RGB-15.png?1696505519",
        "current_price": 13.51,
        "market_cap": 1080057352,
        "market_cap_rank": 67,
        "fully_diluted_valuation": 1350139198,
        "total_volume": 30489321,
        "high_24h": 14.04,
        "low_24h": 13.33,
        "price_change_24h": -0.09625999854001499,
        "price_change_percentage_24h": -0.70755,
        "market_cap_change_24h": -7888295.92329669,
        "market_cap_change_percentage_24h": -0.72506,
        "circulating_supply": 79995999.94518414,
        "total_supply": 100000000,
        "max_supply": 100000000,
        "ath": 22.3,
        "ath_change_percentage": -39.49439,
        "ath_date": "2022-10-31T10:23:59.455Z",
        "atl": 0.111255,
        "atl_change_percentage": 12025.75551,
        "atl_date": "2019-04-28T00:00:00.000Z",
        "roi": {
            "times": 45.500920853388365,
            "currency": "usd",
            "percentage": 4550.092085338836
        },
        "last_updated": "2024-01-13T05:14:03.733Z"
    },
    {
        "id": "axie-infinity",
        "symbol": "axs",
        "name": "Axie Infinity",
        "image": "https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1696512817",
        "current_price": 7.94,
        "market_cap": 1073736655,
        "market_cap_rank": 68,
        "fully_diluted_valuation": 2140138866,
        "total_volume": 82262723,
        "high_24h": 8.72,
        "low_24h": 7.72,
        "price_change_24h": -0.7091771511592615,
        "price_change_percentage_24h": -8.19973,
        "market_cap_change_24h": -95745504.5869416,
        "market_cap_change_percentage_24h": -8.187,
        "circulating_supply": 135462656.84779,
        "total_supply": 270000000,
        "max_supply": 270000000,
        "ath": 164.9,
        "ath_change_percentage": -95.1953,
        "ath_date": "2021-11-06T19:29:29.482Z",
        "atl": 0.123718,
        "atl_change_percentage": 6303.94409,
        "atl_date": "2020-11-06T08:05:43.662Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:00.466Z"
    },
    {
        "id": "the-sandbox",
        "symbol": "sand",
        "name": "The Sandbox",
        "image": "https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg?1696511971",
        "current_price": 0.501528,
        "market_cap": 1062042556,
        "market_cap_rank": 69,
        "fully_diluted_valuation": 1503789898,
        "total_volume": 131479562,
        "high_24h": 0.552395,
        "low_24h": 0.490497,
        "price_change_24h": -0.03704445380761967,
        "price_change_percentage_24h": -6.87826,
        "market_cap_change_24h": -78707417.57331371,
        "market_cap_change_percentage_24h": -6.89962,
        "circulating_supply": 2118731926.2233226,
        "total_supply": 3000000000,
        "max_supply": 3000000000,
        "ath": 8.4,
        "ath_change_percentage": -94.03462,
        "ath_date": "2021-11-25T06:04:40.957Z",
        "atl": 0.02897764,
        "atl_change_percentage": 1628.5769,
        "atl_date": "2020-11-04T15:59:14.441Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:09.369Z"
    },
    {
        "id": "helium",
        "symbol": "hnt",
        "name": "Helium",
        "image": "https://assets.coingecko.com/coins/images/4284/large/Helium_HNT.png?1696504894",
        "current_price": 7.31,
        "market_cap": 1052477919,
        "market_cap_rank": 70,
        "fully_diluted_valuation": 1630617071,
        "total_volume": 40911315,
        "high_24h": 8.37,
        "low_24h": 7,
        "price_change_24h": 0.275479,
        "price_change_percentage_24h": 3.91472,
        "market_cap_change_24h": 39927433,
        "market_cap_change_percentage_24h": 3.94325,
        "circulating_supply": 143934820.830308,
        "total_supply": 223000000,
        "max_supply": 223000000,
        "ath": 54.88,
        "ath_change_percentage": -86.59102,
        "ath_date": "2021-11-12T23:08:25.301Z",
        "atl": 0.113248,
        "atl_change_percentage": 6397.66224,
        "atl_date": "2020-04-18T00:19:10.902Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:10.579Z"
    },
    {
        "id": "theta-token",
        "symbol": "theta",
        "name": "Theta Network",
        "image": "https://assets.coingecko.com/coins/images/2538/large/theta-token-logo.png?1696503349",
        "current_price": 1.043,
        "market_cap": 1043466266,
        "market_cap_rank": 71,
        "fully_diluted_valuation": 1043466266,
        "total_volume": 31915921,
        "high_24h": 1.16,
        "low_24h": 1.013,
        "price_change_24h": -0.10011544762902491,
        "price_change_percentage_24h": -8.75455,
        "market_cap_change_24h": -101212935.38427508,
        "market_cap_change_percentage_24h": -8.84203,
        "circulating_supply": 1000000000,
        "total_supply": 1000000000,
        "max_supply": 1000000000,
        "ath": 15.72,
        "ath_change_percentage": -93.3633,
        "ath_date": "2021-04-16T13:15:11.190Z",
        "atl": 0.04039979,
        "atl_change_percentage": 2482.36785,
        "atl_date": "2020-03-13T02:24:16.483Z",
        "roi": {
            "times": 5.95644177297502,
            "currency": "usd",
            "percentage": 595.644177297502
        },
        "last_updated": "2024-01-13T05:13:39.226Z"
    },
    {
        "id": "bittorrent",
        "symbol": "btt",
        "name": "BitTorrent",
        "image": "https://assets.coingecko.com/coins/images/22457/large/btt_logo.png?1696521780",
        "current_price": 0.00000106,
        "market_cap": 1030535505,
        "market_cap_rank": 72,
        "fully_diluted_valuation": 1053688524,
        "total_volume": 35361005,
        "high_24h": 0.00000111,
        "low_24h": 0.00000105,
        "price_change_24h": -3.8383963475e-8,
        "price_change_percentage_24h": -3.47888,
        "market_cap_change_24h": -38430150.95579028,
        "market_cap_change_percentage_24h": -3.59508,
        "circulating_supply": 968246428571000,
        "total_supply": 990000000000000,
        "max_supply": 990000000000000,
        "ath": 0.00000343,
        "ath_change_percentage": -68.98521,
        "ath_date": "2022-01-21T04:00:31.909Z",
        "atl": 3.65368e-7,
        "atl_change_percentage": 191.27439,
        "atl_date": "2023-10-13T05:10:41.241Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:10.262Z"
    },
    {
        "id": "kucoin-shares",
        "symbol": "kcs",
        "name": "KuCoin",
        "image": "https://assets.coingecko.com/coins/images/1047/large/sa9z79.png?1696502152",
        "current_price": 9.95,
        "market_cap": 961246034,
        "market_cap_rank": 73,
        "fully_diluted_valuation": 1433707677,
        "total_volume": 425313,
        "high_24h": 10.22,
        "low_24h": 9.73,
        "price_change_24h": -0.15097605310210938,
        "price_change_percentage_24h": -1.49492,
        "market_cap_change_24h": -14356426.321127415,
        "market_cap_change_percentage_24h": -1.47154,
        "circulating_supply": 96641044.2890287,
        "total_supply": 144141044.288945,
        "max_supply": null,
        "ath": 28.83,
        "ath_change_percentage": -65.61672,
        "ath_date": "2021-12-01T15:09:35.541Z",
        "atl": 0.342863,
        "atl_change_percentage": 2791.14315,
        "atl_date": "2019-02-07T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:54.150Z"
    },
    {
        "id": "tezos",
        "symbol": "xtz",
        "name": "Tezos",
        "image": "https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1696502091",
        "current_price": 1.016,
        "market_cap": 912407571,
        "market_cap_rank": 74,
        "fully_diluted_valuation": 1003264956,
        "total_volume": 58855089,
        "high_24h": 1.1,
        "low_24h": 1.002,
        "price_change_24h": -0.08032041908098075,
        "price_change_percentage_24h": -7.32627,
        "market_cap_change_24h": -71436838.93120563,
        "market_cap_change_percentage_24h": -7.26099,
        "circulating_supply": 898501128.555601,
        "total_supply": 987973712.443357,
        "max_supply": null,
        "ath": 9.12,
        "ath_change_percentage": -88.84,
        "ath_date": "2021-10-04T00:41:18.025Z",
        "atl": 0.350476,
        "atl_change_percentage": 190.27751,
        "atl_date": "2018-12-07T00:00:00.000Z",
        "roi": {
            "times": 1.161730401372868,
            "currency": "usd",
            "percentage": 116.1730401372868
        },
        "last_updated": "2024-01-13T05:14:10.428Z"
    },
    {
        "id": "wemix-token",
        "symbol": "wemix",
        "name": "WEMIX",
        "image": "https://assets.coingecko.com/coins/images/12998/large/wemixcoin_color_200.png?1696512788",
        "current_price": 2.58,
        "market_cap": 908623278,
        "market_cap_rank": 75,
        "fully_diluted_valuation": 2451007645,
        "total_volume": 5433404,
        "high_24h": 2.77,
        "low_24h": 2.54,
        "price_change_24h": -0.1515371308864082,
        "price_change_percentage_24h": -5.54882,
        "market_cap_change_24h": -52441125.589954734,
        "market_cap_change_percentage_24h": -5.45657,
        "circulating_supply": 352255382.939829,
        "total_supply": 950207481.623633,
        "max_supply": null,
        "ath": 24.71,
        "ath_change_percentage": -89.55885,
        "ath_date": "2021-11-21T17:33:44.178Z",
        "atl": 0.128398,
        "atl_change_percentage": 1909.52596,
        "atl_date": "2021-06-22T12:52:49.089Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:42.244Z"
    },
    {
        "id": "whitebit",
        "symbol": "wbt",
        "name": "WhiteBIT Coin",
        "image": "https://assets.coingecko.com/coins/images/27045/large/wbt_token.png?1696526096",
        "current_price": 6.28,
        "market_cap": 903341442,
        "market_cap_rank": 76,
        "fully_diluted_valuation": 2263688378,
        "total_volume": 8922212,
        "high_24h": 6.4,
        "low_24h": 6.2,
        "price_change_24h": -0.10986075103282733,
        "price_change_percentage_24h": -1.72006,
        "market_cap_change_24h": -16645590.439886093,
        "market_cap_change_percentage_24h": -1.80933,
        "circulating_supply": 144118517.10815412,
        "total_supply": 361147399,
        "max_supply": 400000000,
        "ath": 14.64,
        "ath_change_percentage": -57.18925,
        "ath_date": "2022-10-28T12:32:18.119Z",
        "atl": 3.06,
        "atl_change_percentage": 104.76982,
        "atl_date": "2023-02-13T19:01:21.899Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:09.497Z"
    },
    {
        "id": "beam-2",
        "symbol": "beam",
        "name": "Beam",
        "image": "https://assets.coingecko.com/coins/images/32417/large/chain-logo.png?1698114384",
        "current_price": 0.01758079,
        "market_cap": 881631010,
        "market_cap_rank": 77,
        "fully_diluted_valuation": 1097799561,
        "total_volume": 25913545,
        "high_24h": 0.01926968,
        "low_24h": 0.01716047,
        "price_change_24h": -0.00122665540783087,
        "price_change_percentage_24h": -6.52218,
        "market_cap_change_24h": -63718096.890126586,
        "market_cap_change_percentage_24h": -6.74017,
        "circulating_supply": 50276724552.1436,
        "total_supply": 62604156957.4713,
        "max_supply": 62604156957.4713,
        "ath": 0.02720031,
        "ath_change_percentage": -35.53173,
        "ath_date": "2023-12-14T05:24:58.029Z",
        "atl": 0.0043383,
        "atl_change_percentage": 304.20357,
        "atl_date": "2023-10-29T08:20:14.064Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:00.348Z"
    },
    {
        "id": "cheelee",
        "symbol": "cheel",
        "name": "Cheelee",
        "image": "https://assets.coingecko.com/coins/images/28573/large/CHEEL_%D1%82%D0%BE%D0%BD%D0%BA%D0%B0%D1%8F_%D0%BE%D0%B1%D0%B2%D0%BE%D0%B4%D0%BA%D0%B0_%283%29.png?1696527561",
        "current_price": 16.18,
        "market_cap": 870952680,
        "market_cap_rank": 78,
        "fully_diluted_valuation": 16176742487,
        "total_volume": 5418707,
        "high_24h": 16.44,
        "low_24h": 15.91,
        "price_change_24h": 0.228246,
        "price_change_percentage_24h": 1.43057,
        "market_cap_change_24h": 10394474,
        "market_cap_change_percentage_24h": 1.20788,
        "circulating_supply": 53839806.1486529,
        "total_supply": 1000000000,
        "max_supply": 1000000000,
        "ath": 17.32,
        "ath_change_percentage": -6.49814,
        "ath_date": "2023-07-16T21:40:08.888Z",
        "atl": 3.44,
        "atl_change_percentage": 370.81497,
        "atl_date": "2023-02-13T02:53:08.933Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:36.045Z"
    },
    {
        "id": "eos",
        "symbol": "eos",
        "name": "EOS",
        "image": "https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1696501893",
        "current_price": 0.765223,
        "market_cap": 867376076,
        "market_cap_rank": 79,
        "fully_diluted_valuation": null,
        "total_volume": 177864345,
        "high_24h": 0.806645,
        "low_24h": 0.745369,
        "price_change_24h": -0.024268192753576057,
        "price_change_percentage_24h": -3.0739,
        "market_cap_change_24h": -28399362.383442044,
        "market_cap_change_percentage_24h": -3.17037,
        "circulating_supply": 1135158988.922,
        "total_supply": null,
        "max_supply": null,
        "ath": 22.71,
        "ath_change_percentage": -96.63455,
        "ath_date": "2018-04-29T07:50:33.540Z",
        "atl": 0.5024,
        "atl_change_percentage": 52.13728,
        "atl_date": "2017-10-23T00:00:00.000Z",
        "roi": {
            "times": -0.22704762708994888,
            "currency": "usd",
            "percentage": -22.70476270899489
        },
        "last_updated": "2024-01-13T05:14:11.769Z"
    },
    {
        "id": "neo",
        "symbol": "neo",
        "name": "NEO",
        "image": "https://assets.coingecko.com/coins/images/480/large/NEO_512_512.png?1696501735",
        "current_price": 12.21,
        "market_cap": 860485000,
        "market_cap_rank": 80,
        "fully_diluted_valuation": 1220026939,
        "total_volume": 50877812,
        "high_24h": 13.25,
        "low_24h": 11.97,
        "price_change_24h": -0.9763283931484459,
        "price_change_percentage_24h": -7.40603,
        "market_cap_change_24h": -69608468.57514656,
        "market_cap_change_percentage_24h": -7.48403,
        "circulating_supply": 70530000,
        "total_supply": 100000000,
        "max_supply": null,
        "ath": 198.38,
        "ath_change_percentage": -93.8423,
        "ath_date": "2018-01-15T00:00:00.000Z",
        "atl": 0.078349,
        "atl_change_percentage": 15491.34711,
        "atl_date": "2016-10-21T00:00:00.000Z",
        "roi": {
            "times": 338.0711426264908,
            "currency": "usd",
            "percentage": 33807.114262649084
        },
        "last_updated": "2024-01-13T05:13:56.660Z"
    },
    {
        "id": "decentraland",
        "symbol": "mana",
        "name": "Decentraland",
        "image": "https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1696502010",
        "current_price": 0.463723,
        "market_cap": 855612337,
        "market_cap_rank": 81,
        "fully_diluted_valuation": 1016005544,
        "total_volume": 94372204,
        "high_24h": 0.495012,
        "low_24h": 0.447897,
        "price_change_24h": -0.022289738723654584,
        "price_change_percentage_24h": -4.58624,
        "market_cap_change_24h": -41775376.9159044,
        "market_cap_change_percentage_24h": -4.65522,
        "circulating_supply": 1846949852.3578756,
        "total_supply": 2193179327.32015,
        "max_supply": 2193179327.32015,
        "ath": 5.85,
        "ath_change_percentage": -92.07736,
        "ath_date": "2021-11-25T10:04:18.534Z",
        "atl": 0.00923681,
        "atl_change_percentage": 4918.3934,
        "atl_date": "2017-10-31T00:00:00.000Z",
        "roi": {
            "times": 22.18617128821204,
            "currency": "usd",
            "percentage": 2218.617128821204
        },
        "last_updated": "2024-01-13T05:13:58.532Z"
    },
    {
        "id": "bitget-token",
        "symbol": "bgb",
        "name": "Bitget Token",
        "image": "https://assets.coingecko.com/coins/images/11610/large/icon_colour.png?1696511504",
        "current_price": 0.595338,
        "market_cap": 833202840,
        "market_cap_rank": 82,
        "fully_diluted_valuation": 1190288921,
        "total_volume": 13873613,
        "high_24h": 0.62577,
        "low_24h": 0.593682,
        "price_change_24h": -0.024440632583556113,
        "price_change_percentage_24h": -3.94345,
        "market_cap_change_24h": -36539672.45679593,
        "market_cap_change_percentage_24h": -4.20121,
        "circulating_supply": 1400001000,
        "total_supply": 2000000000,
        "max_supply": 2000000000,
        "ath": 0.701227,
        "ath_change_percentage": -14.97925,
        "ath_date": "2023-12-19T07:13:51.026Z",
        "atl": 0.0142795,
        "atl_change_percentage": 4075.13693,
        "atl_date": "2020-06-25T04:17:05.895Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:24.691Z"
    },
    {
        "id": "kava",
        "symbol": "kava",
        "name": "Kava",
        "image": "https://assets.coingecko.com/coins/images/9761/large/kava.png?1696509822",
        "current_price": 0.762965,
        "market_cap": 826317534,
        "market_cap_rank": 83,
        "fully_diluted_valuation": 826317533,
        "total_volume": 32260769,
        "high_24h": 0.826909,
        "low_24h": 0.744695,
        "price_change_24h": -0.04507807844331924,
        "price_change_percentage_24h": -5.57867,
        "market_cap_change_24h": -49409440.98791778,
        "market_cap_change_percentage_24h": -5.64211,
        "circulating_supply": 1082861785,
        "total_supply": 1082861784,
        "max_supply": null,
        "ath": 9.12,
        "ath_change_percentage": -91.62465,
        "ath_date": "2021-08-30T11:10:02.948Z",
        "atl": 0.287137,
        "atl_change_percentage": 166.02375,
        "atl_date": "2020-03-13T02:24:16.835Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:54.039Z"
    },
    {
        "id": "bonk",
        "symbol": "bonk",
        "name": "Bonk",
        "image": "https://assets.coingecko.com/coins/images/28600/large/bonk.jpg?1696527587",
        "current_price": 0.00001351,
        "market_cap": 821176210,
        "market_cap_rank": 84,
        "fully_diluted_valuation": 1264601823,
        "total_volume": 217090943,
        "high_24h": 0.00001576,
        "low_24h": 0.00001327,
        "price_change_24h": -0.000001936134885297,
        "price_change_percentage_24h": -12.5387,
        "market_cap_change_24h": -123688055.4646833,
        "market_cap_change_percentage_24h": -13.09056,
        "circulating_supply": 60837624528359.55,
        "total_supply": 93689234980611.55,
        "max_supply": 100000000000000,
        "ath": 0.00003416,
        "ath_change_percentage": -60.30103,
        "ath_date": "2023-12-15T07:35:13.369Z",
        "atl": 8.6142e-8,
        "atl_change_percentage": 15644.21862,
        "atl_date": "2022-12-29T22:48:46.755Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:03.288Z"
    },
    {
        "id": "gala",
        "symbol": "gala",
        "name": "GALA",
        "image": "https://assets.coingecko.com/coins/images/12493/large/GALA-COINGECKO.png?1696512310",
        "current_price": 0.02604319,
        "market_cap": 817514093,
        "market_cap_rank": 85,
        "fully_diluted_valuation": 817478354,
        "total_volume": 441435519,
        "high_24h": 0.02834041,
        "low_24h": 0.02501774,
        "price_change_24h": -0.001793810439788549,
        "price_change_percentage_24h": -6.44398,
        "market_cap_change_24h": -54253721.87328839,
        "market_cap_change_percentage_24h": -6.22341,
        "circulating_supply": 31499458002.9352,
        "total_supply": 31498080975.385,
        "max_supply": 50000000000,
        "ath": 0.824837,
        "ath_change_percentage": -96.84277,
        "ath_date": "2021-11-26T01:03:48.731Z",
        "atl": 0.00013475,
        "atl_change_percentage": 19226.25424,
        "atl_date": "2020-12-28T08:46:48.367Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:01.265Z"
    },
    {
        "id": "astar",
        "symbol": "astr",
        "name": "Astar",
        "image": "https://assets.coingecko.com/coins/images/22617/large/astr.png?1696521933",
        "current_price": 0.149326,
        "market_cap": 809526956,
        "market_cap_rank": 86,
        "fully_diluted_valuation": 1231089960,
        "total_volume": 33176812,
        "high_24h": 0.158733,
        "low_24h": 0.141365,
        "price_change_24h": -0.006756942980077647,
        "price_change_percentage_24h": -4.32908,
        "market_cap_change_24h": -46755907.80294204,
        "market_cap_change_percentage_24h": -5.46033,
        "circulating_supply": 5490703905,
        "total_supply": 8350000451,
        "max_supply": null,
        "ath": 0.421574,
        "ath_change_percentage": -65.1384,
        "ath_date": "2022-01-17T13:34:36.289Z",
        "atl": 0.03269521,
        "atl_change_percentage": 349.50698,
        "atl_date": "2022-10-13T13:05:41.982Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:44.138Z"
    },
    {
        "id": "frax-ether",
        "symbol": "frxeth",
        "name": "Frax Ether",
        "image": "https://assets.coingecko.com/coins/images/28284/large/frxETH_icon.png?1696527284",
        "current_price": 2530.48,
        "market_cap": 775166051,
        "market_cap_rank": 87,
        "fully_diluted_valuation": 775166051,
        "total_volume": 18359825,
        "high_24h": 2672.41,
        "low_24h": 2475.1,
        "price_change_24h": -55.83424789718447,
        "price_change_percentage_24h": -2.15884,
        "market_cap_change_24h": -12326084.354084492,
        "market_cap_change_percentage_24h": -1.56523,
        "circulating_supply": 306267.907686872,
        "total_supply": 306267.907686872,
        "max_supply": null,
        "ath": 2672.41,
        "ath_change_percentage": -5.26632,
        "ath_date": "2024-01-12T16:49:45.243Z",
        "atl": 1137.25,
        "atl_change_percentage": 122.61374,
        "atl_date": "2022-11-23T02:25:33.267Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:54.899Z"
    },
    {
        "id": "oasis-network",
        "symbol": "rose",
        "name": "Oasis Network",
        "image": "https://assets.coingecko.com/coins/images/13162/large/rose.png?1696512946",
        "current_price": 0.113362,
        "market_cap": 762768139,
        "market_cap_rank": 88,
        "fully_diluted_valuation": 1133419425,
        "total_volume": 48478096,
        "high_24h": 0.127874,
        "low_24h": 0.11168,
        "price_change_24h": -0.011366470060499609,
        "price_change_percentage_24h": -9.11294,
        "market_cap_change_24h": -74700314.34320414,
        "market_cap_change_percentage_24h": -8.91978,
        "circulating_supply": 6729795897.58809,
        "total_supply": 10000000000,
        "max_supply": 10000000000,
        "ath": 0.597347,
        "ath_change_percentage": -81.02453,
        "ath_date": "2022-01-15T03:31:11.062Z",
        "atl": 0.03200566,
        "atl_change_percentage": 254.15402,
        "atl_date": "2020-11-26T17:09:12.182Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:43.817Z"
    },
    {
        "id": "iota",
        "symbol": "iota",
        "name": "IOTA",
        "image": "https://assets.coingecko.com/coins/images/692/large/IOTA_Swirl.png?1696501881",
        "current_price": 0.243962,
        "market_cap": 754889718,
        "market_cap_rank": 89,
        "fully_diluted_valuation": 1121772375,
        "total_volume": 21463261,
        "high_24h": 0.267257,
        "low_24h": 0.239609,
        "price_change_24h": -0.019548609123513444,
        "price_change_percentage_24h": -7.41852,
        "market_cap_change_24h": -60233879.31213951,
        "market_cap_change_percentage_24h": -7.38954,
        "circulating_supply": 3095541289,
        "total_supply": 4600000000,
        "max_supply": 4600000000,
        "ath": 5.25,
        "ath_change_percentage": -95.34975,
        "ath_date": "2017-12-19T00:00:00.000Z",
        "atl": 0.081637,
        "atl_change_percentage": 198.98239,
        "atl_date": "2020-03-13T02:22:41.168Z",
        "roi": {
            "times": 3.2524518373204394,
            "currency": "btc",
            "percentage": 325.245183732044
        },
        "last_updated": "2024-01-13T05:13:50.176Z"
    },
    {
        "id": "ethereum-name-service",
        "symbol": "ens",
        "name": "Ethereum Name Service",
        "image": "https://assets.coingecko.com/coins/images/19785/large/acatxTm8_400x400.jpg?1696519207",
        "current_price": 24.21,
        "market_cap": 742454484,
        "market_cap_rank": 90,
        "fully_diluted_valuation": 2419403430,
        "total_volume": 635960282,
        "high_24h": 26.1,
        "low_24h": 20.99,
        "price_change_24h": 2.93,
        "price_change_percentage_24h": 13.7883,
        "market_cap_change_24h": 88267520,
        "market_cap_change_percentage_24h": 13.49271,
        "circulating_supply": 30687502.3178397,
        "total_supply": 100000000,
        "max_supply": 100000000,
        "ath": 83.4,
        "ath_change_percentage": -70.73184,
        "ath_date": "2021-11-11T02:20:01.099Z",
        "atl": 6.69,
        "atl_change_percentage": 264.81864,
        "atl_date": "2023-10-19T02:35:00.861Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:02.190Z"
    },
    {
        "id": "dydx",
        "symbol": "ethdydx",
        "name": "dYdX",
        "image": "https://assets.coingecko.com/coins/images/17500/large/hjnIm9bV.jpg?1696517040",
        "current_price": 2.69,
        "market_cap": 739535250,
        "market_cap_rank": 91,
        "fully_diluted_valuation": 2687726487,
        "total_volume": 223454264,
        "high_24h": 2.94,
        "low_24h": 2.64,
        "price_change_24h": -0.07862747042310936,
        "price_change_percentage_24h": -2.83966,
        "market_cap_change_24h": -21155454.489757657,
        "market_cap_change_percentage_24h": -2.78108,
        "circulating_supply": 275152718.7994542,
        "total_supply": 1000000000,
        "max_supply": 1000000000,
        "ath": 27.86,
        "ath_change_percentage": -90.3556,
        "ath_date": "2021-09-30T01:23:43.570Z",
        "atl": 1.011,
        "atl_change_percentage": 165.87839,
        "atl_date": "2022-06-18T20:55:50.603Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:02.443Z"
    },
    {
        "id": "chiliz",
        "symbol": "chz",
        "name": "Chiliz",
        "image": "https://assets.coingecko.com/coins/images/8834/large/CHZ_Token_updated.png?1696508986",
        "current_price": 0.082746,
        "market_cap": 736899888,
        "market_cap_rank": 92,
        "fully_diluted_valuation": 736899888,
        "total_volume": 66601717,
        "high_24h": 0.084075,
        "low_24h": 0.077289,
        "price_change_24h": 0.00017707,
        "price_change_percentage_24h": 0.21445,
        "market_cap_change_24h": 3557558,
        "market_cap_change_percentage_24h": 0.48512,
        "circulating_supply": 8888888888,
        "total_supply": 8888888888,
        "max_supply": 8888888888,
        "ath": 0.878633,
        "ath_change_percentage": -90.56589,
        "ath_date": "2021-03-13T08:04:21.200Z",
        "atl": 0.00410887,
        "atl_change_percentage": 1917.37162,
        "atl_date": "2019-09-28T00:00:00.000Z",
        "roi": {
            "times": 2.761179081888267,
            "currency": "usd",
            "percentage": 276.11790818882673
        },
        "last_updated": "2024-01-13T05:13:56.627Z"
    },
    {
        "id": "woo-network",
        "symbol": "woo",
        "name": "WOO",
        "image": "https://assets.coingecko.com/coins/images/12921/large/WOO_Logos_2023_Profile_Pic_WOO.png?1696512709",
        "current_price": 0.405478,
        "market_cap": 734054602,
        "market_cap_rank": 93,
        "fully_diluted_valuation": 904391315,
        "total_volume": 39754917,
        "high_24h": 0.45337,
        "low_24h": 0.395498,
        "price_change_24h": -0.04571142024421654,
        "price_change_percentage_24h": -10.13132,
        "market_cap_change_24h": -82304205.69650662,
        "market_cap_change_percentage_24h": -10.08187,
        "circulating_supply": 1810345594.41482,
        "total_supply": 2230434666.90186,
        "max_supply": 2230434666.90186,
        "ath": 1.78,
        "ath_change_percentage": -77.25246,
        "ath_date": "2021-11-15T13:44:18.437Z",
        "atl": 0.02211546,
        "atl_change_percentage": 1729.11871,
        "atl_date": "2021-01-04T10:19:13.803Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:06.580Z"
    },
    {
        "id": "usdd",
        "symbol": "usdd",
        "name": "USDD",
        "image": "https://assets.coingecko.com/coins/images/25380/large/UUSD.jpg?1696524513",
        "current_price": 0.989206,
        "market_cap": 717502918,
        "market_cap_rank": 94,
        "fully_diluted_valuation": null,
        "total_volume": 10308285,
        "high_24h": 0.994678,
        "low_24h": 0.975816,
        "price_change_24h": 0.0004549,
        "price_change_percentage_24h": 0.04601,
        "market_cap_change_24h": 35553,
        "market_cap_change_percentage_24h": 0.00496,
        "circulating_supply": 725332033.742248,
        "total_supply": null,
        "max_supply": null,
        "ath": 1.052,
        "ath_change_percentage": -5.99704,
        "ath_date": "2023-10-23T22:45:25.398Z",
        "atl": 0.928067,
        "atl_change_percentage": 6.53769,
        "atl_date": "2022-06-19T16:15:11.558Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:50.395Z"
    },
    {
        "id": "pancakeswap-token",
        "symbol": "cake",
        "name": "PancakeSwap",
        "image": "https://assets.coingecko.com/coins/images/12632/large/pancakeswap-cake-logo_%281%29.png?1696512440",
        "current_price": 2.89,
        "market_cap": 709718569,
        "market_cap_rank": 95,
        "fully_diluted_valuation": 1115508363,
        "total_volume": 101372420,
        "high_24h": 3.09,
        "low_24h": 2.82,
        "price_change_24h": -0.15957435509351603,
        "price_change_percentage_24h": -5.22517,
        "market_cap_change_24h": -39595522.02793896,
        "market_cap_change_percentage_24h": -5.28424,
        "circulating_supply": 245486650.146131,
        "total_supply": 385846479.485489,
        "max_supply": 450000000,
        "ath": 43.96,
        "ath_change_percentage": -93.43266,
        "ath_date": "2021-04-30T10:08:22.934Z",
        "atl": 0.194441,
        "atl_change_percentage": 1384.9294,
        "atl_date": "2020-11-03T14:29:34.165Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:04.415Z"
    },
    {
        "id": "blur",
        "symbol": "blur",
        "name": "Blur",
        "image": "https://assets.coingecko.com/coins/images/28453/large/blur.png?1696527448",
        "current_price": 0.575153,
        "market_cap": 705483186,
        "market_cap_rank": 96,
        "fully_diluted_valuation": 1721942345,
        "total_volume": 333482475,
        "high_24h": 0.646641,
        "low_24h": 0.545259,
        "price_change_24h": 0.02620604,
        "price_change_percentage_24h": 4.77388,
        "market_cap_change_24h": 30475474,
        "market_cap_change_percentage_24h": 4.51483,
        "circulating_supply": 1229105936.07306,
        "total_supply": 3000000000,
        "max_supply": 3000000000,
        "ath": 5.02,
        "ath_change_percentage": -88.55972,
        "ath_date": "2023-02-14T17:33:55.928Z",
        "atl": 0.153143,
        "atl_change_percentage": 274.72893,
        "atl_date": "2023-10-12T17:10:39.401Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:52.232Z"
    },
    {
        "id": "fetch-ai",
        "symbol": "fet",
        "name": "Fetch.ai",
        "image": "https://assets.coingecko.com/coins/images/5681/large/Fetch.jpg?1696506140",
        "current_price": 0.67425,
        "market_cap": 703028973,
        "market_cap_rank": 97,
        "fully_diluted_valuation": 776827595,
        "total_volume": 117908753,
        "high_24h": 0.72737,
        "low_24h": 0.659376,
        "price_change_24h": -0.030280840066569174,
        "price_change_percentage_24h": -4.29802,
        "market_cap_change_24h": -31126706.071271658,
        "market_cap_change_percentage_24h": -4.2398,
        "circulating_supply": 1043462805,
        "total_supply": 1152997575,
        "max_supply": 1152997575,
        "ath": 1.17,
        "ath_change_percentage": -42.46932,
        "ath_date": "2021-09-08T02:24:24.858Z",
        "atl": 0.00816959,
        "atl_change_percentage": 8153.25176,
        "atl_date": "2020-03-13T02:24:18.347Z",
        "roi": {
            "times": 6.776815913035824,
            "currency": "usd",
            "percentage": 677.6815913035823
        },
        "last_updated": "2024-01-13T05:13:57.671Z"
    },
    {
        "id": "terra-luna",
        "symbol": "lunc",
        "name": "Terra Luna Classic",
        "image": "https://assets.coingecko.com/coins/images/8284/large/01_LunaClassic_color.png?1696508486",
        "current_price": 0.00011884,
        "market_cap": 689563533,
        "market_cap_rank": 98,
        "fully_diluted_valuation": 809761123,
        "total_volume": 62904600,
        "high_24h": 0.0001326,
        "low_24h": 0.00011683,
        "price_change_24h": -0.000011380065985071,
        "price_change_percentage_24h": -8.73915,
        "market_cap_change_24h": -66166166.89200735,
        "market_cap_change_percentage_24h": -8.75527,
        "circulating_supply": 5802485079660,
        "total_supply": 6813914323311.65,
        "max_supply": null,
        "ath": 119.18,
        "ath_change_percentage": -99.9999,
        "ath_date": "2022-04-05T12:24:58.854Z",
        "atl": 9.99967e-7,
        "atl_change_percentage": 11786.10429,
        "atl_date": "2022-05-13T02:34:40.340Z",
        "roi": null,
        "last_updated": "2024-01-13T05:13:56.880Z"
    },
    {
        "id": "xdce-crowd-sale",
        "symbol": "xdc",
        "name": "XDC Network",
        "image": "https://assets.coingecko.com/coins/images/2912/large/xdc-icon.png?1696503661",
        "current_price": 0.04961354,
        "market_cap": 688814853,
        "market_cap_rank": 99,
        "fully_diluted_valuation": 1878401243,
        "total_volume": 28343898,
        "high_24h": 0.051919,
        "low_24h": 0.04906635,
        "price_change_24h": -0.001884908491161151,
        "price_change_percentage_24h": -3.66013,
        "market_cap_change_24h": -26179113.245675087,
        "market_cap_change_percentage_24h": -3.66145,
        "circulating_supply": 13890819350.55,
        "total_supply": 37880327674.95,
        "max_supply": null,
        "ath": 0.192754,
        "ath_change_percentage": -74.2236,
        "ath_date": "2021-08-21T04:39:48.324Z",
        "atl": 0.00039532,
        "atl_change_percentage": 12468.3781,
        "atl_date": "2019-07-22T00:00:00.000Z",
        "roi": {
            "times": 1.59172256642446,
            "currency": "eth",
            "percentage": 159.172256642446
        },
        "last_updated": "2024-01-13T05:14:13.304Z"
    },
    {
        "id": "rocket-pool",
        "symbol": "rpl",
        "name": "Rocket Pool",
        "image": "https://assets.coingecko.com/coins/images/2090/large/rocket_pool_%28RPL%29.png?1696503058",
        "current_price": 34.24,
        "market_cap": 684213612,
        "market_cap_rank": 100,
        "fully_diluted_valuation": 684213612,
        "total_volume": 12639272,
        "high_24h": 37.36,
        "low_24h": 33.25,
        "price_change_24h": -1.5720184057421136,
        "price_change_percentage_24h": -4.38948,
        "market_cap_change_24h": -31204240.867786407,
        "market_cap_change_percentage_24h": -4.36168,
        "circulating_supply": 19991436.9382174,
        "total_supply": 19991436.9382174,
        "max_supply": null,
        "ath": 61.9,
        "ath_change_percentage": -44.59221,
        "ath_date": "2023-04-16T19:20:19.534Z",
        "atl": 0.00884718,
        "atl_change_percentage": 387552.07117,
        "atl_date": "2018-08-28T00:00:00.000Z",
        "roi": null,
        "last_updated": "2024-01-13T05:14:05.109Z"
    }
]
