import {
  CreateNetworkReq,
  GetDepositWalletParams,
  GetDepositWalletResponse,
  INetworkInterface,
  INetworkListResponse,
} from "./models";
import { apiClient } from "../../config/serverConstants";

class NetworkServices implements INetworkInterface {
  async getNetworkList(): Promise<INetworkListResponse> {
    const response = await apiClient.get("/api/Network/getNetworksList");
    return response.data;
  }
  async getDepositWallet(
    data: GetDepositWalletParams
  ): Promise<GetDepositWalletResponse> {
    const response = await apiClient.get("/api/Network/getDepositWallet", {
      params: data,
    });
    return response.data;
  }
  async createNetwork(data: CreateNetworkReq) {
    const response = await apiClient.post("/api/Network/CreateNetwork", data);
    return response.data;
  }
}
const NetworkService = new NetworkServices();
export default NetworkService;
