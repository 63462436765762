import {
  FlexColumn,
  FlexColumnStart,
  FlexRowResponsive,
} from "../../../style/projectComponents";
import {
  Content,
  CustomStyledLink,
  Title,
  Wrapper,
} from "../components";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Figure = (
  <svg
    className="subscription-block__img subscription-block__img-pc"
    width="1920"
    height="449"
    viewBox="0 0 1920 449"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M635 124L635 449" stroke="#DEDDD8"></path>
    <path d="M0 1H1920" stroke="#DEDDD8"></path>
    <path d="M960 124L1920 124" stroke="#DEDDD8"></path>
    <path d="M1403 287L1610 287" stroke="#DEDDD8"></path>
    <path d="M1122 124L1122 1" stroke="#DEDDD8"></path>
    <path d="M310 63H960" stroke="#00AAC3"></path>
    <path d="M310 1V449" stroke="#DEDDD8"></path>
    <path d="M960 1V449" stroke="#DEDDD8"></path>
    <path d="M1610 1V449" stroke="#DEDDD8"></path>
    <path
      d="M960 449C960 269.507 814.493 124 635 124C455.507 124 310 269.507 310 449"
      stroke="#00AAC3"
    ></path>
    <path
      d="M1610 62.5C1610 96.1946 1582.68 124 1549 124C1515.31 124 1487.5 96.1894 1487.5 62.5C1487.5 28.8106 1515.31 1 1549 1C1582.68 1 1610 28.8054 1610 62.5Z"
      stroke="#DEDDD8"
    ></path>
    <path d="M1920 247H1733V449" stroke="#DEDDD8"></path>
    <path
      d="M1122 124V124C1122 214.022 1194.98 287 1285 287H1403"
      stroke="#DEDDD8"
    ></path>
    <path d="M62 1V449" stroke="#DEDDD8"></path>
  </svg>
);

const FigureContainer = styled(FlexColumn)`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #deddd8;
`;

const Inner = styled(FlexRowResponsive)`
  justify-content: space-between;
  align-items: start;

  .download {
    gap: 1.25rem;

    a {
      padding-left: 0;
      @media (max-width: 1000px) {
        font-size: 1.425rem;
        text-align: left;
      }
    }
  }
`;
const StyledA = styled.a`
  font-size: 2.125rem;
  line-height: 110%;
  text-decoration: none;
  font-weight: 300;
  align-items: center;
  display: flex;
  gap: 1rem;
  width: 100%;
  color: ${({theme}) => theme.colors.blue};

  img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 1000px) {
    font-size: 1.625rem;
    gap: 0.5rem;
    padding-left: 20px;
  }
`;

export const SubscriptionPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Content>
        <CustomStyledLink to={"/"}>
          <img src={"/images/arrBack.svg"} /> {t("documents.back")}
        </CustomStyledLink>
        <Inner className={"docs"}>
          <Title>{t("documents.subscription.title")}</Title>

          <FlexColumnStart className={"download"}>
            <Title mini={true} style={{ color: "#AAB0B8" }}>
              {t("documents.subscription.downloadTitle")}
            </Title>
          </FlexColumnStart>
        </Inner>
      </Content>
      <FigureContainer>{Figure}</FigureContainer>
    </Wrapper>
  );
};
