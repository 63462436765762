import {FlexRowBetween, InputTitle} from "../../style/projectComponents";
import React, {useContext} from "react";
import styled from "styled-components/macro";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import ProfileStorage from "../../storage/ProfileStorage/ProfileStorage";

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 16px;
  width: 100%;
  max-width: 556px;
  background: ${({theme}) => theme.colors.gray050};
  margin-bottom: 20px;
  height: 124px;

  img {
    margin-bottom: 25px;
    margin-top: 25px;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 40px);
  }
`;

const RefCodeButton = styled(FlexRowBetween)`
  padding: 16px;
  font-size: 15px;
  background: ${({theme}) => theme.colors.inputBackground};
  border-radius: 8px;
  cursor: pointer;
  margin-top: 12px;
  width: calc(100% - 40px);
  align-items: center;

  img {
    width: 18px;
    object-fit: cover;
    margin: 0;
    padding: 4px;
    filter: ${({theme}) => theme.colors.white === "#000" && "invert(1)"};
  }
`;

export const ReferralLinkBlock = () => {
    const {t} = useTranslation();
    const GlobalModalStorage = useContext(GlobalModalStore);
    const ProfileStore = useContext(ProfileStorage);

    const profile = ProfileStore?.getProfile();
    const refCode = profile?.referalCode;

    const refLink = `https://invest-app.defence.investments/register?ref=${refCode}`;
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(refLink);
            GlobalModalStorage.makeVisible(true, t("common.copySuccess"));
        } catch (err) {
            GlobalModalStorage.makeVisible(false, t("common.copyFailed"));
        }
    };

    return (
        <Block>
            <InputTitle>{t("auth.referralLink")}</InputTitle>
            <RefCodeButton onClick={copyToClipboard}>
                <img src="/images/interface/copy.svg" alt=""/>
                <p>{refCode}</p>
            </RefCodeButton>
        </Block>
    );
};
