import {
    ErrorText,
    FlexColumn,
    FlexRow,
    StyledButton,
} from "../../../style/projectComponents";
import React, {
    ComponentPropsWithoutRef,
    FC,
    useContext,
    useEffect,
    useState,
} from "react";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components/macro";
import {ValidationInputComponent} from "../../global/ValidationInputComponent";
import {IUserDetailsResponse} from "../../../service/user-api/models";
import {useMutation, useQuery} from "react-query";
import AdminApi from "../../../service/admin-api/AdminApi";
import {
    IAccrualOfInterestReq,
    IDepositMoneyRequest,
} from "../../../service/admin-api/models";
import {useTranslation} from "react-i18next";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import {CheckComponent} from "../../auth/Register/CheckComponent";
import {
    IGetWithdrawalRequest,
    IWithdrawalStatus,
} from "../../../service/withdrawal-api/models";
import WithdrawalApi from "../../../service/withdrawal-api/WithdrawalApi";
import {DropdownComponent} from "../../global/DropdownComponent";

type BalanceAddForm = {
    user: IUserDetailsResponse;
};

type IFormType = {
    name: "percent";
    rules: any;
    type: string;
    placeholder: string;
};

const forms: IFormType[] = [
    {
        name: "percent",
        rules: {
            required: true,
            valueAsNumber: true,
            pattern: {
                value: /^-?\d{1,7}(?:\.\d{1,2})?$/,
                message: "adminPercent.errors.max",
            },
        },
        type: "number",
        placeholder: "adminUserDetail.commissionRule",
    },
];

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  border: 1px solid ${({theme}) => theme.colors.lightGrayBg};
  padding: 1rem var(--4, 2rem) 3rem var(--4, 2rem);
  margin: 10px;

  h2 {
    font-size: 20px;
    width: 100%;
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }

  .inps {
    gap: 0.81rem;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }
`;

export const PercentAddForm: FC<BalanceAddForm & ComponentPropsWithoutRef<"form">> = (props) => {
        const {user, ...other} = props;
        const {
            register,
            control,
            clearErrors,
            reset,
            resetField,
            setValue,
            formState: {errors, isDirty},
            watch,
            handleSubmit,
        } = useForm();
        const depositMoney = useMutation((data: IDepositMoneyRequest) =>
            AdminApi.depositMoney(data)
        );
        const [uId, setUId] = useState<any>(
            user.wallets.find((it) => it.currency === "USDT")?.id
        );

        const ITEMS_PER_PAGE = 10;
        const notApprovedStatuses = [
            IWithdrawalStatus.notApproved,
            IWithdrawalStatus.undefined,
        ];
        const getWithdrawalQuery = useMutation((data: IGetWithdrawalRequest) =>
            WithdrawalApi.getWithdrawal(data)
        );
        const [anyWithdrawActive, SetAnyWithdrawActive] = useState(false);

        const walletsItems = user.wallets || [];
        const currencyNames = walletsItems.map((c) => c?.currency) || [];
        const [walletId, setWalletId] = useState<any>(0);
        const currencyName = walletsItems[walletId]?.currency;
        const handleChangeWalletsId = (id: number) => {
            setWalletId(id);
        };

        const {data: currencyItems} = useQuery(
                "currency_list",
                () =>
                    AdminApi.getCurrencies({
                        skip: 0,
                        take: 30,
                    })
            )
        ;
        const currenciesItems = currencyItems?.currencies || [];
        const activeCurrencyId = currenciesItems?.find(
            (cur) => cur?.name?.toLowerCase() === currencyName?.toLowerCase()
        )?.id;

        useEffect(() => {
            setUId(user.wallets.find((it) => it.currency === "USDT")?.id);
            //
            const searchParams: IGetWithdrawalRequest = {
                statuses: notApprovedStatuses,
                skip: 0,
                take: ITEMS_PER_PAGE,
            };
            getWithdrawalQuery.mutate(searchParams, {
                onSuccess: (data) => {
                    SetAnyWithdrawActive(data.items.length >= 1);
                },
            });
        }, [user]);

        const GlobalModalStorage = useContext(GlobalModalStore);

        const addPercent = useMutation(
            (data: IAccrualOfInterestReq) => AdminApi.accrualOfInterest(data),
            {
                onSuccess: (data) => {
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                    reset({
                        percent: "",
                    });
                    console.log(data);
                },
                onError: (error) => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                    console.log(error);
                },
            }
        );

        const onSubmit = (data: any) => {
            const newData: IAccrualOfInterestReq = {
                walletId: user.wallets[0].id,
                comment: "Interest accrual",
                currencyId: activeCurrencyId,
                percents: parseFloat(parseFloat(data.percent).toFixed(6)),
            };
            addPercent.mutate(newData);
        };

        const {t, i18n} = useTranslation();
        const [openCurrencyMenu, setOpenCurrencyMenu] = useState(false);

        return (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <h3 style={{marginBottom: "1rem", fontSize: "1.5rem", fontWeight: 400}}>
                    {t("adminUserDetail.addPercent")}
                </h3>
                <FlexColumn className={"inps"}>
                    {forms.map((it, idx) =>
                        it.type === "boolean" ? (
                            <CheckComponent
                                key={idx}
                                textXl={true}
                                text={"adminUserDetail.initialBalance"}
                                isClicked={watch("setEfficientBalance")}
                                handleClick={() =>
                                    setValue("setEfficientBalance", !watch("setEfficientBalance"))
                                }
                            />
                        ) : (
                            <React.Fragment key={idx}>
                                <InputWrapper>
                                    <Controller
                                        name={it.name}
                                        control={control}
                                        rules={it.rules}
                                        render={({field: {ref, ...field}}) => (
                                            <ValidationInputComponent
                                                {...field}
                                                maxLength={6}
                                                isError={!!errors[it.name]}
                                                label={t(it.placeholder)}
                                            />
                                        )}
                                    />
                                </InputWrapper>
                                <DropdownComponent
                                    openMenu={openCurrencyMenu}
                                    setOpenMenu={setOpenCurrencyMenu}
                                    itemId={walletId}
                                    setId={handleChangeWalletsId}
                                    itemList={currencyNames}
                                />
                            </React.Fragment>
                        )
                    )}
                </FlexColumn>
                {!!errors["sum"] && (
                    <ErrorText style={{marginBottom: 10}}>
                        {t(errors["sum"]?.message.toString())}
                    </ErrorText>
                )}

                <StyledButton
                    type={"submit"}
                    variant="black"
                    style={{width: "100%", padding: "var(--16, 1rem)", maxWidth: 385}}
                    disabled={!isDirty || depositMoney.isLoading || !uId}
                    noRadius
                >
                    {depositMoney.isLoading
                        ? t("adminPercent.processus")
                        : t("adminPercent.add")}
                </StyledButton>
            </StyledForm>
        );
    }
;
