import React, {createContext, useEffect, useState} from "react";
import {
    AuthContent,
    AuthModal,
    AuthTitle,
    AuthWrapper, FlexColumn,
    FlexRow,
    HalfContent,
    Page,
    StyledLink,
} from "../style/projectComponents";
import {SecondStep} from "../components/auth/Register/SecondStep";
import {useTranslation} from "react-i18next";
import styled from "styled-components/macro";
import {IAuthSignupRequest} from "../service/auth-api/models";
import LeftContentComponent from "../components/auth/Content/LeftContentComponent";
import FirstStep from "../components/auth/Register/FirstStep";

export enum STAGE {
    BASE = "BASE",
    FINAL = "FINAL",
}

const StyledPage = styled(Page)`
  background-position: center;
  background-size: cover;
  justify-content: start;

  .header {
    position: absolute;
  }
`;
const InputsBlock = styled(FlexColumn)`
  width: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
  
  form {
    border-radius: 16px;
    background: ${({theme}) => theme.colors.gray050};
    padding: 20px 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 20px 10px;
      width: calc(100% - 20px);
    }
  }
`;

export const RegisterContext = createContext(null);
export const INITIAL_STATE_REG: IAuthSignupRequest = {
    birthDate: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    password: "",
    phoneNumber: "",
    receivedReferalCode: "",
};
export const RegistrationPage: React.FC = () => {
    const [stage, setStage] = useState(STAGE.BASE);
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formUser, setFormUser] =
        useState<IAuthSignupRequest>(INITIAL_STATE_REG);

    return (
        <StyledPage>
            <AuthContent>
                <AuthWrapper>
                    <LeftContentComponent/>
                    <HalfContent>
                        <AuthModal style={{marginTop: 100, marginBottom: 100}}>
                            <FlexRow
                                style={{
                                    marginBottom: 20,
                                    position: "relative",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "fit-content",
                                }}
                            >
                                {stage === STAGE.FINAL && (
                                    <img
                                        onClick={() => setStage(STAGE.BASE)}
                                        alt=""
                                        width={20}
                                        height={20}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            left: 0,
                                            zIndex: 100,
                                        }}
                                        src={"images/buttons/left-arrow.png"}
                                    />
                                )}

                                <AuthTitle>{t("auth.reg_title")}</AuthTitle>
                            </FlexRow>

                            <RegisterContext.Provider value={{formUser, setFormUser}}>
                                <InputsBlock>
                                    {(stage === STAGE.BASE
                                            ? <FirstStep setRegStage={setStage}/>
                                            : <SecondStep/>
                                    )}
                                </InputsBlock>
                            </RegisterContext.Provider>

                            <FlexRow style={{marginTop: 32}}>
                                <p style={{fontSize: 14}}>{t("auth.already_has")}</p>
                                <StyledLink to="/login" style={{marginLeft: 12}}>
                                    {t("auth.login")}
                                </StyledLink>
                            </FlexRow>
                        </AuthModal>
                    </HalfContent>
                </AuthWrapper>
            </AuthContent>
        </StyledPage>
    );
};
