import {useTranslation} from "react-i18next";
import {CircleTitle, DocContent, RectTitle} from "../components";
import {FlexColumnStart} from "../../../style/projectComponents";

export const First = () => {
    const {t, i18n} = useTranslation();

    return (
        <DocContent>
            <FlexColumnStart className={'block'}>
                <span>
                    {t("documents.terms.1.1")}
                </span>
                <p>
                    {t("documents.terms.1.2")}
                </p>
            </FlexColumnStart>

            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.3")}
                </p>
            </FlexColumnStart>

            <FlexColumnStart className={'block'}>
                <p>
                    {t("documents.terms.1.4")}
                    <CircleTitle text={
                        t(`documents.terms.1.5`)
                    }/>

                    <CircleTitle text={t(`documents.terms.1.6`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.7`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.8`)}/>


                    <CircleTitle text={t(`documents.terms.1.9`)}/>


                    <CircleTitle text={t(`documents.terms.1.10`)}/>


                    {t("documents.terms.1.11")}


                    <CircleTitle text={t(`documents.terms.1.12`)}/>


                    <CircleTitle text={t(`documents.terms.1.13`)}/>


                    <CircleTitle text={t(`documents.terms.1.14`)}/>


                    <CircleTitle text={t(`documents.terms.1.15`)}/>


                    {t("documents.terms.1.16")}


                    <CircleTitle text={t(`documents.terms.1.17`)}/>


                    <CircleTitle text={t(`documents.terms.1.18`)}/>


                    <CircleTitle text={t(`documents.terms.1.19`)}/>


                    {t("documents.terms.1.20")}


                    <CircleTitle text={t(`documents.terms.1.21`)}/>


                    {t("documents.terms.1.22")}


                    <CircleTitle text={t(`documents.terms.1.23`)}/>


                    <CircleTitle text={t(`documents.terms.1.24`)}/>


                    <CircleTitle text={t(`documents.terms.1.25`)}/>


                    {t("documents.terms.1.26")}


                    <CircleTitle text={t(`documents.terms.1.27`)}/>


                    {t("documents.terms.1.28")}


                    <CircleTitle text={t(`documents.terms.1.29`)}/>


                    <CircleTitle text={t(`documents.terms.1.30`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.31`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.32`)}/>


                    <CircleTitle text={t(`documents.terms.1.33`)}/>


                    <CircleTitle text={t(`documents.terms.1.34`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.35`)}/>


                    <CircleTitle text={t(`documents.terms.1.36`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.37`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.38`)}/>


                    <CircleTitle text={t(`documents.terms.1.39`)}/>


                    <CircleTitle
                        text={t(`documents.terms.1.40`)}/>


                    <CircleTitle text={t(`documents.terms.1.41`)}/>


                    {t("documents.terms.1.42")}


                    <CircleTitle text={t(`documents.terms.1.43`)}/>

                    <RectTitle text={t(`documents.terms.1.44`)}/>


                    <RectTitle text={t(`documents.terms.1.45`)}/>


                    <RectTitle text={t(`documents.terms.1.46`)}/>


                    <RectTitle text={t(`documents.terms.1.47`)}/>


                    <RectTitle text={t(`documents.terms.1.48`)}/>


                    <RectTitle text={t(`documents.terms.1.49`)}/>

                    {t("documents.terms.1.50")}

                    <RectTitle text={t(`documents.terms.1.51`)}/>


                    <RectTitle text={t(`documents.terms.1.52`)}/>


                    <RectTitle text={t(`documents.terms.1.53`)}/>


                    <RectTitle text={t(`documents.terms.1.54`)}/>


                    <RectTitle text={t(`documents.terms.1.55`)}/>


                    <RectTitle text={t(`documents.terms.1.56`)}/>


                    <RectTitle text={t(`documents.terms.1.57`)}/>


                    <RectTitle text={t(`documents.terms.1.58`)}/>


                    <RectTitle text={t(`documents.terms.1.59`)}/>


                    <RectTitle text={t(`documents.terms.1.60`)}/>


                    <RectTitle text={t(`documents.terms.1.61`)}/>


                    <RectTitle text={t(`documents.terms.1.62`)}/>


                    <RectTitle
                        text={t(`documents.terms.1.63`)}/>


                    <RectTitle text={t(`documents.terms.1.64`)}/>

                    {t("documents.terms.1.65")}

                    <RectTitle text={t(`documents.terms.1.66`)}/>


                    <RectTitle text={t(`documents.terms.1.67`)}/>


                    <RectTitle text={t(`documents.terms.1.68`)}/>


                </p>
            </FlexColumnStart>
        </DocContent>
    );
};
