import {DefaultTheme} from 'styled-components';

const DarkStyle: DefaultTheme = {
    colors: {
        primaryActive: "#008080",
        lightGrayBg: "#DEDDD8",
        lightGray: "#EBEBEBFF",
        gray050: "#121212",
        mediumGray: "#e8e8e8",
        pageBg: "#000",
        gray: "#AAB0B8",
        blueGray: "#E2E8F0",
        secondary: "rgb(84, 84, 84)",
        buttonHover: "rgba(40,46,54,0.5)",
        greenSuccess: "#00CD8A",
        greenMarket: "rgba(80,200,120,0.1)",
        redFail: "#FF007A",
        redMarket: "rgba(241,80,123,0.1)",
        redInput: "#f1507b",
        white: '#000',
        black: "#fff",
        blue: "#35C2FF",
        dark: "#5C626D",
        lightGrayButton: "#202020",
        grayButton: "#5C626D",
        pink: "#E66491",
        lightGrayBorder: "#2B2B2B",
        modalBg: "#4D4D4D7A",
        inputBackground: "#202020",
        inputBackgroundSecondary: "#2C2C2C",
        popupBg: "#111111",
    }
};

export const LightStyle: DefaultTheme = {
    colors: {
        primaryActive: "#008080",
        lightGrayBg: "#DEDDD8",
        lightGray: "#EBEBEBFF",
        gray050: "#F8F9FB",
        mediumGray: "#e8e8e8",
        pageBg: "#fff",
        gray: "#AAB0B8",
        blueGray: "#E2E8F0",
        secondary: "rgb(84, 84, 84)",
        buttonHover: "rgba(40,46,54,0.5)",
        greenSuccess: "#00CD8A",
        greenMarket: "rgba(80,200,120,0.1)",
        redFail: "#FF007A",
        redMarket: "rgba(241,80,123,0.1)",
        redInput: "#f1507b",
        white: '#fff',
        black: "#000",
        blue: "#35C2FF",
        dark: "#222222",
        lightGrayButton: "#ededed",
        grayButton: "#929292",
        pink: "#E66491",
        lightGrayBorder: "#E0E5E6",
        modalBg: "#000000A5",
        inputBackground: "#fff",
        inputBackgroundSecondary: "#EDF4FF",
        popupBg: "#fff",
    }
};

export default DarkStyle;
