import React, { useState } from "react";
import styled, {useTheme} from "styled-components/macro";
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
} from "../../style/projectComponents";
import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IDashboardOperationTypeRsp } from "../../service/user-api/models";
import { useTranslation } from "react-i18next";
import { FilterMethodButton } from "./FilterMethodButton";
import { isAfter, parse, subMonths, subWeeks, subYears } from "date-fns";

const FilterWrapper = styled(FlexRow)`
  justify-content: end;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const FilterMethods = styled(FlexRow)`
  @media (max-width: 780px) {
    width: 100%;
  }
`;

const ChartWrapper = styled(FlexColumn)`
  width: calc(100% - 32px);
  height: 500px;

  line {
    stroke: ${({theme}) => theme.colors.lightGray};
  }
`;

interface ChartComponentProps {
  variant?: "gradient" | "linear";
  data: any;
  xAxisKey?: string;
  yAxisKey?: string;
  titleKey?: string | string[];
  valueKey?: string | string[];
}

const CustomTooltip = (props) => {
  const { active, payload, label, titleKey, valueKey } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const numKeys = ["balance", "deposit"];
  const handleKeys = () => {
    if (typeof titleKey === "string") {
      const res = payload[0].payload[titleKey]
        ? t(`common.${payload[0].payload[titleKey]}`)
        : titleKey;
      return [res];
    } else {
      const res = titleKey.map((it, idx) => {
        const r = payload[0].payload[it]
          ? t(`common.${payload[0].payload[it]}`)
          : it;
        return r;
      });
      return res;
    }
  };

  const handleValues = () => {
    if (typeof valueKey === "string") {
      const res = numKeys.find((it) => valueKey.toLowerCase().includes(it))
        ? `$${Number(payload[0].payload[valueKey]).toFixed(2)}`
        : payload[0].payload[valueKey];
      return [res];
    } else {
      const res = valueKey.map((it, idx) => {
        let rs;
        if (it === "balanceDiffPercent") {
          rs = Number(payload[0].payload[it]).toFixed(2);
          if (
            payload[0].payload.operationType ===
            IDashboardOperationTypeRsp.withdrawalRequest
          ) {
            return `-${rs} %`;
          } else {
            return `${rs} %`;
          }
        }
        if (
          numKeys.find((it_) => it.toLowerCase().includes(it_.toLowerCase()))
        ) {
          rs = Number(payload[0].payload[it]).toFixed(2);
          if (
            payload[0].payload.operationType ===
              IDashboardOperationTypeRsp.withdrawalRequest &&
            it != "balance"
          ) {
            return `-$${rs}`;
          } else {
            return `$${rs}`;
          }
        }
        rs = payload[0].payload[it];
        return rs;
      });
      return res;
    }
  };

  if (active && payload && payload.length) {
    return (
      <FlexColumnStart
        style={{
          // @ts-ignore
          background: theme.colors.inputBackgroundSecondary,
          padding: "0.75rem 1rem",
          borderRadius: "0.1875rem",
        }}
      >
        <p style={{ fontSize: "0.875rem", fontWeight: 500 }} className="label">
          {`${label}`}
        </p>
        <div
          style={{
            fontSize: "0.875rem",
            // @ts-ignore
            color: theme.colors.dark,
            paddingTop: "0.25rem",
          }}
        >
          {handleKeys().map((it, idx) => (
            <FlexColumnStart key={idx}>
              {`${it}: ${handleValues().at(idx)}`}
              <br />
            </FlexColumnStart>
          ))}
        </div>
      </FlexColumnStart>
    );
  }

  return null;
};

export const ChartComponent: React.FC<ChartComponentProps> = (props) => {
  const {
    data,
    titleKey,
    valueKey,
    variant = "gradient",
    xAxisKey = "operationDate",
    yAxisKey = "balanceDiff",
  } = props;

  const formatYAxis = (value) => {
    return Math.round(value);
  };

  const { t } = useTranslation();
  const chartPeriods = [
    t("dashboard.chart.week1"),
    t("dashboard.chart.month1"),
    t("dashboard.chart.year1"),
    t("dashboard.chart.all"),
  ];
  const [chartPeriodId, setChartPeriodId] = useState(3);

  const formatString = "dd.MM.yy";

  const parsedDate = (date: string) => parse(date, formatString, new Date());
  const subtractedDate =
    (chartPeriodId === 0 && subWeeks(new Date(), 1)) ||
    (chartPeriodId === 1 && subMonths(new Date(), 1)) ||
    (chartPeriodId === 2 && subYears(new Date(), 1));

  const filteredData =
    chartPeriodId < 3
      ? data.filter((dataItem) =>
          isAfter(parsedDate(dataItem.date), subtractedDate)
        )
      : data;

  return (
    <ChartWrapper>
      <ResponsiveContainer
        width="100%"
        height={250}
        key={`rc_${filteredData.length}`}
      >
        <ComposedChart data={filteredData} key={`cc_${filteredData.length}`}>
          {variant === "gradient" && (
            <defs>
              <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
                <stop offset="35%" stopColor="#40915c" />
                <stop offset="65%" stopColor="#50c878" />
              </linearGradient>
            </defs>
          )}
          <XAxis dataKey={xAxisKey} style={{ fontSize: 14 }} />
          <YAxis
            type="number"
            // @ts-ignore
            tickFormatter={formatYAxis}
            domain={[
              (dataMin) =>
                valueKey === "registrationsCount"
                  ? 0
                  : (dataMin <= 0 ? dataMin * 1.1 : dataMin * 0.9).toFixed(0),
              (dataMax) => (dataMax * 1.1).toFixed(0),
            ]}
            dataKey={yAxisKey}
            style={{ fontSize: 14 }}
          />
          <Tooltip
            content={<CustomTooltip titleKey={titleKey} valueKey={valueKey} />}
            contentStyle={{ fontSize: 14, textAlign: "left" }}
          />
          {variant === "gradient" && (
            <Area
              key={`ac_${filteredData.length}`}
              type={"monotone"}
              dataKey={yAxisKey}
              stroke="#50c878"
              strokeWidth={1}
              fill={"url(#colorUv)"}
            />
          )}
          {variant === "linear" && (
            <Line
              key={`lc_${filteredData.length}`}
              dot={<></>}
              type={"monotone"}
              dataKey={yAxisKey}
              stroke="#50c878"
              strokeWidth={"3px"}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <FilterWrapper>
        <FilterMethods>
          {chartPeriods.map((item, idx) => (
            <FilterMethodButton
              title={item}
              isActive={idx === chartPeriodId}
              setActiveTypeId={setChartPeriodId}
              id={idx}
              key={idx}
            />
          ))}
        </FilterMethods>
      </FilterWrapper>
    </ChartWrapper>
  );
};
