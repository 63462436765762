import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FilterMethodButton} from "./FilterMethodButton";
import {OperationsComponent} from "./OperationsComponent";
import {DataEmptyComponent} from "../global/DataEmptyComponent";
import styled from "styled-components/macro";
import {
    AppTitleNormal,
    FlexColumn,
    FlexRow,
} from "../../style/projectComponents";
import {PaginationComponent} from "../global/PaginationComponent";
import {generatePagesArray} from "../../utils/generatePagesArray";

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1000px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 820px;
  margin-top: 50px;
`;
const FilterTitle = styled.p`
  font-size: 14px;
  margin-right: 20px;
  @media (max-width: 780px) {
    display: none;
  }
`;
const FilterMethods = styled(FlexRow)`
  gap: 20px;
  @media (max-width: 780px) {
    width: 100%;
    align-items:start;
  }
`;
const FilterWrapper = styled(FlexRow)`
  justify-content: end;
  width: calc(100% - 40px);
  padding: 20px;
  background: ${({theme}) => theme.colors.gray050};
  border-radius: 20px 20px 0 0;
  margin-top: 30px;
  @media (max-width: 780px) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
`;


interface UserOperationsComponentProps {
    operations: any;
    isLoading: boolean;
    totalPages: number;
    pageId: number;
    setPageId: (page: number) => void;
}

export const UserOperationsComponent: React.FC<UserOperationsComponentProps> = ({operations, isLoading, setPageId, pageId, totalPages}) => {
    const {t} = useTranslation();

    const operationsFilterTypes = [
        "none",
        "deposit",
        "withdrawalRequest",
        "accrualOfInterest",
    ];
    const operationsFilterNames = [
        t("dashboard.filter.all"),
        t("dashboard.filter.deposit"),
        t("dashboard.filter.withdrawal"),
        t("dashboard.filter.dividends"),
    ];

    const [filterId, setFilterId] = useState(0);

    const operationsItems = operations.items;
    const editedOperations = operationsItems?.map((o) => ({
        ...o,
        currency: o.wallet?.currency,
    }));
    const filteredOperationsData =
        (operationsItems > 0 &&
            filterId > 0 && {
                items: editedOperations?.filter(
                    (item) => item.type === operationsFilterTypes[filterId]
                ),
            }) ||
        editedOperations;

    return (
        <OperationsWrapper>
            <AppTitleNormal
                style={{marginBottom: 10, width: "100%", textAlign: "left"}}
            >
                {t("dashboard.operations")}
            </AppTitleNormal>
            {operations?.items?.length > 0 && (
                <FilterWrapper>
                    <FilterTitle>{t("dashboard.filter.operationType")}</FilterTitle>
                    <FilterMethods>
                        {operationsFilterNames.map((item, idx) => (
                            <FilterMethodButton
                                title={item}
                                isActive={idx === filterId}
                                setActiveTypeId={setFilterId}
                                id={idx}
                                key={idx}
                            />
                        ))}
                    </FilterMethods>
                </FilterWrapper>
            )}
            {filteredOperationsData.length > 0 ? (
                <OperationsComponent
                    keys={[
                        "operationDate",
                        "currency",
                        "balanceDiff",
                        "balanceDiffInUsd",
                        "type",
                        "status",
                    ]}
                    operations={filteredOperationsData}
                />
            ) : (
                <DataEmptyComponent isLoading={isLoading}/>
            )}
            <PaginationComponent
                maxPages={totalPages}
                pageId={pageId}
                pagesNum={generatePagesArray(totalPages, pageId, 100)}
                setPageId={setPageId}
            />
        </OperationsWrapper>
    );
};
