import styled from "styled-components/macro";
import {
  FlexColumnStart,
  FlexRow,
  FlexRowResponsive,
  StyledLink,
} from "../../style/projectComponents";
import { FC } from "react";

export const Title = styled.h3<{ mini?: boolean }>`
  text-align: left;
  font-size: ${(props) => (props.mini ? "2.125rem" : "3.125rem")};
  line-height: 105%;
  font-weight: 300;
  padding: 0;
  margin: 0;

  @media (max-width: 1350px) {
    font-size: 2rem;
  }
`;

export const CustomStyledLink = styled(StyledLink)`
  font-size: 2.125rem;
  line-height: 110%;
  font-weight: 300;
  align-items: center;
  display: flex;
  gap: 1rem;
  width: 100%;

  img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 1350px) {
    font-size: 1.625rem;
    gap: 0.5rem;
    padding-left: 20px;
  }
`;

export const Wrapper = styled(FlexColumnStart)`
  width: 100%;
  height: 100%;
  padding-bottom: 6.5rem;
  padding-top: 6.5rem;
  align-items: center;

  @media (max-width: 1350px) {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }
`;

export const Content = styled(FlexColumnStart)`
  width: 100%;
  max-width: 81.25rem;
  padding-bottom: 12.5rem;

  .docs {
    margin-top: 6.25rem;
    gap: 5rem;
    width: 100%;
  }

  @media (max-width: 1350px) {
    .docs {
      width: auto;
      padding: 20px;
      margin-top: 2.75rem;
    }
  }
`;

export const DocWrapper = styled(FlexRowResponsive)`
  gap: 7.5625rem;
  width: 100%;
  position: relative;
`;

export const StyledHr = styled.hr`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  height: 100%;
  width: 0.0625rem;
  background-color: #deddd8;
  padding: 0;
  margin: 0;
  border: 0;
  @media (max-width: 1350px) {
    display: none;
  }
`;

export const DocContent = styled(FlexColumnStart)`
  width: 36.875rem;
  gap: 3.125rem;

  .block {
    width: 100%;
    gap: 0.875rem;

    p,
    pre,
    span {
      text-align: left;
      font-size: 1.125rem;
      line-height: 140%;
      box-sizing: border-box;
    }

    span {
      color: #aab0b8;
    }
  }

  @media (max-width: 1350px) {
    width: 100%;
  }
`;
export const Circle = (
  <svg height="20" width="20">
    <circle
      cx="10"
      cy="10"
      r="5"
      stroke={`${({theme}) => theme.colors.blue}`}
      strokeWidth="1"
      fill={"white"}
    />
  </svg>
);

export const CircleWrapper = styled(FlexRow)`
  gap: 1rem;
  width: fit-content;
  align-items: flex-start;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
`;

export const CircleTitle: FC<{ text: string }> = ({ text }) => {
  return (
    <CircleWrapper>
      <div>{Circle}</div>
      <p>{text}</p>
    </CircleWrapper>
  );
};

export const RectWrapper = styled(CircleWrapper)`
  padding-left: 1.5rem;
  align-items: baseline;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const RectTitle: FC<{ text: string }> = ({ text }) => {
  return (
    <RectWrapper>
      <img src={"/images/rect.svg"} />
      <p>{text}</p>
    </RectWrapper>
  );
};
