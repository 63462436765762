import { action, autorun, computed, makeAutoObservable } from "mobx";
import { createContext } from "react";
import { IUserDetailsResponse, IWallet } from "../../service/user-api/models";

export const initialProfile: IUserDetailsResponse = {
  birthDate: "",
  email: "",
  firstName: "",
  id: "",
  lastName: "",
  middleName: "",
  phoneNumber: "",
  roles: [],
  wallets: [],
  twoFactorEnabled: false,
  referalCode: "",
};

class ProfileStorage {
  profile: IUserDetailsResponse = localStorage.profile
    ? JSON.parse(localStorage.profile)
    : initialProfile;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
      localStorage.profile = JSON.stringify(this.profile);
    });
  }

  @action setProfile(newProfile: IUserDetailsResponse) {
    this.profile = newProfile;
  }

  @action updateBalanceUSDT(newBalance: number) {
    this.profile.wallets = this.profile.wallets.map((it) =>
      it.currency === "USDT"
        ? {
            ...it,
            balance: newBalance,
          }
        : it
    );
  }

  @computed getProfile(): IUserDetailsResponse {
    return this.profile;
  }

  @computed getBalance(): number {
    return (
      this?.profile?.wallets?.find((it) => it.currency === "USDT")?.balance || 0
    );
  }
  @computed getWallets() {
    return this?.profile?.wallets;
  }

  @computed getUSDTWallet(): IWallet {
    return this?.profile?.wallets?.find((it) => it.currency === "USDT");
  }

  @action
  clear() {
    this.profile = initialProfile;
  }
}

export default createContext(new ProfileStorage());
