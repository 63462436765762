import { FlexColumnStart, StyledLink } from "../../../style/projectComponents";
import styled from "styled-components/macro";
import { ComponentPropsWithoutRef, FC } from "react";
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from "react-scroll";

const StyledMenuItem = styled(StyledLink)`
  color: ${({theme}) => theme.colors.grayButton};
  font-size: 1rem;
  font-weight: 300;

  :hover {
    color: #fff;
    transition: all ease-in 0.3s;
  }
`;

const StyledScrollLink = styled(ScrollLink)`
  color: ${({theme}) => theme.colors.grayButton};
  font-size: 1rem;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: #fff;
    transition: all ease-in 0.3s;
  }
`;

const MenuCol = styled(FlexColumnStart)`
  @media (max-width: 1350px) {
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: baseline;
  }
  flex-wrap: wrap;
`;

export const MenuColItem: FC<
  {
    isScrollLink?: boolean;
    items: {
      title: string;
      link: string;
    }[];
  } & ComponentPropsWithoutRef<"div">
> = (props) => {
  const { items, isScrollLink = false, ...other } = props;
  const { t } = useTranslation();

  return (
    <MenuCol {...other} className={"column_menu"}>
      {items.map((it, idx) =>
        isScrollLink ? (
          <StyledScrollLink
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            to={it.link}
            key={idx}
          >
            {t(it.title)}
          </StyledScrollLink>
        ) : (
          <StyledMenuItem key={idx} to={it.link}>
            {t(it.title)}
          </StyledMenuItem>
        )
      )}
    </MenuCol>
  );
};
