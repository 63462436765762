import {
  FlexRow,
  FlexRowBetween,
  LanguageSwitch,
} from "../../style/projectComponents";
import React, {
  ComponentPropsWithoutRef,
  FC,
  useContext,
  useState,
} from "react";
import LanguageStorage from "../../storage/LanguageStorage/LanguageStorage";
import UserStorage from "../../storage/UserStorage/UserStorage";
import i18n from "../../config/i18n";
import styled from "styled-components/macro";

const LanguageWrapper = styled(FlexRow)`
  width: 100%;
  gap: 40px;
`;

interface ChangeLanguageProps {
  handleMenu: () => void;
}

export const ChangeLanguage: React.FC<ChangeLanguageProps> = ({
  handleMenu,
}) => {
  const LanguageStore = useContext(LanguageStorage);
  const UserStore = useContext(UserStorage);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const [langs, setLangs] = useState(
    [
      {
        lang: "en",
        active: true,
      },
      {
        lang: "ru",
        active: false,
      },
    ].map((it, idx) =>
      LanguageStore.language === it.lang
        ? { ...it, active: true }
        : { ...it, active: false }
    )
  );

  const getLanguageDescription = (lang: string) => {
    return (lang === "en" && "English") || (lang === "ru" && "Русский");
  };

  return (
    <LanguageWrapper>
      {langs.map((it, idx) => (
        <LanguageSwitch
          key={idx}
          onClick={() => {
            setLangs(
              langs.map((lng) =>
                lng.lang === it.lang
                  ? { ...lng, active: true }
                  : { ...lng, active: false }
              )
            );
            changeLanguage(it.lang);
            LanguageStore.setLanguage(it.lang);
            handleMenu();
          }}
          active={it.active}
        >
          {getLanguageDescription(it.lang)}
        </LanguageSwitch>
      ))}
    </LanguageWrapper>
  );
};
