/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import {
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    InputTitle,
    StyledButton,
} from "../../style/projectComponents";
import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components/macro";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import AdminApi from "../../service/admin-api/AdminApi";
import {IGetExchangeRateReq} from "../../service/currency-api/models";
import CurrencyApi from "../../service/currency-api/CurrencyApi";
import {useForm} from "react-hook-form";
import SelectCurrencyModal from "../../components/global/modal/SelectCurrencyModal";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {queryClient} from "../../config/query-client";
import UserStorage from "../../storage/UserStorage/UserStorage";
import UserApi from "../../service/user-api/UserApi";

const Wrapper = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.white};
  padding: 24px;
  width: calc(100% - 48px);
  @media (max-width: 768px) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transform: translateY(-15px);
  }
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 600px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const AmountInput = styled.input`
  width: 100%;
  border: none;
  font-weight: 600;
  font-size: 30px;
  background: none;
  color: ${({theme}) => theme.colors.black};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
  appearance: none;
  outline: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.grayButton};
  }
`;
const InputWrapper = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.gray050};
  width: calc(100% - 40px);
  padding: 20px;
  align-items: start;
  border-radius: 16px;
`;
const SwapSidesButton = styled(FlexColumn)`
  width: 43px;
  height: 43px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 100%;
  cursor: pointer;
  background: linear-gradient(100.72deg, #ff8a00, #d449f7);

  img {
    width: 23px;
    height: 23px;
  }
`;
const TokenSelector = styled(FlexRow)`
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  border-radius: 12px;
`;

const MaxButton = styled(StyledButton)`
  color: ${({theme}) => theme.colors.black};
  font-weight: 500;
  font-size: 11px;
  border-radius: 9px;
  border: none;
  background: ${({theme}) => theme.colors.white};
`;
const ExchangeRate = styled.p`
  color: ${({theme}) => theme.colors.grayButton};
  font-size: 11px;
  margin-top: 16px;
`;
const BalanceWrapper = styled(FlexRow)`
  margin-top: 12px;
  gap: 10px;

  p {
    font-size: 11px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.grayButton};
  }
`;

const ConvertForm: React.FC = () => {
    const GlobalModalStorage = useContext(GlobalModalStore);
    const {
        formState: {errors, isDirty},
        handleSubmit,
    } = useForm();

    const UserStore = useContext(UserStorage);
    const {data: userData} = useQuery(
        "user_profile_data",
        () => UserApi.getUserDetails(UserStore.user["UserId"]),
        {
            enabled: !!UserStore.accessToken,
        }
    );

    const wallets = userData?.wallets;

    const {t} = useTranslation();

    const {data: currencyList} = useQuery("currency_list", () =>
        AdminApi.getCurrencies({
            skip: 0,
            take: 100,
        })
    );
    const currencies = currencyList?.currencies || [];
    const swapCurrency = useMutation((data: IGetExchangeRateReq) =>
        CurrencyApi.exchangeCurrencies(data)
    );

    const [firstTokenId, setFirstTokenId] = useState(0);
    const [secondTokenId, setSecondTokenId] = useState(1);
    const {data: exchangeRate, mutate} = useMutation(
        (data: IGetExchangeRateReq) =>
            CurrencyApi.getExchangeRate({
                firstTokenId:
                    currencies?.[firstTokenId] && currencies[firstTokenId]?.id,
                secondTokenId:
                    currencies?.[secondTokenId] && currencies[secondTokenId]?.id,
                firstTokenAmount: 1,
            })
    );
    useEffect(() => {
        if (currencies?.length > 0) {
            mutate({
                firstTokenId:
                    currencies?.[firstTokenId] && currencies[firstTokenId]?.id,
                secondTokenId:
                    currencies?.[secondTokenId] && currencies[secondTokenId]?.id,
                firstTokenAmount: 1,
            });
        }
    }, [currencies, firstTokenId, secondTokenId]);

    const [amountFrom, setAmountFrom] = useState<number | string>("");
    const [amountTo, setAmountTo] = useState<number | string>("");
    const handleChangeAmountFrom = (e) => {
        setAmountFrom(e.target.value);
        setAmountTo(e.target.value * exchangeRate?.exchangeResult);
    };
    const handleChangeAmountTo = (e) => {
        setAmountTo(e.target.value);
        setAmountFrom(e.target.value / exchangeRate?.exchangeResult);
    };
    useEffect(() => {
        if (amountFrom) {
            setAmountTo(Number(amountFrom) * exchangeRate?.exchangeResult);
        }
    }, [exchangeRate]);

    const firstTokenBalance =
        wallets?.find(
            (w) =>
                w.currency?.toLowerCase() ===
                currencies?.[firstTokenId]?.name?.toLowerCase()
        )?.balance || 0;
    const secondTokenBalance =
        wallets?.find(
            (w) =>
                w.currency?.toLowerCase() ===
                currencies?.[secondTokenId]?.name?.toLowerCase()
        )?.balance || 0;

    const amountFromMax = () => {
        setAmountFrom(firstTokenBalance);
        setAmountTo(firstTokenBalance * exchangeRate?.exchangeResult);
    };

    const isFirstTokenAmountGood =
        exchangeRate?.exchangeResult &&
        firstTokenBalance > 0 &&
        Number(amountFrom) > 0 &&
        firstTokenBalance >= Number(amountFrom);

    const firstTokenName = currencies?.[firstTokenId]?.name;
    const secondTokenName = currencies?.[secondTokenId]?.name;
    const firstTokenImg =
        firstTokenName && `/images/coins/${firstTokenName.toLowerCase()}.png`;
    const secondTokenImg =
        secondTokenName && `/images/coins/${secondTokenName.toLowerCase()}.png`;
    const swapTokensSide = () => {
        const firstId = firstTokenId;
        const secondId = secondTokenId;
        const firstAmount = Number(amountFrom);
        const secondAmount = amountTo;
        setFirstTokenId(secondId);
        setSecondTokenId(firstId);
        setAmountFrom(secondAmount);
        setAmountTo(firstAmount);
    };

    const onSubmit = () => {
        swapCurrency.mutate(
            {
                firstTokenId: currencies?.[firstTokenId]?.id,
                secondTokenId: currencies?.[secondTokenId]?.id,
                firstTokenAmount: Number(amountFrom),
            },
            {
                onSuccess: () => {
                    GlobalModalStorage.makeVisible(true, t("common.success"));
                    queryClient.invalidateQueries({queryKey: ["user_profile_data"]});
                },
                onError: () => {
                    GlobalModalStorage.makeVisible(false, t("common.error"));
                },
            }
        );
    };

    const [swapFrom, setSwapFrom] = useState(true);
    const [currencyModal, setCurrencyModal] = useState(false);
    const handleCurrencyModal = () => setCurrencyModal((c) => !c);
    const theme = useTheme()

    return (
        <Wrapper>
            <SelectCurrencyModal
                openMenu={currencyModal}
                handleMenu={handleCurrencyModal}
                swapFrom={swapFrom}
                firstTokenId={firstTokenId}
                secondTokenId={secondTokenId}
                setFirstTokenId={setFirstTokenId}
                setSecondTokenId={setSecondTokenId}
                currencies={currencies}
            />
            {currencies?.length > 0 && (
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper>
                        <InputTitle>From</InputTitle>
                        <FlexRowBetween style={{marginTop: 12}}>
                            <AmountInput
                                placeholder="Amount from"
                                type="number"
                                value={amountFrom}
                                min={0}
                                step="any"
                                onChange={handleChangeAmountFrom}
                            />
                            <TokenSelector
                                onClick={() => {
                                    setSwapFrom(true);
                                    handleCurrencyModal();
                                }}
                            >
                                <img
                                    src={firstTokenImg}
                                    alt=""
                                    style={{
                                        width: 26,
                                        height: 26,
                                        marginRight: 5,
                                        borderRadius: "100%",
                                    }}
                                />
                                <p>{firstTokenName}</p>
                                <img
                                    src="/images/interface/arrow-bottom.png"
                                    alt=""
                                    style={{width: 12, marginLeft: 5}}
                                />
                            </TokenSelector>
                        </FlexRowBetween>
                        <BalanceWrapper>
                            <MaxButton onClick={amountFromMax} type="button">
                                Max
                            </MaxButton>
                            <p>Balance <span
                                // @ts-ignore
                                style={{color: theme.colors.black}}>{firstTokenBalance}</span></p>
                        </BalanceWrapper>
                    </InputWrapper>
                    <SwapSidesButton onClick={swapTokensSide}>
                        <img src="/images/interface/arrows-swap.svg" alt=""/>
                    </SwapSidesButton>
                    <InputWrapper>
                        <InputTitle>To</InputTitle>
                        <FlexRowBetween style={{marginTop: 12}}>
                            <AmountInput
                                placeholder="Amount to"
                                type="number"
                                value={amountTo}
                                onChange={handleChangeAmountTo}
                            />
                            <TokenSelector
                                onClick={() => {
                                    setSwapFrom(false);
                                    handleCurrencyModal();
                                }}
                            >
                                <img
                                    src={secondTokenImg}
                                    alt=""
                                    style={{
                                        width: 26,
                                        height: 26,
                                        marginRight: 5,
                                        borderRadius: "100%",
                                    }}
                                />
                                <p>{secondTokenName}</p>
                                <img
                                    src="/images/interface/arrow-bottom.png"
                                    alt=""
                                    style={{width: 12, marginLeft: 5}}
                                />
                            </TokenSelector>
                        </FlexRowBetween>
                        <BalanceWrapper>
                            <MaxButton onClick={amountFromMax} type="button">
                                Max
                            </MaxButton>
                            <p>Balance <span
                                // @ts-ignore
                                style={{color: theme.colors.black}}>{secondTokenBalance}</span></p>
                        </BalanceWrapper>
                    </InputWrapper>
                    <ExchangeRate>
                        Exchange Rate: 1 {firstTokenName} ≈{" "}
                        {exchangeRate?.exchangeResult?.toFixed(10)} {secondTokenName}
                    </ExchangeRate>
                    <StyledButton
                        type="submit"
                        disabled={!isFirstTokenAmountGood || swapCurrency.isLoading}
                        style={{width: "100%", height: 56, marginTop: 32}}
                    >
                        Convert
                    </StyledButton>
                </StyledForm>
            )}
        </Wrapper>
    );
};

export default observer(ConvertForm);
