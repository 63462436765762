import { apiClient } from "../../config/serverConstants";
import {
  IGetExchangeRateReq,
  ICurrencyInterface,
  IGetExchangeRateRes,
  AddOrUpdateCurrencyNetworkInfo,
  GetCurrencyNetworkInfoReq,
  GetCurrencyNetworkInfoRes,
} from "./models";

class CurrencyServices implements ICurrencyInterface {
  async getExchangeRate(data: IGetExchangeRateReq) {
    const response = await apiClient.post(
      "/api/Currencies/getExchangeRate",
      data
    );
    return response.data as IGetExchangeRateRes;
  }

  async exchangeCurrencies(data: IGetExchangeRateReq) {
    const response = await apiClient.post(
      "/api/Currencies/exchangeCurrencies",
      data
    );
    return response.data as any;
  }

  async addOrUpdateCurrencyNetworkInfo(data: AddOrUpdateCurrencyNetworkInfo) {
    const response = await apiClient.post(
      "/api/Currencies/addOrUpdateCurrencyNetworkInfo",
      data
    );
    return response.data as any;
  }

  async getCurrencyNetworkInfo(
    data: GetCurrencyNetworkInfoReq
  ): Promise<GetCurrencyNetworkInfoRes> {
    const response = await apiClient.post(
      "/api/Currencies/getCurrencyNetworkInfo",
      data
    );
    return response.data;
  }
}
const CurrencyService = new CurrencyServices();
export default CurrencyService;
