import {
  ErrorText,
  FlexColumn,
  FlexRow,
  StyledButton,
} from "../../../style/projectComponents";
import React, {
  ComponentPropsWithoutRef,
  FC,
  useContext,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components/macro";
import { ValidationInputComponent } from "../../global/ValidationInputComponent";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import GlobalModalStore from "../../../storage/GlobalModalStore/GlobalModalStore";
import NetworkService from "../../../service/network-api/NetworkApi";
import { CreateNetworkReq } from "../../../service/network-api/models";
import { CheckComponent } from "../../auth/Register/CheckComponent";

type IFormType = {
  name: any;
  rules: any;
  type: string;
};

const forms: IFormType[] = [
  {
    name: "name",
    rules: {
      required: true,
    },
    type: "string",
  },
  {
    name: "nodeUrl",
    rules: {
      required: true,
    },
    type: "string",
  },
  {
    name: "walletsValidationRegex",
    rules: {
      required: true,
    },
    type: "string",
  },
  // {
  //   name: "evmCompatible",
  //   rules: {
  //     required: true,
  //   },
  //   type: "checkbox",
  // },
];

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 380px;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  background: ${({theme}) => theme.colors.gray050};
  display: flex;
  align-items: start;
  border-radius: var(--8, 0.5rem);
  padding: 20px 25px;
  margin: 10px;

  .inps {
    gap: 0.81rem;
    margin-bottom: 0.81rem;

    .checkbox {
      width: 100%;
      justify-content: left;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }
`;

export const CreateNetworkForm: FC<ComponentPropsWithoutRef<"form">> = (
  props
) => {
  const {
    register,
    control,
    clearErrors,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty, isValid },
    watch,
    handleSubmit,
  } = useForm<CreateNetworkReq>();

  const createCurrency = useMutation((data: CreateNetworkReq) =>
    NetworkService.createNetwork(data)
  );
  const GlobalModalStorage = useContext(GlobalModalStore);
  const [isClicked, setIsClicked] = useState(true);
  const onSubmit = (data: CreateNetworkReq) => {
    createCurrency.mutate(
      { ...data, evmCompatible: isClicked },
      {
        onSuccess: () => {
          GlobalModalStorage.makeVisible(true, t("common.success"));
          reset({
            name: "",
            evmCompatible: true,
            nodeUrl: "",
            walletsValidationRegex: "",
          });
        },
        onError: (error) => {
          GlobalModalStorage.makeVisible(false, t("common.error"));
        },
      }
    );
  };

  const { t } = useTranslation();
  const reg = RegExp(/"^[1-9A-HJ-NP-Za-km-z]{32,44}$"/);
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <h3 style={{ marginBottom: "1rem", fontSize: "1.5rem", fontWeight: 400 }}>
        Create network
      </h3>
      <FlexColumn className={"inps"}>
        {forms.map((it, idx) => (
          <InputWrapper key={idx}>
            <Controller
              name={it.name}
              control={control}
              rules={it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  maxLength={50}
                  isError={!!errors[it.name]}
                  label={it.name}
                />
              )}
            />
          </InputWrapper>
        ))}
        <CheckComponent
          handleClick={() => setIsClicked((prev) => !prev)}
          isClicked={isClicked}
          text="evm compatible"
        />
      </FlexColumn>

      {!!errors["name"] && (
        <ErrorText style={{ marginBottom: 10 }}>
          {t(errors["name"]?.message.toString())}
        </ErrorText>
      )}

      <StyledButton
        type={"submit"}
        variant="black"
        style={{ width: "100%", padding: "var(--16, 1rem)", maxWidth: "100%" }}
        disabled={!isDirty || createCurrency.isLoading || !isValid}
      >
        {createCurrency.isLoading ? "Loading" : "Create"}
      </StyledButton>
    </StyledForm>
  );
};
