import React from "react";
import styled from "styled-components/macro";
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
} from "../../style/projectComponents";
import toLocaleNumber from "../../utils/toLocaleNumber";
import getColorByValue from "../../utils/getColorByValue";
import { Link } from "react-router-dom";
import {
  getActiveMarketPair,
  getMarketDataByPair,
} from "../../utils/getActiveMarketPair";
import { ITicker } from "../../service/bybit-api/models";

const Block = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 5px 0;
  background: none;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGrayBorder};
  background: ${({theme}) => theme.colors.gray050};

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &:hover {
    background: ${({theme}) => theme.colors.gray050};
  }
`;
const ShortName = styled.p`
  font-size: 14px !important;
  font-weight: 600;
  color: ${({theme}) => theme.colors.black};
  text-align: left;
`;
const Value = styled.p`
  font-size: 11px !important;
  font-weight: 600;
  color: ${({theme}) => theme.colors.black};
  text-align: right;
  width: 100%;
`;

interface TradingPairProps {
  link: string;
  activePair: string;
  tickerList: ITicker[] | null;
}

export const TradingPair: React.FC<TradingPairProps> = ({
  link,
  activePair,
  tickerList,
}) => {
  const pairName = link.toUpperCase().split("/")[2] || "";
  const tokenPriceData = getMarketDataByPair(tickerList, pairName);
  const tokenPrice =
    (tokenPriceData?.lastPrice && toLocaleNumber(tokenPriceData?.lastPrice)) ||
    0;
  const priceChangePercent = tokenPriceData?.price24hPcnt * 100 || 0;
  const priceChangeString = `${
    Number(priceChangePercent) >= 0 ? "+" : ""
  }${Number(priceChangePercent).toFixed(2)}`;
  const pairNameString = getActiveMarketPair(pairName)?.pair || "";

  return (
    <Block
      to={link}
      style={{ background: activePair === pairName && `${({theme}) => theme.colors.gray050}` }}
    >
      <FlexRowBetween style={{ alignItems: "center" }}>
        <FlexRow style={{ justifyContent: "start", marginLeft: 15 }}>
          <FlexColumn style={{ alignItems: "start" }}>
            <ShortName>{pairNameString}</ShortName>
          </FlexColumn>
        </FlexRow>
        <FlexColumn style={{ alignItems: "end", marginRight: 15, gap: 5 }}>
          <Value style={{ textAlign: "right" }}>
            {toLocaleNumber(Number(tokenPrice))}
          </Value>
          <Value
            style={{
              color: getColorByValue(priceChangePercent),
              textAlign: "right",
            }}
          >
            {priceChangeString} %
          </Value>
        </FlexColumn>
      </FlexRowBetween>
    </Block>
  );
};
