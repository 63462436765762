import React, {
  ComponentPropsWithoutRef,
  useState,
} from "react";
import { StyledInput } from "../../style/projectComponents";
import styled from "styled-components/macro";

type ValidationInputComponentProps = {
  value?: string;
  isError?: any;
  label?: string;
  ref?: any;
};

export const ValidationLabel = styled.label<{ isFocused?: any }>`
  display: ${({ isFocused }) => (isFocused ? "block" : "none")};
  font-size: 14px;
  position: absolute;
  left: 20px;
  margin-top: 10px;
  transition: all 1s;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 480px) {
    left: 70px;
  }
`;

export const CustomStyledInput = styled(StyledInput)`
  width: 100%;
  position: relative;
  font-family: sans-serif;

  @media (max-width: 480px) {
  }
`;
export const ValidationInputComponent: React.FC<
  ValidationInputComponentProps & ComponentPropsWithoutRef<"input">
> = (props) => {
  const { value, isError, label, ...other } = props;
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <CustomStyledInput
        {...other}
        value={value ? value : ""}
        placeholder={label}
        onChange={other.onChange}
        isFailed={isError}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      />
    </>
  );
};
