import styled from "styled-components/macro";
import { FlexColumn, Page } from "../../style/projectComponents";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import InvestPoolApi from "../../service/investpool-api/InvestPoolApi";
import { IGetAllInvestPoolsResponse } from "../../service/investpool-api/models";
import { InvestMethodsComponent } from "../../components/DashboardComponents/InvestMethodsComponent";

const StyledBg = styled(FlexColumn)`
  background: ${({theme}) => theme.colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: 100%;
  margin-top: 110px;
  justify-content: flex-start;
`;

const MarketWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  margin-top: 3.12rem;
`;

export const AdminInvestPools = () => {
  const getAllInvestPoolsQuery = useMutation(() =>
    InvestPoolApi.getAllInvestPools()
  );
  const [investPools, setInvestPools] = useState<IGetAllInvestPoolsResponse>({
    items: [],
    totalCount: 0,
  });
  const investPoolsItems = investPools?.items;

  useEffect(() => {
    getAllInvestPoolsQuery.mutate(null, {
      onSuccess: (data) => {
        setInvestPools(data);
      },
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <StyledBg>
        <MarketWrapper>
          <InvestMethodsComponent investPools={investPoolsItems} isAdmin />
        </MarketWrapper>
      </StyledBg>
    </Page>
  );
};
