import React from "react";
import { HalfContent } from "../../../style/projectComponents";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const LeftContent = styled(HalfContent)`
  color: #fff;
  background: #000;

  h2 {
    font-size: 26px;
  }

  p {
    font-size: 16px;
    max-width: 520px;
  }

  @media (max-width: 1020px) {
    display: none;
  }

  img {
    margin-top: 32px;
    max-height: 400px;
  }
`;

const LeftContentComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LeftContent>
      <h2>{t("auth.presentation.title")}</h2>
      <p>{t("auth.presentation.description")}</p>
      <img src="/images/promo/cmc-app.png" alt="promo" />
    </LeftContent>
  );
};

export default LeftContentComponent;
