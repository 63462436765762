import React, { useEffect } from "react";
import { FlexColumn, Page } from "../../style/projectComponents";
import styled from "styled-components/macro";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

const StyledBg = styled(FlexColumn)`
  justify-content: start;
  align-items: start;
  width: 100vw;
  height: 100%;
  margin-top: 54px;
`;

const InvestPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();

  return (
    <Page style={{ justifyContent: "start" }}>
      <StyledBg>
        <p>Invest</p>
      </StyledBg>
    </Page>
  );
};

export default observer(InvestPage);
