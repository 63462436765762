import React from "react";
import styled from "styled-components/macro";
import { FlexRow, SelectTextButton } from "../../style/projectComponents";

const Block = styled(FlexRow)`
  justify-content: start;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGrayBorder};
  color: #000;
  gap: 8px;
`;

interface AddressComponentProps {
  elements: any[];
  selectedId: number;
  selectElement: (id: number) => void;
}

const SelectCurrencyPanel: React.FC<AddressComponentProps> = ({
  elements,
  selectedId,
  selectElement,
}) => {
  return (
    <Block>
      {elements.map((el, id) => (
        <SelectTextButton
          type="button"
          key={id}
          onClick={(e) => {
            selectElement(id);
          }}
          selected={selectedId === id}
        >
          {el}
        </SelectTextButton>
      ))}
    </Block>
  );
};

export default SelectCurrencyPanel;
