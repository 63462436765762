import {marketList} from "../config/marketList";

export const getActiveMarketPair = (pair: string) => {
    return marketList.find(mPair => mPair.link === `/markets/${pair}`)
}

export const getTokenDataByPair = (marketData: any, pair: string) => {
    return marketData && marketData.find(token => token?.symbol?.toLowerCase() === pair.toLowerCase())
}

export const getMarketDataByPair = (tickersList: any, pair: string) => {
    return tickersList?.find(ticker => ticker.symbol.toLowerCase() === pair.toLowerCase())
}

