import React from "react";
import {FlexColumnStart, FlexRow} from "../../style/projectComponents";
import styled from "styled-components/macro";
import {ICurrency} from "../../service/admin-api/models";

interface BlockProps {
    isSelected: boolean;
}

const Block = styled(FlexRow)<BlockProps>`
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 68px;
  cursor: pointer;
  background: ${({isSelected, theme}) => isSelected && theme.colors.inputBackgroundSecondary};

  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 10px;
    margin-left: 20px;
  }
`;

interface ConvertCurrencyItemProps {
    currency: ICurrency;
    id: number;
    selectedId: number;
    removedId: number;
    setTokenId: any;
    handleMenu: () => void;
}

const ConvertCurrencyItem: React.FC<ConvertCurrencyItemProps> = ({
                                                                     currency,
                                                                     removedId,
                                                                     selectedId,
                                                                     id,
                                                                     setTokenId,
                                                                     handleMenu,
                                                                 }) => {
    const currencyImg = `/images/coins/${currency.name?.toLowerCase()}.png`;
    const name = currency?.name;
    const description = currency?.description;
    const isSelected = id === selectedId;
    const isRemoved = removedId === id;
    const selectToken = () => {
        setTokenId(id);
        handleMenu();
    };

    return (
        <Block style={{display: isRemoved && "none"}}
               isSelected={isSelected}
               onClick={selectToken}
        >
            <img src={currencyImg} alt=""/>
            <FlexColumnStart>
                <p style={{fontSize: 14, fontWeight: 600}}>{name}</p>
                <p style={{fontSize: 12}}>{description}</p>
            </FlexColumnStart>
        </Block>
    );
};

export default ConvertCurrencyItem;
